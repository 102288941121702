import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery for DOM manipulation
import 'datatables.net-bs5'; // Import DataTables plugin for Bootstrap 5
import FileUploadButton from '../../buttons/UploadFile'; // Assuming this is a custom component for file upload
import styles from '../../../common/common.module.css'; // Import CSS module for styling
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for localization

const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

/**
 * Component to display and manage files associated with a specific asset.
 * Fetches asset name and associated files from the server based on parameters.
 */
function ListFiles() {
    const { t } = useTranslation(); // Initialize useTranslation hook for translation
    const [files, setFiles] = useState([]); // State for storing files
    const [showFiles, setShowFiles] = useState(null); // State for indicating if files should be shown
    const { zone_name, plant_name, hcode, type } = useParams(); // Extract parameters from URL
    const [change, setChange] = useState(''); // State to trigger change in data fetching
    const [asset_name, setAsset_name] = useState(""); // State to store asset name

    const tableRef = useRef(null); // Reference for DataTable

    /**
     * Fetches the asset name from the server based on hcode and type parameters.
     * Sets the asset_name state if the fetch is successful.
     */
    const fetchAssetName = async () => {
        try {
            const resp = await fetch(`${SERVER_URL}/getAssetName/${hcode}/${type}`);
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.aname); // Set asset name if fetch is successful
            }
        } catch (error) {
            console.error(error); // Log error if fetch fails
        }
    };

    /**
     * Fetches files associated with the asset from the server based on hcode and type parameters.
     * Sets the files state with fetched files if successful.
     * Sets showFiles state based on the response status code.
     */
    const fetchFiles = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/assetFiles/${hcode}/${type}?hcode=${localStorage.getItem('hcode')}`);
            const data = await response.json();
            if (data.statusCode === 409) {
                setShowFiles(false); // If no files found, set showFiles to false
            } else if (data.statusCode === 200) {
                setFiles(data.assetFiles); // Set files state with fetched files
                setShowFiles(true); // Show files if fetched successfully
            } else {
                setShowFiles(false); // Hide files for other status codes
            }
        } catch (error) {
            console.error('Error fetching files:', error); // Log error if fetch fails
            setShowFiles(false); // Hide files on fetch error
        }
    };

    // Effect hook to fetch asset name when hcode changes
    useEffect(() => {
        fetchAssetName();
    }, [hcode]);

    // Effect hook to fetch files when change state changes
    useEffect(() => {
        fetchFiles();
    }, [change]);

    // Effect hook to initialize DataTable when files state changes
    useEffect(() => {
        if (files.length > 0) {
            // Initialize DataTable with localized language options
            $(tableRef.current).DataTable({
                destroy: true, // Destroy existing DataTable instance if any
                language: t('dataTable', { returnObjects: true }) // Use translation for DataTable language settings
            });
        }
    }, [files, t]); // Depend on files and translation function for updates

    /**
     * Handles the download of a file from the server.
     * Downloads the file using the fetched URL and sets the appropriate download attributes.
     * @param {string} fname - File name and identifier to be downloaded
     */
    const handleDownloadFiles = async (fname) => {
        try {
            // Fetch file URL from server
            const fileURL = await fetch(`${SERVER_URL}/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('hcode')}`);
            const file = await fileURL.blob(); // Convert fetched data to blob
            const url = URL.createObjectURL(file); // Create object URL for blob
            const link = document.createElement('a'); // Create <a> element
            link.href = url; // Set href attribute to object URL
            link.download = `${fname.split('|')[1]}`; // Set download attribute to file name
            link.click(); // Programmatically click the link to trigger download
        } catch (error) {
            console.error('Error downloading file:', error); // Log error if download fails
        }
    };

    /**
     * Handles the viewing of a file, opening it in a new tab or window if supported.
     * Opens the file URL in a new tab or window for viewing (specifically for PDF files).
     * @param {string} fname - File name and identifier to be viewed
     */
    const handleView = async (fname) => {
        try {
            if (fname.endsWith('.pdf')) { // Check if file is PDF
                const fileURL = await fetch(`${SERVER_URL}/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('hcode')}`);
                const pdfBlob = await fileURL.blob(); // Convert fetched data to blob
                const pdfUrl = URL.createObjectURL(pdfBlob); // Create object URL for blob

                // Open the PDF in a new tab or window
                window.open(pdfUrl, '_blank');
            } else {
                alert('Browser does not support viewing this file.'); // Alert if browser does not support viewing file
            }
        } catch (error) {
            console.error('Error viewing file:', error); // Log error if view fails
        }
    };

    /**
     * Deletes a file from the server.
     * Sends a DELETE request to the server to delete the specified file.
     * Triggers a change in the component to update the file list.
     * @param {string} fname - File name and identifier to be deleted
     */
    const deleteFile = async (fname, hcode) => {
        try {
            const response = await fetch(`${SERVER_URL}/assetFile/delete/${fname}/${hcode}?hcode=${localStorage.getItem('hcode')}`, {
                method: 'DELETE', // HTTP DELETE method
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            setChange((prev) => !prev); // Trigger change to update file list
        } catch (error) {
            console.error('Error deleting file:', error); // Log error if delete fails
        }
    };

    /**
     * Handler function for deleting a file.
     * Calls the deleteFile function to delete the specified file and trigger an update in the file list.
     * @param {string} fname - File name and identifier to be deleted
     */
    const handleDeleteFiles = async (fname) => {
        await deleteFile(fname, hcode); // Call deleteFile function to delete file
    };

    return (
        <div id="content" className='w-100'>
            {/* Breadcrumb navigation */}
            <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>{t('plants')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: '#121212' }}>{t('area')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to={`/assets/${plant_name}/${zone_name}`} style={{ color: '#121212' }}>{t('assets')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('files')}</li>
                </ol>
            </nav>
            {/* Title section */}
            <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfFilesOf')} {asset_name} {t('of')} {zone_name} {t('of')} {plant_name}</div>
            <br />
            <div className="ps-2">
                {/* File upload button */}
                <div className="row row-cols-5 mb-2">
                    <FileUploadButton hcode={hcode} setChange={setChange} />
                </div>
                {/* Conditional rendering based on showFiles state */}
                {showFiles ? (
                    // Show table if files are available
                    <div className="overflow-y w-100" style={{ maxHeight: '100%' }}>
                        <table className={`table ${styles.table} table-sm table-bordered`} ref={tableRef}>
                            <thead>
                                <tr className={`${styles['border-top-bottom']}`}>
                                    <th>{t('fileName')}</th>
                                    <th>{t('download')}</th>
                                    <th>{t('delete')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Map through files and display each file */}
                                {files.map((file) => (
                                    <tr key={file.fname} className={`${styles['row-hover']}`}>
                                        <td style={{ cursor: 'pointer' }} onDoubleClick={() => handleView(file.fname)}>
                                            {file.fname.split('|')[1]} {/* Display file name */}
                                        </td>
                                        {/* Download button */}
                                        <td>
                                            <button
                                                className={`${styles['btn-style']} btn`}
                                                style={{ lineHeight: '1' }}
                                                onClick={() => handleDownloadFiles(file.fname)}
                                            >
                                                {t('download')} {/* Localized text for download */}
                                            </button>
                                        </td>
                                        {/* Delete button */}
                                        <td>
                                            <button
                                                className="btn btn-secondary"
                                                style={{ lineHeight: '1' }}
                                                onClick={() => handleDeleteFiles(file.fname)}
                                            >
                                                {t('delete')} {/* Localized text for delete */}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    // Show message if no files are available
                    <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>
                )}
            </div>
        </div>
    );
}

export default ListFiles;
