import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import { useParams, useNavigate } from "react-router-dom";
import styles from './../../common/common.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function CreateZone() {
    const [zoneName, setZoneName] = useState('');
    const [zoneID, setZoneID] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);



    const handleZoneName = (event) => {
        setZoneName(event.target.value.trim())
    }

    const handleZoneID = (event) => {
        setZoneID(event.target.value.trim())
    }

    const navigate = useNavigate();

    const { plant_name } = useParams();

    const handleFormSubmit = (event) => {
        event.preventDefault();



        fetch(`${SERVER_URL}/zones/create?hcode=${localStorage.getItem('hcode')}`, {

            method: 'POST',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({
                zname: zoneName,
                zoneID: zoneID,
                plant_name: plant_name
            }),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()) // Parse the response body as JSON
            .then(data => {
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/zones/${plant_name}`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error)); // Log any errors to the console
    }



    return (<>
        <div className="w-100 mt-3" id="content"><div id="msg">
            {showAlert && <Alert data={responseData} ></Alert>}
        </div>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
                <form className="w-50" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal" style={{ color: '#121212', fontSize: '600' }}>Create Area</h1>
                    <div className="form-floating">
                        <input type="text" className="form-control" name="plant_name" placeholder="name@example.com" value={plant_name} disabled readOnly />
                        <label htmlFor="plant_name">Plant Name</label>
                    </div>
                    <br />
                    <div className="form-floating">
                        <input className="form-control" type="text" name="zone" onChange={handleZoneName} placeholder="name@example.com" required />
                        <label htmlFor="zone">Area Name</label>
                    </div>
                    <br />
                    <div className="form-floating">
                        <input className="form-control" type="text" name="zoneID" onChange={handleZoneID} placeholder="A0100" required />
                        <label htmlFor="zoneID">Area Code</label>
                    </div>
                    <br />
                    <button className={`w-100 btn btn-lg ${styles['btn-style']}`} type="submit">Create Area</button>
                </form>
            </div>
        </div>
    </>

    );
}


export default CreateZone;