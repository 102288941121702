import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css'


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function UserViewerProcurement(prop) {
    const [procurements, setSpares] = useState([]);
    const [showProcurements, setShowProcurements] = useState(null);
    const [hcode, setHcode] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [aliase, setaliase] = useState(null);
    const [change, setChange] = useState(false);
    const { selectedPart, setActiveTab, plantHcode } = prop;

    const tableRef = useRef(null);

    const fetchAssetName = async () => {
        try {
            let part_name = '';

            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            fetchEquipment(part_name);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('userhcode'));
        try {
            const resp = await fetch(`${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString());
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.equipment.aname);
                // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
                // Getting ASSET_TYPE value as type in getEquipment API
                // Using ASSET_TYPE to getProperties
                fetchSpares(data.equipment.hcode, data.type);
                fetchAsset(data.equipment.hcode, data.type);
            } else {
                setShowProcurements(false);
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                    if (data.alias_name !== '') {
                        setaliase(data.alias_name);
                    } else {
                        setaliase(null)
                    }
                } else {
                    setShowProcurements(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const fetchSpares = async (hashCode, type) => {
        if (hashCode) {
            try {
                setLoad(true);
                const response = await fetch(`${SERVER_URL}/user/spares/procurements/${hashCode}?hcode=${localStorage.getItem('userhcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    setSpares(data.procurements);
                    setShowProcurements(true);
                } else {
                    setSpares([]);
                    setShowProcurements(false);
                    setLoad(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        fetchAssetName();
    }, [selectedPart]);



    useEffect(() => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        if (procurements.length > 0) {
            setShowProcurements(true);
            const dataTable = $(tableRef.current).DataTable();
            // Customize the table filter input

            const searchInput = $(tableRef.current).closest('.dataTables_wrapper').find('input[type="search"]');
            // Add any custom CSS classes or styles to the searchInput element

            // Adjust the position of the search input
            searchInput.css('left', '-6px');
            searchInput.css('position', 'relative');
            // Apply any additional CSS styles to move the search input to the left

            // Apply DataTables initialization
            dataTable.draw();
        }
        else {
            setShowProcurements(false);
        }
    }, [procurements]);


    return (
        <>
            {procurements.length > 0 ?
                <div id="content" className='w-100' style={{ maxWidth: '100%' }}>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>Spare Purchase History of {asset_name} {aliase && `(${aliase})`}</div>
                    <span className='fw-bold fs-5' style={{ color: '#121212' }}>Link : </span><Link className={`${styles['btn-style']}   btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>Spare part list</Link>
                    <br />
                    <div className="ps-2">
                        <div className="w-100 overflow" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>Spare Name</th>
                                        <th>Price</th>
                                        <th>Purchase Date</th>
                                        <th>Supplier</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {procurements.map((spare) => (
                                        <tr key={spare.hcode} className={`${styles['row-hover']}`}>
                                            <td >{spare.spare_name}</td>
                                            <td >{spare.price + ' ' + '$'}</td>
                                            <td >{spare.purchase_date.split('T')[0]}</td>
                                            <td >{spare.supplier}</td>
                                            <td >{spare.quantity + ' ' + spare.quantity_unit}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <>{
                    load ? <div id="content" className='w-100'>
                        <div className="ps-2">
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>Loading...</div>
                        </div>
                    </div> : <div id="content" className='w-100'>
                        <div className="m-3 ps-2">
                            <span className='fw-bold fs-5' style={{ color: '#121212' }}>Link : </span><Link className={`${styles['btn-style']}   btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>Spare part list</Link>
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>Nothing to show</div>
                        </div>
                    </div>
                }</>
            }
        </>
    );
}


export default UserViewerProcurement;
