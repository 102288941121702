import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/loginContext";
import { useNavigate } from "react-router";
import { Translate } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown } from "react-bootstrap";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Header component for displaying the navigation bar.
 *
 * @component
 */
function Header() {
  const { t, i18n } = useTranslation();
  const { isLogin } = useContext(AppContext);
  const [user, setUser] = useState('User');
  const [ip, setIp] = useState('');
  const navigate = useNavigate();
  const lang = localStorage.getItem('lang');

  /**
   * Redirects to login if the user is not logged in and is not on specific paths.
   */
  useEffect(() => {
    if (window.location.pathname !== '/login') {
      if (!localStorage.getItem('hcode')) {
        if (window.location.pathname === '/forgotpassword' || window.location.pathname === '/resetpwd') {
          return;
        } else {
          navigate('/login');
        }
      }
    }
  }, []);

  /**
   * Fetches user data from the server and sets the username and IP address.
   */
  const fetchData = async () => {
    if (localStorage.getItem('hcode')) {
      const response = await fetch(`${SERVER_URL}/adminUsername?hcode=${localStorage.getItem('hcode')}`);
      const data = await response.json();
      if (data.statusCode === 200) {
        const userName = data.username.split('@')[0];
        setUser(userName);
        setIp(data.user_ip);
      } else if (data.statusCode === 401) {
        localStorage.removeItem("hcode");
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Changes the language of the application.
   *
   * @param {string} lng - The language code to change to.
   */
  const changeLanguage = (lng) => {
    localStorage.setItem('lang', lng);
    i18n.changeLanguage(lng);
    if (window.location.pathname.split('/')[1] === 'viewer')
      window.location.reload();
  };

  useEffect(() => {
    if (lang !== undefined) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <>
      {!isLogin ? (
        <nav className="navbar" style={{ backgroundColor: '#202428' }}>
          <a className="navbar-brand ps-2" href="https://www.metso.com/" target="_blank">
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} height="40" alt="" />
          </a>
          <DropdownButton id="languageSwitcher" title={<Translate />} variant='secondary' className="pe-2">
            <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('de')}>Deutsch</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('fi')}>Finnish</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('zh-Hant')}> 普通话</Dropdown.Item>
            {/* Add more languages here */}
          </DropdownButton>
        </nav>
      ) : (
        <nav className="navbar" style={{ backgroundColor: '#202428' }}>
          <div className="container-fluid">
            <a className="navbar-brand" href="https://www.metso.com/" target="_blank">
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} height="40" alt="" />
            </a>
            <h2 style={{ color: '#fff', fontFamily: 'serif' }}>Metso Digital Solution</h2>
            <div>
              <div className="text-capitalize text-right" style={{ color: '#fff' }}>
                <DropdownButton id="languageSwitcher" title={<Translate />} variant='secondary'>
                  <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('de')}>Deutsch</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('fi')}>Finnish</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('zh-Hant')}> 普通话</Dropdown.Item>
                  {/* Add more languages here */}
                </DropdownButton>
                {t('welcome')}, {user}
              </div>
              {/* <div className='fst-italic fw-light fs-6' style={{ color: '#121212' }}>You have logged in from {ip}</div> */}
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default Header;
