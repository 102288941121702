import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import AssetModal from '../../modal/AssetsModal';
import ListProperties from '../propertyTable/PropertyTable';
import styles from '../../../common/common.module.css';
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListInstruments() {
    const { t } = useTranslation();
    const [assets, setAssets] = useState([]);
    const [showAssets, setShowAssets] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { zone_name, plant_name } = useParams();
    const [change, setChange] = useState(true);
    const navigate = useNavigate();
    const tableRef = useRef(null);

    useEffect(() => {
        fetchAssets();
    }, []);

    useEffect(() => {
        fetchAssets();
    }, [change]);

    const fetchAssets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/instruments/${plant_name}/${zone_name}?hcode=${localStorage.getItem('hcode')}`);
            const data = await response.json();
            if (data.statusCode === 200) {
                setAssets(data.assets);
                setShowAssets(true);
            } else {
                setShowAssets(false);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
            setShowAssets(false);
        }
    };

    useEffect(() => {
        if (assets.length > 0) {
            $(tableRef.current).DataTable().destroy(); // Destroy existing DataTable instance
            $(tableRef.current).DataTable(); // Initialize DataTable
        }
    }, [assets]);

    const handleAssetNameClick = (asset) => {
        setSelectedAsset(asset);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleViewSensors = (asset_hcode) => {
        navigate(`/sensors/${plant_name}/${zone_name}/${asset_hcode}`);
    };

    const handleViewFiles = (hcode) => {
        navigate(`/files/${plant_name}/${zone_name}/asset/${hcode}/3`);
    };

    const handleViewParts = (hcode) => {
        navigate(`/parts/${plant_name}/${zone_name}/asset/${hcode}`);
    };

    return (
        <>
            <div id="content" className='w-100'>
                <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/plants" style={{ color: 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: 'rgb(18, 18, 18)' }}>{t('area')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t('instruments')}</li>
                    </ol>
                </nav>
                <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfInstrumentOf')} {zone_name} {t('of')} {plant_name}</div>
                <br />
                <div className="ps-2">
                    <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                        <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                            <thead>
                                <tr className={`${styles['border-top-bottom']}`}>
                                    <th>{t('instrumentTag')}</th>
                                    <th>{t('instrumentName')}</th>
                                    <th>{t('viewFiles')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assets.map((asset) => (
                                    <tr key={asset.hcode} className={`${styles['row-hover']}`}>
                                        <td style={{ cursor: 'pointer' }} onClick={() => { handleAssetNameClick(asset) }}>{asset.instrument_tag}</td>
                                        <td>{asset.instrument_desc}</td>
                                        <td>
                                            <button className={`${styles['btn-style']}   btn`} onClick={() => handleViewFiles(asset.hcode)}>
                                                {t('viewFiles')}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {selectedAsset && (
                <AssetModal
                    asset={selectedAsset}
                    showModal={showModal}
                    closeModal={closeModal}
                    plant_name={plant_name}
                    zname={zone_name}
                    setChange={setChange}
                />
            )}
        </>
    );
}

export default ListInstruments;
