import React, { useState } from "react";
import forgotStyle from './Forgot.module.css';
import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import ForgotForm from "../../components/forgotpasspage/forgotForm";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import styled from c



function ForgotPasswordPage() {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('hcode') !== null || isLogin === true) {
            navigate('/dashboard');
        }
    }, [isLogin, navigate]);

    // const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <Wrapper>
            <Header />
            <div className={forgotStyle['div-style']}>
                {/* <div className="justify-content-start"><img className="mb-4 mt-2" src={process.env.PUBLIC_URL + '/images/logo.png'} alt="some text" style={{ maxWidth: '80%' }}
                    height="57" />
                </div> */}
                <ForgotForm />
            </div>
            <Footer />
        </Wrapper>
    );
}

export default ForgotPasswordPage;