import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import style from './style/modal.module.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchData = async (data) => {
    console.log(data);
    const response = await fetch(`${SERVER_URL}/dashboard/create?hcode=${localStorage.getItem('userhcode')}`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

const DashboardModal = ({ showModal, closeModal, accountHCode, setChange, setShowAlert, setResponseData }) => {
    const [dashName, setDashName] = useState('');
    const [comments, setComments] = useState('');
    const [isPrivate, setIsPrivate] = useState(1);
    const [plantName, setPlantName] = useState('');
    const [plants, setPlants] = useState([]);

    const navigate = useNavigate();

    const fetchPlants = async () => {
        const response = await fetch(`${SERVER_URL}/plantsList/${accountHCode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setPlants(data.plants);
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('userhcode')) {
            navigate('/userlogin');
        } else {
            fetchPlants();
        }
    }, []);

    const handleDashChange = (event) => {
        setDashName(event.target.value.trim());
    };

    const handleCommentsChange = (event) => {
        setComments(event.target.value.trim());
    };

    const handleIsPrivateChange = (event) => {
        setIsPrivate(event.target.value);
    };

    const handlePlantNameChange = (event) => {
        setPlantName(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Add validation check for empty fields
        if (!dashName || !isPrivate || !plantName) {
            return; // If any field is empty, exit the function
        }

        const data = {
            dash_name: dashName,
            comments: comments,
            isPrivate: isPrivate,
            plant_name: plantName
        };

        const response = await fetchData(data);
        const responseData = await response.json();
        setResponseData(responseData);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
        setChange(prev => !prev);
        closeModal();
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Create Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="dash_name">
                        <Form.Label>Dashboard Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="dash_name"
                            onChange={handleDashChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="comments">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                            type="text"
                            name="comments"
                            onChange={handleCommentsChange}
                        />
                    </Form.Group>
                    <br></br>
                    <Form.Group controlId="is_private" className="d-flex">
                        <Form.Label>Is Private </Form.Label>
                        <div className="ms-2">
                        <Form.Check
                            inline
                            type="radio"
                            label="Yes"
                            value= {1}
                            checked={isPrivate === 1}
                            name="is_private"
                            onChange={handleIsPrivateChange}
                            required
                        />
                        <Form.Check
                            inline
                            type="radio"
                            label="No"
                            value={0}
                            checked={isPrivate === 0}
                            name="is_private"
                            onChange={handleIsPrivateChange}
                            required
                        />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="plantName">
                        <Form.Label>Plant Name</Form.Label>
                        <Form.Select
                            name="plant_name"
                            onChange={handlePlantNameChange}
                            required
                        >
                            <option value="">Select a Plant</option>
                            {plants.map((plant) => (
                                <option key={plant.hcode} value={plant.plant_name}>
                                    {plant.plant_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
        </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
        </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default DashboardModal;
