import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import ListSensors from '../../components/tables/sensortable/SensorTable';


function DashSensors() {
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <ListSensors></ListSensors>
            </Sidebars>
            <Footer />
        </Wrapper>
    )
}

export default DashSensors;