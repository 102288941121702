import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import ListFiles from '../../components/tables/filetables/FileTable'


function DashFiles({ screenCode }) {
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <ListFiles />
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashFiles;