import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ProcurementModal = ({ hcode, showModal, closeModal, handleAddedSpare, setActiveTab }) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [units, setUnits] = useState([]);
  const [formData, setFormData] = useState({
    spare_name: '',
    price: '',
    quantity: '',
    purchase_date: '',
    supplier: '',
    quantity_unit: '',
  });
  const [formErrors, setFormErrors] = useState({
    spare_name: '',
    price: '',
    quantity: '',
    purchase_date: '',
    supplier: '',
    quantity_unit: '',
  });

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const resp = await fetch(`${SERVER_URL}/spareUnits?hcode=${localStorage.getItem('hcode')}`);
        const data = await resp.json();
        if (data.statusCode === 200) {
          setUnits(data.units);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUnits();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trim(),
    }));
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.spare_name.trim()) {
      errors.spare_name = 'Spare Name is required';
      valid = false;
    }

    if (!formData.price.trim()) {
      errors.price = 'Price is required';
      valid = false;
    } else if (isNaN(formData.price) || parseFloat(formData.price) < 0) {
      errors.price = 'Price must be a positive number';
      valid = false;
    }

    if (!formData.purchase_date) {
      errors.purchase_date = 'Purchase Date is required';
      valid = false;
    }

    if (!formData.quantity.trim()) {
      errors.quantity = 'Quantity is required';
      valid = false;
    } else if (isNaN(formData.quantity) || parseInt(formData.quantity) < 0) {
      errors.quantity = 'Quantity must be a positive number';
      valid = false;
    }

    if (!formData.quantity_unit) {
      errors.quantity_unit = 'Unit is required';
      valid = false;
    }

    if (!formData.supplier.trim()) {
      errors.supplier = 'Supplier is required';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };


  const handleFormSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const resp = await fetch(`${SERVER_URL}/spares/procurements/create/${hcode}?hcode=${localStorage.getItem('hcode')}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (resp.ok) {
        // Data successfully stored in the database
        setActiveTab('docs');

        handleAddedSpare();
        closeModal();
      } else {
        // Handle any errors
        console.error('Error storing data in the database');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Header closeButton className={`${style['modal-header']}`}>
        <Modal.Title className={`${style['modal-tittle']}`}>Add Purchase Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${style['modal-body']}`}>
        <Form className={`${style['custom-form']}`}>
          <Form.Group controlId="spare_name">
            <Form.Label className="form-label">Spare Name</Form.Label>
            <Form.Control
              type="text"
              name="spare_name"
              required
              autoComplete="off"
              className="form-control"
              onChange={handleFormChange}
              isInvalid={!!formErrors.spare_name}
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.spare_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="quantity">
            <Form.Label className="form-label">Quantity</Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              required
              className="form-control"
              onChange={handleFormChange}
              isInvalid={!!formErrors.quantity}
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.quantity}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="quantity_unit">
            <Form.Label className="form-label">Unit</Form.Label>
            <Form.Select
              name="quantity_unit"
              required
              className="form-select"
              onChange={handleFormChange}
              isInvalid={!!formErrors.quantity_unit}
            >
              <option value="">Select a Unit</option>
              {units.map((unit) => (
                <option key={unit.unit_name} value={unit.unit_name}>
                  {unit.unit_name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.quantity_unit}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="price">
            <Form.Label className="form-label">Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              required
              className="form-control"
              onChange={handleFormChange}
              isInvalid={!!formErrors.price}
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.price}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="purchase_date">
            <Form.Label className="form-label">Purchase Date</Form.Label>
            <Form.Control
              type="date"
              name="purchase_date"
              required
              className="form-control"
              onChange={handleFormChange}
              isInvalid={!!formErrors.purchase_date}
              max={currentDate}
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.purchase_date}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="supplier">
            <Form.Label className="form-label">Supplier</Form.Label>
            <Form.Control
              type="text"
              name="supplier"
              required
              autoComplete="off"
              className="form-control"
              onChange={handleFormChange}
              isInvalid={!!formErrors.supplier}
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {formErrors.supplier}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={`${style['modal-footer']}`}>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProcurementModal;
