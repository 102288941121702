import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, assetHCode) => {
    const response = await fetch(`${SERVER_URL}/assets/update/${assetHCode}?hcode=${localStorage.getItem('hcode')}`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};




const AssetModal = ({ asset, showModal, closeModal, plant_name, zname, setChange }) => {
    const assetHCode = asset.hcode;
    const [assetData, setAssetData] = useState(asset);
    const [accounts, setAccounts] = useState([]);
    const [plants, setPlants] = useState([]);
    const [zones, setZones] = useState([]);
    const [acctName, setAcctName] = useState(asset.acct_name);
    const [plantName, setPlantName] = useState(asset.plant_name);
    const [zoneName, setZoneName] = useState(asset.zname);


    const fetchAccounts = async () => {
        const response = await fetch(`${SERVER_URL}/listOfAccounts?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setAccounts(data.accounts);
    };

    const fetchPlants = async (acctName) => {
        const response = await fetch(`${SERVER_URL}/listOfPlants/${acctName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setPlants(data.plants);
    };

    const fetchZones = async (plantName) => {
        const response = await fetch(`${SERVER_URL}/listOfZones/${plantName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setZones(data.zones);
    };

    useEffect(() => {
        fetchZones(plantName);
        fetchPlants(acctName);
        fetchAccounts();
    },[]);

    useEffect(() => {
        setAssetData(asset);
    }, [asset]);



    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'aname' || name === 'adesc' || name === 'alias_name' || name === 'doc_mer_tag') {
            setAssetData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'acct_name') {
            setAcctName(value);
            fetchPlants(value);
            setAssetData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'plant_name') {
            setPlantName(value);
            fetchZones(value);
            setAssetData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'zname') {
            setZoneName(value);
            setAssetData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    const handleSaveChanges = async () => {
        const response = await fetchData(assetData, assetHCode);
        const data = await response.json();
        closeModal();
        setChange(prev => !prev);
    };


    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Edit Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="assetName">
                        <Form.Label>Asset Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="aname"
                            value={assetData.aname}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="assetAliasName">
                        <Form.Label>Asset Alias Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="alias_name"
                            value={assetData.alias_name}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="adesc">
                        <Form.Label>Asset Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="adesc"
                            value={assetData.adesc}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="assetMeridianTagName">
                        <Form.Label>Asset Meridian Tag Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="doc_mer_tag"
                            value={assetData.doc_mer_tag}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="createdOn">
                        <Form.Label>Created On</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_on"
                            value={assetData.created_on}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="created_by">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_by"
                            value={assetData.created_by}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="accountName">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Select
                            name="acct_name"
                            value={acctName}
                            onChange={handleInputChange}
                            required
                        >
                            <option>Select a Account</option>
                            {accounts.map((account) => (
                                <option key={account.hcode} value={account.acct_name}>
                                    {account.acct_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="plantName">
                        <Form.Label>Plant Name</Form.Label>
                        <Form.Select
                            name="plant_name"
                            value={plantName}
                            onChange={handleInputChange}
                            disabled={plants.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option>Select a Plant</option>
                            {plants.length === 0 ? (
                                <option disabled>No plants available</option>
                            ) : (
                                    plants.map((plant) => (
                                        <option key={plant.hcode} value={plant.plant_name}>
                                            {plant.plant_name}
                                        </option>
                                    ))
                                )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="zname">
                        <Form.Label>Area Name</Form.Label>
                        <Form.Select
                            name="zname"
                            value={zoneName}
                            onChange={handleInputChange}
                            disabled={zones.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option>Select a Zone</option>
                            {zones.length === 0 ? (
                                <option disabled>No Zones available</option>
                            ) : (
                                    zones.map((zone) => (
                                        <option key={zone.hcode} value={zone.zname}>
                                            {zone.zname}
                                        </option>
                                    ))
                                )}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default AssetModal;