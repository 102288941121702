import React, { useState, useEffect } from "react";
import DropCard from "../Dropcard/DropCard";
import style from './viewerSensor.module.css';
import DefaultGraph from "../Dropcard/Dropcarddefgraph";
import UserGraphSensors from "../googleCharts/UserGoogleGraphs";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function UserViewerSensors(props) {
    const [sensors, setSensors] = useState([]);
    const [showSensors, setShowSensors] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [shcode, setShcode] = useState(null);
    const [hcode, setHcode] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [sname, setSname] = useState("");
    const { activeSubTab, selectedPart, startDate, endDate, plantHcode } = props;
    const [searchQuery, setSearchQuery] = useState("");

    // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
    // Getting ASSET_TYPE value as type in getEquipment API
    // Using ASSET_TYPE to getAssetName
    const [assetType, setAssetType] = useState(0);

    useEffect(() => {
        const fetchAssetName = async () => {
            try {
                setHcode(null);
                let part_name = "";

                await new Promise((resolve, reject) => {
                    window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                        part_name = data.name;
                        resolve();
                    }, reject);
                });

                const newParams = new URLSearchParams();
                newParams.append("part_name", part_name);
                newParams.append("hcode", localStorage.getItem("userhcode"));

                const fetchEquipment = async () => {
                    try {
                        const resp = await fetch(
                            `${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString()
                        );
                        const data = await resp.json();
                        if (data.statusCode === 200) {
                            setAsset_name(data.equipment.aname);
                            setHcode(data.equipment.hcode);
                            setAssetType(data.type);
                            setShowSensors(false);
                            window.NOP_VIEWER.fitToView([data.equipment.assetID]);
                        } else {
                            setHcode(null);
                            setShowSensors(false);
                            setLoad(false);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                fetchEquipment();
            } catch (error) {
                console.error(error);
            }
        };

        fetchAssetName();
    }, [selectedPart]);

    useEffect(() => {
        const fetchAsset = async () => {
            if (hcode) {
                try {
                    const resp = await fetch(`${SERVER_URL}/getAssetName/${hcode}/${assetType}`);
                    const data = await resp.json();
                    if (data.statusCode === 200) {
                        setAsset_name(data.aname);
                    } else {
                        setShowSensors(false);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchAsset();
    }, [assetType]);

    useEffect(() => {
        const fetchSensors = async () => {
            if (hcode) {
                try {
                    setLoad(true);
                    const response = await fetch(
                        `${SERVER_URL}/user/sensors/${hcode}?hcode=${localStorage.getItem("userhcode")}`
                    );
                    const data = await response.json();
                    if (data.statusCode === 409) {
                        setShowSensors(false);
                        setLoad(false);
                        return;
                    } else if (data.statusCode === 200) {
                        if (data.sensors.length > 0) {
                            setSensors(data.sensors);
                            setShowSensors(true);
                            setLoad(false);
                        } else {
                            setLoad(false);
                        }
                    } else {
                        setShowSensors(false);
                        setLoad(false);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchSensors();
    }, [hcode]);

    function handleClickGraph(sname, shcode) {
        setShcode(shcode);
        setSname(sname);
        setShowModal(true);
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredSensors = sensors.filter((sensor) =>
        sensor.sname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            {showSensors && (
                <div className="sticky-top w-100 mb-2">
                    <h2 className="text-center" style={{ color: '#121212' }}> Drop The Sensor to See their Graphs </h2>
                    <div className="d-flex flex-wrap justify-content-center gap-1">
                        {sensors.length === 1 ? (
                            <DefaultGraph activeSubTab={activeSubTab} defaultSensor={sensors[0]} startDate={startDate} endDate={endDate}></DefaultGraph>
                        ) : (
                            sensors.slice(0, 2).map((sensor) => (
                                <DefaultGraph key={sensor.hcode} activeSubTab={activeSubTab} defaultSensor={sensor} startDate={startDate} endDate={endDate}></DefaultGraph>
                            ))
                        )}
                    </div>
                </div>
            )}
            {showSensors && (
                <div className="mb-3 ms-2">
                    <input
                        className={`${style['input-style']}  p-2`}
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            )}
            <div>

                {showSensors ? (
                    <>
                        <div style={{ color: '#121212' }} className="h4 text-center"> Listed Sensors </div>
                        <ul className={`${style['custom-style']} list-group`}>
                            {filteredSensors.map((sensor) => (
                                <li
                                    draggable="true"
                                    onDragStart={(event) => {
                                        event.dataTransfer.setData("text/plain", JSON.stringify(sensor));
                                    }}
                                    className='w-100 list-group-item'
                                    style={{ color: '#121212', backgroundColor: 'transparent' }}
                                    onDoubleClick={() => handleClickGraph(sensor.sname, sensor.hcode)}
                                    key={sensor.hcode}
                                >
                                    {sensor.sname}
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        {load ? (
                            <div id="content" className="w-100">
                                <div className="ps-2">
                                    <div className="w-100 text-center h1">Loading...</div>
                                </div>
                            </div>
                        ) : (
                            <div id="content" className="w-100">
                                <div className="ps-2">
                                    <div className="w-100 text-center h1">Nothing to show</div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {showModal && (
                    <UserGraphSensors
                        sname={sname}
                        shcode={shcode}
                        startDate={startDate}
                        endDate={endDate}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                )}
            </div>
        </>
    );
}

export default UserViewerSensors;
