import React, { useState } from "react";
import styles from './forgotForm.module.css';
import Alert from '../alert/Alert';
import { Link } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * UserForgotForm component allows users to request a password reset link.
 *
 * @component
 */
function UserForgotForm() {
    const [username, setUsername] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    /**
     * Handles changes to the email input field.
     *
     * @param {object} event - The input change event.
     */
    const handleUserName = (event) => {
        setUsername(event.target.value);
    };

    /**
     * Handles the form submission to request a password reset link.
     *
     * @param {object} event - The form submission event.
     */
    const handleFormSubmit = (event) => {
        event.preventDefault();

        const serverUrl = SERVER_URL;
        fetch(`${serverUrl}/forgotUserPassword`, {
            method: 'POST',
            body: JSON.stringify({ email: username }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    };

    return (
        <div className={styles['form-signin']}>
            <form method="post" onSubmit={handleFormSubmit}>
                <div id="msg" className="w-100">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <h1 className="h1 mb-3 fw-normal" style={{ color: '#8a9198' }}>Forgot Password</h1>
                <div className="mb-2">
                    <label htmlFor="email" className="h4" style={{ color: '#8a9198' }}>Email address</label>
                    <input type="email" className="form-control" name="email" placeholder="name@example.com" onChange={handleUserName} autoComplete="" required />
                </div>
                <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center w-100">
                    <Link to="/userlogin" className={`btn mt-1 align-self-sm-auto align-self-start ${styles['btn-o-style']}`}>Login Page</Link>
                    <button className={`col-sm-4 col-12 btn btn-lg ${styles['btn-style']}`} type="submit">Send Reset Link</button>
                </div>
            </form>
        </div>
    );
}

export default UserForgotForm;
