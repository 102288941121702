import React, { useState, useRef } from 'react';
import styles from '../../common/common.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const MultipleFileUploadButton = ({ setChange }) => {
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const { plantHCode } = useParams();


    const handleFileUpload = () => {
        if (fileInputRef.current && fileInputRef.current.files.length > 0) {
            const selectedFiles = fileInputRef.current.files;
            setUploading(true);

            const formData = new FormData();

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('files', selectedFiles[i]);
            }

            fetch(`${SERVER_URL}/multipleFiles/upload/${plantHCode}?hcode=${localStorage.getItem('hcode')}`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    setChange((prev) => !prev);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setUploading(false);
                    fileInputRef.current.value = null;
                });
        }
    };

    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                multiple
            />
            <button
                className="btn btn-secondary"
                style={{ lineHeight: '1' }}
                onClick={() => fileInputRef.current.click()}
                disabled={uploading}
            >
                {uploading ? t('uploading') : t('uploadFiles')}
            </button>
        </>
    );
};

export default MultipleFileUploadButton;
