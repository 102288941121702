import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery for DOM manipulation
import 'datatables.net-bs5'; // Import DataTables for table enhancements
import AssetModal from '../../modal/AssetsModal'; // Import AssetModal component
import ListProperties from '../propertyTable/PropertyTable'; // Import ListProperties component
import styles from '../../../common/common.module.css'; // Import CSS modules for styling
import OverlayWithTabs from '../../overlays/overlaysWithTabs'; // Import OverlayWithTabs component
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations

const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

/**
 * Component to list assets based on plant and zone.
 * Handles fetching data, displaying assets in a table, and navigation to related pages.
 */
function ListAssets() {
    const { t } = useTranslation(); // Translation function
    const [assets, setAssets] = useState([]); // State to hold assets data
    const [showAssets, setShowAssets] = useState(false); // State to control asset display
    const [selectedAsset, setSelectedAsset] = useState(null); // State to manage selected asset for modal
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const { zone_name, plant_name } = useParams(); // Get plant_name and zone_name from URL params
    const [change, setChange] = useState(true); // State to trigger data fetching
    const navigate = useNavigate(); // Navigation hook from react-router-dom
    const tableRef = useRef(null); // Ref to hold reference to the table DOM element

    const ASSET_TYPE = 1; // Constant for asset type (example usage)

    // Fetch assets when component mounts
    useEffect(() => {
        fetchAssets();
    }, []);

    // Fetch assets when 'change' state updates
    useEffect(() => {
        fetchAssets();
    }, [change]);

    // Function to fetch assets data from the server
    const fetchAssets = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/assets/${plant_name}/${zone_name}?hcode=${localStorage.getItem('hcode')}`);
            const data = await response.json();

            if (data.statusCode === 200) {
                setAssets(data.assets); // Update assets state with fetched data
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    // Initialize DataTables when 'assets' state updates
    useEffect(() => {
        if (assets.length > 0) {
            setShowAssets(true); // Show assets when data is available
            $(tableRef.current).DataTable({
                destroy: true, // Destroy previous instance if exists
                language: t('dataTable', { returnObjects: true }) // Localized language settings for DataTables
            });
        }
    }, [assets, t]);

    // Function to handle click on asset name to display modal
    const handleAssetNameClick = (asset) => {
        setSelectedAsset(asset); // Set selected asset for modal display
        setShowModal(true); // Show the modal
    };

    // Function to close the modal
    const closeModal = () => {
        setShowModal(false); // Hide the modal
    };

    // Function to navigate to sensors page for the selected asset
    const handleViewSensors = (asset_hcode) => {
        navigate(`/sensors/${plant_name}/${zone_name}/${asset_hcode}`);
    };

    // Function to navigate to files page for the selected asset
    const handleViewFiles = (hcode) => {
        navigate(`/files/${plant_name}/${zone_name}/asset/${hcode}/${ASSET_TYPE}`);
    };

    // Function to navigate to parts page for the selected asset
    const handleViewParts = (hcode) => {
        navigate(`/parts/${plant_name}/${zone_name}/asset/${hcode}`);
    };

    // Function to open analytics in a new window/tab for the selected asset
    const handleViewAnalytics = (urn, hcode) => {
        window.open(`/viewer/${plant_name}/${zone_name}/${hcode}?urn=${urn}&hcode=${hcode}`, '_blank');
    };

    // Render component
    return (
        <>
            {/* Conditionally render based on assets availability */}
            {showAssets ? (
                <div id="content" className='w-100'>
                    {/* Breadcrumb navigation */}
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/plants" style={{ 'color': 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ 'color': 'rgb(18, 18, 18)' }}>{t('area')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('equipment')}</li>
                        </ol>
                        {/* Optionally, add other elements like overlays here */}
                    </nav>

                    {/* Header */}
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfAssetOf')} {zone_name} {t('of')} {plant_name}</div>
                    <br />

                    {/* Table */}
                    <div className="ps-2">
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('assetName')}</th>
                                        <th>{t('assetAliasName')}</th>
                                        <th>{t('viewFiles')}</th>
                                        <th>{t('viewSensors')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Render each asset row */}
                                    {assets.map((asset) => (
                                        <tr key={asset.hcode} className={`${styles['row-hover']}`}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleAssetNameClick(asset) }}>{asset.aname}</td>
                                            <td>{asset.alias_name}</td>
                                            <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewFiles(asset.hcode)}
                                                >
                                                    {t('viewFiles')}
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewSensors(asset.hcode)}
                                                >
                                                    {t('viewSensors')}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div id="content" className='w-100'>
                    {/* Breadcrumb navigation */}
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/plants" style={{ 'color': 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ 'color': 'rgb(18, 18, 18)' }}>{t('area')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('equipment')}</li>
                        </ol>
                        {/* Optionally, add other elements like overlays here */}
                    </nav>

                    {/* Header */}
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfAssetOf')} {zone_name} {t('of')} {plant_name}</div>
                    <br />

                    {/* No assets message */}
                    <div className="ps-2">
                        <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>
                    </div>
                </div>
            )}

            {/* Modal for displaying asset details */}
            {selectedAsset && (
                <AssetModal
                    asset={selectedAsset}
                    showModal={showModal}
                    closeModal={closeModal}
                    plant_name={plant_name}
                    zname={zone_name}
                    setChange={setChange} // Pass setChange function to update parent component state
                />
            )}
        </>
    );
}

export default ListAssets;
