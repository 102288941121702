import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GraphSensors from "../googleCharts/GoogleGraphs";
import DropCard from "../Dropcard/DropCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListAllSensors({ startDate, endDate }) {
    const [sensors, setSensors] = useState([]);
    const [show, setShow] = useState(false);
    const { assetHCode, zone_name, plant_name } = useParams();
    const [shcode, setShcode] = useState("");
    const [sname, setSname] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    function handleClickGraph(sname, shcode) {
        setShcode(shcode);
        setSname(sname);
        setShowModal(true);
    }

    useEffect(() => {
        fetchSensors();
    }, []);

    const fetchSensors = async () => {
        const response = await fetch(
            `${SERVER_URL}/sensors/${assetHCode}?hcode=${localStorage.getItem(
                "hcode"
            )}`
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            setSensors(data.sensors);
            setShow(true);
        } else {
            setShow(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredSensors = sensors.filter((sensor) =>
        sensor.sname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="border border-success bg-info sticky-top">
                <h2 className="text-center"> Drop The Sensor to See their Graphs </h2>
                <div className="d-flex mt-1 mb-1 ms-1 me-1 flex-wrap justify-content-center">
                    <DropCard startDate={startDate} endDate={endDate}></DropCard>
                    <DropCard startDate={startDate} endDate={endDate}></DropCard>
                    <DropCard startDate={startDate} endDate={endDate}></DropCard>
                    <DropCard startDate={startDate} endDate={endDate}></DropCard>
                </div>
            </div>
            <br></br>
            <div className="mb-3 ms-1">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="d-flex mt-1 mb-1 ms-1 me-1 flex-wrap justify-content-center">
                {show &&
                    filteredSensors.map((sensor) => (
                        <div
                            draggable="true"
                            onDragStart={(event) => {
                                event.dataTransfer.setData("text/plain", JSON.stringify(sensor));
                            }}
                            className="card mb-2 ms-2 me-2"
                            style={{ width: "18rem" }}
                            onDoubleClick={() => handleClickGraph(sensor.sname, sensor.hcode)}
                            key={sensor.sname}
                        >
                            <div className="card-body">
                                <h5 className="card-title">{sensor.sname}</h5>
                            </div>
                        </div>
                    ))}
                {showModal && (
                    <GraphSensors
                        sname={sname}
                        shcode={shcode}
                        startDate={startDate}
                        endDate={endDate}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                )}
            </div>
        </>
    );
}

export default ListAllSensors;
