import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
// import ListAccounts from '../../components/tables/accounttables/AccountTable';
import ListUsers from '../../components/tables/usertables/UserTable';

function DashPageUsers({screenCode}) {
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <ListUsers></ListUsers>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashPageUsers;