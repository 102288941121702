import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import CreateAccountForm from '../../components/dashpage/CreateAccount';
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function DashPageAddAccounts({screenCode}) {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
    }, [isLogin, navigate]);
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <CreateAccountForm></CreateAccountForm>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashPageAddAccounts;