import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const UploadDefineAsset = ({ setSave }) => {
    const fileInputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { plant_name, plantHCode } = useParams();
    const [plant_unit, setPlantUnit] = useState('');
    const [textToReplace, setTextToReplace] = useState('');
    const [replaceWith, setReplaceWith] = useState('');
    const { t } = useTranslation();



    const handleFileUpload = async () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Perform your desired function on each row of the sheet
                const headerIndexes = {};

                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    if (rowIndex + 1 === jsonData.length) {
                        setTimeout(() => {
                            setSave(false)
                        }, 1000)
                    }
                    const row = jsonData[rowIndex];
                    if (rowIndex === 0) {
                        // Find the indexes of the desired headers
                        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                            const header = row[columnIndex];
                            if (header === 'Equipment Tagname') {
                                headerIndexes.nameIndex = columnIndex;
                            } else if (header === 'Description') {
                                headerIndexes.descriptionIndex = columnIndex;
                            } else if (header === 'Equipment Class') {
                                headerIndexes.equipmentClassIndex = columnIndex;
                            } else if (header === 'Plant Code') {
                                headerIndexes.plantUnitIndex = columnIndex;
                            } else if (header === 'PI-Diagrams') {
                                headerIndexes.fileIndex = columnIndex;
                            }
                        }

                        // Log the found indexes (optional)
                    } else {
                        // Extract values based on the found indexes
                        const name = row[headerIndexes.nameIndex].replace(textToReplace, replaceWith);
                        const description = row[headerIndexes.descriptionIndex];
                        const plantUnit = row[headerIndexes.plantUnitIndex];
                        const fileNames = row[headerIndexes.fileIndex];

                        try {
                            if (description && plantUnit && name) {
                                console.log('started')
                                let assetName;
                                if (name.startsWith('/')) {
                                    assetName = name.trim();
                                } else {
                                    assetName = '/' + name.trim();
                                }
                                const dbIds = await new Promise((resolve, reject) => {
                                    console.log(assetName);
                                    window.NOP_VIEWER.model.search(assetName, (dbIds) => {
                                        resolve(dbIds);
                                    }, reject);
                                });
                                const data = await new Promise((resolve, reject) => {
                                    window.NOP_VIEWER.model.getBulkProperties(dbIds, { propFilter: ['dbId', 'name'] }, (data) => {
                                        resolve(data);
                                    }, reject);
                                });

                                let assetlist = [];
                                for (let i = 0; i < data.length; i++) {
                                    if (data[i].name === assetName) {
                                        assetlist.push(data[i]);
                                        break;
                                    }
                                }

                                if (assetlist.length > 0) {

                                    const asset_list = await Promise.all(assetlist.map(async ({ dbId, name }) => {
                                        const asset_with_part = {};
                                        asset_with_part['adbid'] = dbId;
                                        asset_with_part['aname'] = name;
                                        asset_with_part['alias_name'] = description;
                                        window.NOP_VIEWER.fitToView([dbId]);
                                        window.NOP_VIEWER.isolate([dbId]);
                                        asset_with_part['parts'] = [];

                                        let tree = window.NOP_VIEWER.model.getInstanceTree();
                                        const getPropertiesPromises = [];

                                        await new Promise((resolve) => {
                                            tree.enumNodeChildren(dbId, function (id) {
                                                if (window.NOP_VIEWER.isNodeVisible(id) && id !== dbId) {
                                                    const getPropertiesPromise = new Promise((resolveProperties) => {
                                                        window.NOP_VIEWER.getProperties(id, (data) => {
                                                            asset_with_part.parts.push({ pname: data.name, pdbid: data.dbId });
                                                            resolveProperties();
                                                        });
                                                    });

                                                    getPropertiesPromises.push(getPropertiesPromise);
                                                }
                                            }, true);

                                            Promise.all(getPropertiesPromises).then(() => {
                                                resolve(); // Resolve the outer promise after all getProperties calls have completed
                                            });
                                        });

                                        return asset_with_part;
                                    }));
                                    const resp = await fetch(`${SERVER_URL}/assets/define?hcode=${localStorage.getItem('hcode')}`, {

                                        method: 'POST',
                                        // Convert the form data object to a JSON string and include it in the request body
                                        body: JSON.stringify({
                                            assetsList: asset_list,
                                            zoneID: plantUnit,
                                            plantHCode: plantHCode,
                                            plant_name: plant_name,
                                            fileNames: fileNames,
                                            doc_code: 12,
                                        }),
                                        headers: {
                                            // Set the Content-Type header to application/json
                                            // Tells the server that the content type of the request body is JSON.
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                }
                            }
                            else {
                                console.log('not called')
                            }
                        } catch (error) {
                            console.error(error);
                        }
                        // Perform your desired function on the extracted data
                    }
                }

            }
        };

        reader.readAsArrayBuffer(file);

    };




    const handleReplaceWith = (e) => {
        setReplaceWith(e.target.value);
    };

    const handleTextToReplace = (e) => {
        setTextToReplace(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSave(true);
        setModalOpen(false);
        await handleFileUpload();
    };

    return (
        <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
            <h3 className=''>{plant_name}</h3>
            <br></br>
            <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadExcel')}</button>
                <FileDownloadButton filename={'DefineAsset.xlsx'} title={'Download Asset Template'}></FileDownloadButton>
            </div>
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title >{t('uploadExcelFile')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required />
                            <br />
                            <h5 className='form-label'>{t('replaceTextInTagname')}</h5>
                            <input type="text" value={textToReplace} onChange={handleTextToReplace} className='w-100 mb-2  p-1' />
                            <br />
                            <h5 className='form-label'>{t('replaceWith')}</h5>
                            <input type="text" value={replaceWith} onChange={handleReplaceWith} className='w-100 mb-2  p-1' />
                            <br />
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </div>
    );
};

export default UploadDefineAsset;
