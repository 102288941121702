import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;



/**
 * The `ListUsers` function in JavaScript fetches and displays a list of users for a specific account,
 * allowing actions like activating or deactivating users.
 * @returns The `ListUsers` function is returning a React component that displays a list of users for a
 * specific account. The component fetches user data from a server, displays a breadcrumb navigation, a
 * header indicating the list of users for a specific account, and a table showing user details such as
 * email, status, created by, and an option to activate or deactivate users. The component also
 * includes a button to
 */
function ListUsers() {
  const [users, setUsers] = useState([]);
  const [change, setChange] = useState('');
  const tableRef = useRef(null);
  const { t } = useTranslation();


  const data = {
    'hcode': localStorage.getItem('hcode')
  }
  const { acct_name } = useParams();

  useEffect(() => {
    fetch(`${SERVER_URL}/users/${acct_name}?hcode=${data.hcode}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          setUsers(data.users);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [change]);

  useEffect(() => {
    if (users.length > 0) {
      $(tableRef.current).DataTable({
        language: t('dataTable', { returnObjects: true })
      });
    }
  }, [users]);

  const fetchData = async (data) => {

    const response = await fetch(`${SERVER_URL}/users/isEnabled?hcode=${localStorage.getItem('hcode')}`, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify({
        email: data
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setChange(response);

    return response;
  };



  return (
    <div id="content" className='w-100'>
      <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/" style={{ color: '#121212' }}>{t('accounts')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('user')}</li>
        </ol>
        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
      </nav>
      <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfUsersOf')} {acct_name}</div>
      <br />
      <div className="ps-2">
        <div className="row row-cols-5 mb-2">
          <button
            className={`${styles['btn-style']}   btn offset-9`}
            onClick={() => window.location.href = `/addusers/${acct_name}`}
          >
            {t('addUser')}
          </button>
        </div>
        <div className="overflow-auto w-100" style={{ maxHeight: '100%' }}>
          <table id="table" className={`table ${styles.table} table-sm`} ref={tableRef}>
            <thead>
              <tr>
                <th>{t('email')}</th>
                <th>{t('status')}</th>
                <th>{t('createdBy')}</th>
                <th>{t('update')}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email}>
                  <td>{user.email}</td>
                  <td>{user.isEnabled ? t('active') : t('inactive')}</td>
                  <td>{user.created_by}</td>
                  <td>
                    <button
                      className={`btn ${user.isEnabled ? 'btn-outline-danger' : 'btn-outline-success'}`}
                      style={{ lineHeight: '1' }}
                      onClick={() => {
                        fetchData(user.email);                        // some actions
                      }}
                    >
                      {user.isEnabled ? t('deactivate') : t('activate')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListUsers;
