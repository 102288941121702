import React, { useState, useEffect } from "react";
import GraphSensors from "../googleCharts/GoogleGraphs";
import DropCard from "../Dropcard/DropCard";
import style from './viewerSensor.module.css';
import DefaultGraph from "../Dropcard/Dropcarddefgraph";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewerSensors(props) {

    const { t } = useTranslation();
    const [sensors, setSensors] = useState([]);
    const [showSensors, setShowSensors] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [shcode, setShcode] = useState(null);
    const [hcode, setHcode] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [sname, setSname] = useState("");
    const { activeSubTab, selectedPart, startDate, endDate } = props;
    const [searchQuery, setSearchQuery] = useState("");
    const { plantHcode, plant_name } = useParams();

    // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
    // Getting ASSET_TYPE value as type in getEquipment API
    // Using ASSET_TYPE to getAssetName
    const [assetType, setAssetType] = useState(0);

    useEffect(() => {
        const fetchAssetName = async () => {
            try {
                setHcode(null);
                let part_name = "";

                await new Promise((resolve, reject) => {
                    window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                        part_name = data.name;
                        resolve();
                    }, reject);
                });

                const newParams = new URLSearchParams();
                newParams.append("part_name", part_name);
                newParams.append("hcode", localStorage.getItem("hcode"));

                const fetchEquipment = async () => {
                    try {
                        const resp = await fetch(
                            `${SERVER_URL}/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString()
                        );
                        const data = await resp.json();
                        if (data.statusCode === 200) {
                            setAsset_name(data.equipment.aname);
                            setHcode(data.equipment.hcode);
                            setAssetType(data.type);
                            // setShowSensors(false);
                            window.NOP_VIEWER.fitToView([data.equipment.assetID]);
                        } else {
                            setHcode(null);
                            // setShowSensors(false);
                            setLoad(false);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                fetchEquipment();
            } catch (error) {
                console.error(error);
            }
        };

        fetchAssetName();
    }, [selectedPart]);

    useEffect(() => {
        const fetchAsset = async () => {
            if (hcode) {
                try {
                    const resp = await fetch(`${SERVER_URL}/getAssetName/${hcode}/${assetType}`);
                    const data = await resp.json();
                    if (data.statusCode === 200) {
                        setAsset_name(data.aname);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchAsset();
    }, [assetType]);

    useEffect(() => {
        const fetchSensors = async () => {
            if (hcode) {
                try {
                    setLoad(true);
                    const response = await fetch(
                        `${SERVER_URL}/sensors/${hcode}?hcode=${localStorage.getItem("hcode")}`
                    );
                    const data = await response.json();
                    console.log(data)
                    if (data.statusCode === 409) {
                        setShowSensors(false);
                        setLoad(false);
                        return;
                    } else if (data.statusCode === 200) {
                        if (data.sensors.length > 0) {
                            setSensors(data.sensors);
                            setShowSensors(true);
                            setLoad(false);
                        } else {
                            setSensors([{
                                "hcode": "120399912177d758381d9ca098c9d3f7ba68e12ec2af",
                                "sname": "AC7_AC_GMX_InnTemp1",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-28T22:12:15.000Z",
                                "aname": "/HH51-AU001",
                                "zname": "PRESSURE LEACHING",
                                "plant_name": "HYDROMETALLURGICAL LITHIUM PLANT",
                                "acct_name": "Metso"
                            },
                            {
                                "hcode": "e04ff7ff4b02211854b017c45daf40a2ab8030e7b332",
                                "sname": "AC7_AC_GMX_InnTemp2",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-28T22:12:15.000Z",
                                "aname": "/HH51-AU001",
                                "zname": "PRESSURE LEACHING",
                                "plant_name": "HYDROMETALLURGICAL LITHIUM PLANT",
                                "acct_name": "Metso"
                            },
                            {
                                "hcode": "049d84f8ce5a480914935f813169bb1400d1e9279c98",
                                "sname": "AC7_AC_GMX_InnTemp3",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-28T22:12:15.000Z",
                                "aname": "/HH51-AU001",
                                "zname": "PRESSURE LEACHING",
                                "plant_name": "HYDROMETALLURGICAL LITHIUM PLANT",
                                "acct_name": "Metso"
                            },
                            {
                                "hcode": "c2ac0e5efc1091245c4f3af54702be7183d4e98b0e0c",
                                "sname": "AC7_CalcPulp_GMX_Outflow",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-28T22:12:15.000Z",
                                "aname": "/HH51-AU001",
                                "zname": "PRESSURE LEACHING",
                                "plant_name": "HYDROMETALLURGICAL LITHIUM PLANT",
                                "acct_name": "Metso"
                            }])
                            setShowSensors(true);
                            setLoad(false);
                        }
                    } else {
                        // setShowSensors(false);
                        setLoad(false);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchSensors();
    }, [hcode]);

    function handleClickGraph(sname, shcode) {
        setShcode(shcode);
        setSname(sname);
        setShowModal(true);
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredSensors = sensors.filter((sensor) =>
        sensor.sname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        console.log('show sensors', showSensors)
    }, [showSensors])

    return (
        <>
            {showSensors && (
                <div className="sticky-top w-100 mb-2">
                    {
                        activeSubTab === 'Date' &&
                        <h2 className="text-center" style={{ color: '#121212' }}> {t('dropTheSensorToSeeGraphs')} </h2>
                    }
                    <div className="d-flex flex-wrap justify-content-center gap-1">
                        {sensors.length === 1 ? (
                            <DefaultGraph activeSubTab={activeSubTab} defaultSensor={sensors[0]} startDate={startDate} endDate={endDate}></DefaultGraph>
                        ) : (
                            sensors.slice(0, 2).map((sensor) => (
                                <DefaultGraph key={sensor.hcode} activeSubTab={activeSubTab} defaultSensor={sensor} startDate={startDate} endDate={endDate}></DefaultGraph>
                            ))
                        )}
                    </div>
                </div>
            )}
            {showSensors && activeSubTab === 'Date' && (
                <div className="mb-3 ms-2">
                    <input
                        className={`${style['input-style']}  p-2`}
                        type="text"
                        placeholder={`${t('searche')}...`}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            )}
            <div>

                {showSensors && activeSubTab === 'Date' ? (
                    <>
                        <div style={{ color: '#121212' }} className="h4 text-center"> {t('listedSensor')} </div>
                        <ul className={`${style['custom-style']} list-group`}>
                            {filteredSensors.map((sensor) => (
                                <li
                                    draggable="true"
                                    onDragStart={(event) => {
                                        event.dataTransfer.setData("text/plain", JSON.stringify(sensor));
                                    }}
                                    className='w-100 list-group-item'
                                    style={{ color: '#121212', backgroundColor: 'transparent' }}
                                    onDoubleClick={() => handleClickGraph(sensor.sname, sensor.hcode)}
                                    key={sensor.hcode}
                                >
                                    {sensor.sname}
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        {load ? (
                            <div id="content" className="w-100">
                                <div className="ps-2">
                                    <div className="w-100 text-center h1">{t('loading')}...</div>
                                </div>
                            </div>
                        ) : (
                            <div id="content" className="w-100">
                                <div className="ps-2">
                                    <div className="w-100 text-center h1">{t('nothingToShow')}</div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {showModal && (
                    <GraphSensors
                        sname={sname}
                        shcode={shcode}
                        startDate={startDate}
                        endDate={endDate}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                )}
            </div>
        </>
    );
}

export default ViewerSensors;
