import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;



const fetchData = async (data, zoneHCode) => {

    const response = await fetch(`${SERVER_URL}/zones/update/${zoneHCode}?hcode=${localStorage.getItem('hcode')}`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};




const ZoneModal = ({ zone, showModal, closeModal, setChange }) => {

    const zoneHCode = zone.hcode;
    const [zoneData, setZoneData] = useState(zone);
    const [accounts, setAccounts] = useState([]);
    const [plants, setPlants] = useState([]);
    const [acctName, setAcctName] = useState(zoneData.acct_name);


    const fetchAccounts = async () => {
        const response = await fetch(`${SERVER_URL}/listOfAccounts?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setAccounts(data.accounts);
    };

    const fetchPlants = async (acctName) => {
        const response = await fetch(`${SERVER_URL}/listOfPlants/${acctName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setPlants(data.plants);
    };

    useEffect(() => {
        fetchPlants(zoneData.acct_name);
        fetchAccounts();
        setZoneData(zone);
    }, [zone]);



    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'zname' || name === 'plant_name') {
            setZoneData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'acct_name') {
            setAcctName(value);
            fetchPlants(value);
            setZoneData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };





    const handleSaveChanges = async () => {
        // Logic to save the changes to the plant data

        const response = await fetchData(zoneData, zoneHCode);
        const data = await response.json();

        closeModal();
        setChange(response);
        // window.location.reload();
    };




    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Edit Area</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="zoneName">
                        <Form.Label>Area Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="zname"
                            value={zoneData.zname}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="zoneID">
                        <Form.Label>Area Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="zoneID"
                            value={zoneData.zoneID}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="createdOn">
                        <Form.Label>Created On</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_on"
                            value={zoneData.created_on}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="createdBy">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_by"
                            value={zoneData.created_by}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="accountName">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Select
                            name="acct_name"
                            value={acctName}
                            onChange={handleInputChange}
                            required
                        >
                            <option>Select a Account</option>
                            {accounts.map((account) => (
                                <option key={account.hcode} value={account.acct_name}>
                                    {account.acct_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="plantName">
                        <Form.Label>Plant Name</Form.Label>
                        <Form.Select
                            name="plant_name"
                            value={zoneData.plant_name}
                            onChange={handleInputChange}
                            disabled={plants.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option>Select a Plant</option>
                            {plants.length === 0 ? (
                                <option disabled>No plants available</option>
                            ) : (
                                    plants.map((plant) => (
                                        <option key={plant.hcode} value={plant.plant_name}>
                                            {plant.plant_name}
                                        </option>
                                    ))
                                )}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body> 
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};




export default ZoneModal;