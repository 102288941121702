import { useEffect, useState } from 'react';
import { initViewer, loadModel } from './helpViewer';
import { useLocation } from 'react-router-dom';
import './viewer.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Initialize the container for the model to view in web browser
 */
const MainViewer = (props) => {

    const { width, height, setSelectedPart } = props;

    // Get the current location object
    const location = useLocation();
    // Parse URL search parameters
    const params = new URLSearchParams(location.search);
    // Get the 'urn' parameter from the URL
    const urn = params.get('urn');

    const [modelTranslated, setModelTranslated] = useState(false);



    useEffect(() => {
        const manifest = async () => {
            const req = await fetch(`${SERVER_URL}/manifest/${urn}?hcode=${localStorage.getItem('hcode')}`);
            const respone = await req.json();
            return respone;
        }
        const data = manifest(); 



    }, [])


    useEffect(() => {
        initViewer(document.getElementById('viewer')).then((viewer) => {
            viewer.addEventListener(window.Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => {
                setSelectedPart(window.NOP_VIEWER.getSelection()[0]);
            })
            loadModel(viewer, urn);
        })
    }, [])
    return <div id="viewer" className='mb-2 mt-2 ms-2' style={{ position: 'relative', width: width, height: height, boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset' }} />;
};


export default MainViewer;
