import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// DOC_CODE

// EQUIPMENT_OUTLINE	11
// EQUIPMENT_PID		12
// PIPE_ISOMETRIC		21
// PIPE_PID		        22
// INSTRUMENT		    31
// VALVE			    41
// PLANT			    0

// TYPE OF EQUIPMENT

// EQUIPMENT            1
// PIPE                 1
// INSTRUMENT           3
// VALVE                4
// PLANT			    0


const UploadPlantDocumentsExcel = () => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { plant_name, plantHcode, urn } = useParams();


    const handlePlantDocumentsFileUpload = async () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headerIndexes = {};

                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    if (rowIndex + 1 === jsonData.length) {
                        setTimeout(() => {
                            // setSave(false)
                        }, 1000)
                    }
                    const row = jsonData[rowIndex];
                    // console.log(row);
                    if (rowIndex === 0) {
                        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                            const header = row[columnIndex];
                            if (header === 'File Name') {
                                headerIndexes.fileName = columnIndex;
                            }
                        }
                    } else {
                        const fileName = row[headerIndexes.fileName] || '';

                        if (fileName.trim()) {
                            const resp = await fetch(`${SERVER_URL}/upload/assets/files?hcode=${localStorage.getItem('hcode')}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    type: 0,
                                    plant_name: plant_name,
                                    doc_code: 0,
                                    fname: fileName.trim()
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })

                            const data = await resp.json();
                            console.log(data);
                        }
                    }
                }

            }
        };

        reader.readAsArrayBuffer(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setSave(true);
        setModalOpen(false);
        await handlePlantDocumentsFileUpload();
    };

    return (
        <>
            <span className='m-2 border' style={{ padding: '11px' }}>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadPlantDocumentExcelFile')}</button>
                <FileDownloadButton filename={'PlantDocuments.xlsx'} title={t('downloadUploadPlantDocumentTemplate')}></FileDownloadButton>
            </span>
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title >{t('uploadPlantDocumentExcelFile')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required />
                            <br />
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default UploadPlantDocumentsExcel;
