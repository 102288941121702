import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from './SetPasswordForm.module.css';
import Alert from '../alert/Alert';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function validatePassword(password) {
    const MIN_LENGTH = 8;
    const UPPERCASE_REGEX = /[A-Z]/;
    const LOWERCASE_REGEX = /[a-z]/;
    const SPECIAL_CHAR_REGEX = /[@$!%*?&]/;

    if (password.length < MIN_LENGTH) {
        return false;
    }

    if (!UPPERCASE_REGEX.test(password)) {
        return false;
    }

    if (!LOWERCASE_REGEX.test(password)) {
        return false;
    }

    if (!SPECIAL_CHAR_REGEX.test(password)) {
        return false;
    }

    return true;
}

function SetPasswordsForm() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkLink, setCheckLink] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${SERVER_URL}/resetpwd${window.location.search}`, { method: 'GET' });
                const data = await response.json();
                if (data.statusCode !== 200) {
                    setCheckLink(false);
                    setResponseData({
                        statusCode: 200,
                        message: 'The link might not be correct or may have expired.'
                    });
                    setShowAlert(true);
                } else {
                    setCheckLink(true);
                }
            } catch (error) {
                console.error('Error:', error);
                setCheckLink(false);
                setResponseData({
                    statusCode: 500,
                    message: 'Internal Server Error. Please try again later.'
                });
                setShowAlert(true);
            }
        }
        fetchData();
    }, []);

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (validatePassword(password) && (confirmPassword === password)) {
            const serverUrl = SERVER_URL;

            fetch(`${serverUrl}/resetpwd${window.location.search}`, {
                method: 'POST',
                body: JSON.stringify({
                    password: password
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    setResponseData(data);
                    setShowAlert(true);
                    if (data.statusCode === 200) {
                        setTimeout(() => {
                            navigate('/', { replace: true });
                        }, 2000);
                    }
                    setTimeout(() => setShowAlert(false), 1000);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setResponseData({
                        statusCode: 500,
                        message: 'Internal Server Error. Please try again later.'
                    });
                    setShowAlert(true);
                    setTimeout(() => setShowAlert(false), 2000);
                });
        } else {
            setShowAlert(true);
            setResponseData({
                statusCode: 200,
                message: 'Invalid password'
            });
            setTimeout(() => setShowAlert(false), 2000);
        }
    }

    return (
        <div className={styles['form-signin']}>
            <form method="post" onSubmit={checkLink ? handleFormSubmit : undefined}>
                <div id="msg" className="w-100">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <h1 className="h1 mb-1 fw-normal" style={{ color: '#121212' }}>Set Password</h1>
                <div className="" style={{ color: '#8a9198' }}>
                    Password should contain Uppercase, Lowercase and Special Character with minimum length of 8 and Password and confirm Password
                    Need to be Same
                </div>
                <div>
                    <label htmlFor="password" className="h4" style={{ color: '#121212' }}>Password</label>
                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={handlePassword} disabled={!checkLink} required />
                </div>
                <div>
                    <label htmlFor="cpassword" className="h4" style={{ color: '#121212' }}>Confirm Password</label>
                    <input type="password" className="form-control" name="cpassword" placeholder="Confirm Password" onChange={handleConfirmPassword} disabled={!checkLink} required />
                </div>
                <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center w-100">
                    <Link to="/forgotpassword" className={`btn btn-outline-primary mt-1 align-self-sm-auto align-self-start ${styles['btn-o-style']}`}>Forgot Password?</Link>
                    <button className={`col-sm-4 col-12 btn btn-lg ${styles['btn-style']}`} type="submit" disabled={!checkLink}>Change Password</button>
                </div>
            </form>
        </div>
    );
}

export default SetPasswordsForm;
