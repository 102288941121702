import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery'; // Import jQuery for DOM manipulation
import 'datatables.net-bs5'; // Import DataTables for table rendering
import styles from '../../../common/common.module.css'; // Import CSS styles

const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

function UserViewerListFiles(prop) {
    // State declarations
    const [files, setFiles] = useState([]); // Array of files
    const [load, setLoad] = useState(false); // Loading state
    const [asset_name, setAsset_name] = useState(""); // Asset name
    const [aliase, setAliase] = useState(null); // Alias name
    const [showFiles, setShowFiles] = useState(false)

    // Destructure props
    const { selectedPart, setActiveTab, setVisible, visible, setFname, plantHcode, plantName } = prop;

    // Reference for DataTables table
    const tableRef = useRef(null);

    /**
     * Fetches asset name based on selected part using Autodesk Viewer API.
     * Fetches equipment details based on the part name.
     */
    const fetchAssetName = async () => {
        try {
            let part_name = '';

            // Fetch part name asynchronously from Autodesk Viewer
            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            // Fetch equipment details based on the fetched part name
            fetchEquipment(part_name);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Fetches equipment details from the server.
     * @param {string} part_name - Name of the part to fetch equipment details for.
     */
    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('userhcode'));

        try {
            // Fetch equipment details from the server
            const resp = await fetch(`${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?${newParams.toString()}`);
            const data = await resp.json();

            if (data.statusCode === 200) {
                // Set asset name and fetch associated files
                setAsset_name(data.equipment.aname);
                fetchFiles(data.equipment.hcode, data.type);
                fetchAsset(data.equipment.hcode, data.type);
            } else {
                setShowFiles(false);
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Fetches asset details from the server.
     * @param {string} hashCode - Hash code of the asset.
     * @param {number} type - Type of the asset.
     */
    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                // Fetch asset details from the server
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();

                if (data.statusCode === 200) {
                    // Set asset name and alias if available
                    setAsset_name(data.aname);
                    setAliase(data.alias_name || null);
                } else {
                    setShowFiles(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    /**
     * Fetches files associated with the asset from the server and updates state.
     * Destroys the existing DataTables instance and reinitializes it with the new data.
     * @param {string} hashCode - Hash code of the asset.
     * @param {number} type - Type of the asset.
     */
    const fetchFiles = async (hashCode, type) => {
        $(tableRef.current).DataTable().destroy(); // Destroy existing DataTable instance

        if (hashCode) {
            try {
                setLoad(true); // Set loading state
                // Fetch asset files from the server
                const response = await fetch(`${SERVER_URL}/user/assetFiles/${hashCode}/${type}?hcode=${localStorage.getItem('userhcode')}`);
                const data = await response.json();

                if (data.statusCode === 200) {
                    // Set files and showFiles state if data fetch is successful
                    setFiles(data.assetFiles);
                    setShowFiles(true);
                } else {
                    // Clear files and set loading state to false on error
                    setFiles([]);
                    setShowFiles(false);
                    setLoad(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Effect hook to fetch initial files when selectedPart changes or on component mount
    useEffect(() => {
        if (!selectedPart) {
            setAsset_name("Plant");
            setAliase(plantName);
            fetchFiles(plantHcode, 0);
        }
        fetchAssetName(); // Fetch asset name when selectedPart changes
    }, [selectedPart]);

    // Effect hook to initialize or update DataTables instance when files state changes
    useEffect(() => {
        $(tableRef.current).DataTable().destroy(); // Destroy existing DataTable instance
        $(tableRef.current).DataTable(); // Initialize DataTable instance
    }, [files]);

    /**
     * Handles downloading files from the server.
     * Downloads the file using fetch API and creates a temporary link element for download.
     * @param {string} fname - File name and ID separated by '|'.
     */
    const handleDownloadFiles = async (fname) => {
        try {
            const response = await fetch(`${SERVER_URL}/user/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('userhcode')}`);
            const file = await response.blob(); // Convert response to blob
            const url = URL.createObjectURL(file); // Create URL for blob

            const filenameParts = fname.split('|');
            const originalName = filenameParts[1];

            const link = document.createElement('a');
            link.href = url;
            link.download = originalName; // Set download filename
            link.click(); // Trigger download
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    /**
     * Handles viewing files from the server.
     * Fetches the file from the server and opens it in a new tab based on file type.
     * @param {string} fname - File name and ID separated by '|'.
     */
    const handleView = async (fname) => {
        try {
            const fileExtension = fname.split('.').pop().toLowerCase(); // Get file extension

            // Fetch file from server
            const response = await fetch(`${SERVER_URL}/user/assetfile/${fname}?hcode=${localStorage.getItem('userhcode')}`);
            const fileBlob = await response.blob(); // Convert response to blob
            const fileUrl = URL.createObjectURL(fileBlob); // Create URL for blob

            // Determine how to handle different file types
            if (fileExtension === 'pdf') {
                window.open(fileUrl, '_blank'); // Open PDF in new tab
            } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
                window.open(fileUrl, '_blank'); // Open image in new tab
            } else if (['xlsx', 'xls'].includes(fileExtension)) {
                window.open(`/user/excel/viewer/${fname}`, '_blank'); // Open Excel in new tab
            } else {
                alert("Unsupported file type for viewing."); // Alert for unsupported file types
            }
        } catch (error) {
            console.error('Error viewing file:', error);
        }
    };

    // Render component
    return (
        <>
            {files.length > 0 ?
                <div id="content" className='w-100'>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>List of Files of {asset_name}{aliase && ` (${aliase})`}</div>
                    <br />
                    <div className="ps-2">
                        <div className="w-100" style={{ maxHeight: "100%" }}>
                            {/* Table to display files */}
                            <table className={`table ${styles.table} table-sm table-bordered`} id={asset_name} ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>File Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Map through files and display each file */}
                                    {files.map((file) => (
                                        <tr key={file.fname} className={`${styles['row-hover']}`}>
                                            {/* Clickable file name to view */}
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleView(file.fname.split('|')[0])}>
                                                {file.fname.split('|')[1]} {/* Display file name */}
                                            </td>
                                            {/* Buttons to open or download file */}
                                            <td>
                                                <button
                                                    className={`${styles['btn-style']} btn me-2`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleView(file.fname.split('|')[0])}
                                                >
                                                    Open
                                                </button>
                                                {/* Icon for downloading file */}
                                                <span style={{ cursor: 'pointer' }} onClick={() => handleDownloadFiles(file.fname)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                :
                <>
                    {/* Conditional rendering for loading or no files */}
                    {load ?
                        <div id="content" className='w-100'>
                            <div className="ps-2">
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>Loading...</div>
                            </div>
                        </div>
                        :
                        <div id="content" className='w-100'>
                            <div className="ps-2">
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>Nothing to show</div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}

export default UserViewerListFiles;
