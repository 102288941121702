import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile'; // Assuming correct import path
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// EQUIPMENT_OUTLINE	11
// EQUIPMENT_PID		12
// PIPE_ISOMETRIC		21
// PIPE_PID		        22
// INSTRUMENT		    31
// VALVE			    41

// Component for uploading Equipment PID Drawings Excel file
/**
 * Handles the upload process for Equipment PID Drawings Excel file.
 * Reads the uploaded file, extracts data, and sends requests to upload each asset file.
 * Uses XLSX library for file reading and fetch API for server communication.
 */
const UploadEquipmentPIDDrawingsExcel = () => {
    const fileInputRef = useRef(null); // Ref for file input element
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const { plant_name } = useParams(); // Get URL parameters using useParams hook
    const { t } = useTranslation(); // Translation hook

    // Function to handle uploading of PID drawing Excel file
    const handlePIDDrawingFileUpload = async () => {
        const file = fileInputRef.current.files[0]; // Get the uploaded file
        const reader = new FileReader(); // Create a file reader

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result); // Convert file content to Uint8Array
            const workbook = XLSX.read(data, { type: 'array' }); // Read the workbook from the file

            // Iterate over each sheet in the workbook
            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName]; // Get current sheet
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Convert sheet to JSON

                const headerIndexes = {}; // Object to store header indexes

                // Iterate through rows in JSON data
                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    const row = jsonData[rowIndex]; // Current row data

                    if (rowIndex === 0) {
                        // Process headers (first row)
                        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                            const header = row[columnIndex]; // Current header
                            if (header === 'Tagname') {
                                headerIndexes.assetName = columnIndex; // Store index for 'Tagname'
                            } else if (header === 'File Name') {
                                headerIndexes.fileName = columnIndex; // Store index for 'File Name'
                            }
                        }
                    } else {
                        // Process data rows (excluding header)
                        const fileName = row[headerIndexes.fileName] || ''; // Get file name from row
                        const name = row[headerIndexes.assetName] || ''; // Get asset name from row

                        if (name.trim() && fileName.trim()) {
                            // If both name and file name are valid
                            const filesList = fileName.split(' '); // Split file names by space
                            for (const file of filesList) {
                                // Iterate through each file name
                                if (name.trim() && file.trim()) {
                                    let fname = file.trim().replace('/', ''); // Remove slashes from file name
                                    const resp = await fetch(`${SERVER_URL}/upload/assets/files?hcode=${localStorage.getItem('hcode')}`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            type: 1,
                                            aname: name.trim(),
                                            plant_name: plant_name,
                                            doc_code: 12,
                                            fname: fname
                                        }),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    });

                                    const data = await resp.json(); // Parse response JSON
                                    // Handle response data if needed
                                }
                            }
                        }
                    }
                }
            }
        };

        reader.readAsArrayBuffer(file); // Read file as array buffer
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setModalOpen(false); // Close modal
        await handlePIDDrawingFileUpload(); // Upload PID drawing file
    };

    // Render JSX
    return (
        <>
            {/* Button to open modal */}
            <span className='m-2 border' style={{ padding: '11px' }}>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadEquipmentPIDdrawingExcel')}</button>
                {/* Component for downloading template file */}
                <FileDownloadButton filename={'EquipmentP&IDDrawing.xlsx'} title={t('downloadUploadEquipmentPIDDrawingTemplate')}></FileDownloadButton>
            </span>

            {/* Modal for uploading file */}
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title>{t('uploadEquipmentPIDdrawingExcel')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        {/* Form for file upload */}
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            {/* File input */}
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required />
                            <br />
                            {/* Submit button */}
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default UploadEquipmentPIDDrawingsExcel;
