import React, { useState } from "react";
import Alert from '../alert/Alert';
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from './../../common/common.module.css';
import { useTranslation } from "react-i18next";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Component for adding a new user.
 * Allows input for username, password, and account name, and submits the data to the server.
 */
function AddUserForm() {
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate(); // Provides navigation functionality

    /**
     * Handles changes in the username input field.
     * @param {object} event - The event object containing the input value.
     */
    const handleUserName = (event) => {
        setUsername(event.target.value.trim())
    }

    /**
     * Handles changes in the password input field.
     * @param {object} event - The event object containing the input value.
     */
    const handlePassword = (event) => {
        setPassword(event.target.value.trim())
    }

    const { acct_name } = useParams(); // Retrieves the account name from URL params

    /**
     * Handles form submission.
     * Sends a POST request to the server to create a new user with the provided username, password, and account name.
     * Shows an alert based on server response and navigates to the user list page if successful.
     * @param {object} event - The form submit event.
     */
    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior
        fetch(`${SERVER_URL}/users/create?hcode=${localStorage.getItem('hcode')}`, {
            method: 'POST',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({
                email: username,
                password: password,
                acct_name: acct_name,
            }),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()) // Parse the response body as JSON
            .then(data => {
                setResponseData(data); // Set response data for displaying alert
                setShowAlert(true); // Show alert component
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/users/${acct_name}`); // Navigate to user list page if successful
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000); // Hide alert after 2 seconds
            })
            .catch(error => console.error(error)); // Log any errors to the console
    }

    // Render JSX
    return (
        <>
            <div className="w-100 mt-3" id="content">
                <div id="msg">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
                    <form className="w-50" onSubmit={handleFormSubmit}>
                        <h1 className="h1 mb-3 fw-normal" style={{ color: '#121212', fontSize: '600' }}>{t('createUser')}</h1>
                        <div className="form-floating">
                            <input type="text" className="form-control" name="acct_name" placeholder="name@example.com" value={acct_name} disabled readOnly />
                            <label htmlFor="acct_name">{t('accountName')}</label>
                        </div>
                        <br />
                        <div className="form-floating">
                            <input className="form-control" type="email" name="email" onChange={handleUserName} placeholder="name@example.com" required />
                            <label htmlFor="email">{t('email')}</label>
                        </div>
                        <br />
                        <div className="form-floating">
                            <input className="form-control" type="password" name="password" onChange={handlePassword} placeholder="name@example.com" required />
                            <label htmlFor="password">{t('password')}</label>
                        </div>
                        <br />
                        <button className={`w-100 btn btn-lg ${styles['btn-style']}`} type="submit">{t('createUser')}</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddUserForm;
