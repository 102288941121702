import React, { Fragment, useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/login-page/LoginPage';
import ForgotPasswordPage from './pages/forgot-pass-page/ForgotPasswordPage';
import SetPasswordPage from './pages/set-pass-page/SetPasswordPage';
import DashPage from './pages/dash-page/dashpage';
import DashPageUsers from './pages/dash-page/DashpageUsers';
import DashPageAddUsers from './pages/dash-page/DashpageAddUsers';
import DashPageAddAccounts from './pages/dash-page/DashpageAddAccounts';
import AppContext from './context/loginContext';
import DashPlants from './pages/dash-page/DashpagePlants';
import DashPageCreatePlant from './pages/dash-page/DashpageCreatePlants';
import DashZones from './pages/dash-page/DashpageZones';
import DashCreateZone from './pages/dash-page/DashpageCreateZones';
import DashAssets from './pages/dash-page/DashpageAssets';
import DashCreateAssets from './pages/dash-page/DashpageCreateAssets';
import DashSensors from './pages/dash-page/DashpageSensors';
import DashCreateSensors from './pages/dash-page/DashpageCreateSensors';
import DashPageViewer from './pages/dash-page/DashViewer';
import DashFiles from './pages/dash-page/DashpageFiles';
import DashDefineAssets from './pages/dash-page/DashDefineAssets';
import DashParts from './pages/dash-page/DashPageParts';
import DashAnalytics from './pages/dash-page/AnalyticsPage';
import UserLoginPage from './pages/login-page/UserLoginPage';
import UserForgotPasswordPage from './pages/forgot-pass-page/UserForgotPasswordPage';
import SetUserPasswordPage from './pages/set-pass-page/SetUserPasswordPage';
import HomePage from './pages/user-dash-page/HomePage';
import DashDefinePiping from './pages/dash-page/DashDefinePiping';
import DashPipes from './pages/dash-page/DashpagePipes';
import DashDefineInstruments from './pages/dash-page/DashDefineInstrument';
import DashInstrument from './pages/dash-page/DashpageInstrument';
import DashDefineValves from './pages/dash-page/DashDefineValve';
import DashActionCenter from './pages/dash-page/DashActionCenter';
import DashExcel from './pages/dash-page/DashpageExcel';
import DashUserPageExcel from './pages/user-dash-page/DashUserPageExcel';

function App() {

  const currentBuildCode = process.env.REACT_APP_BUILD_CODE;

  const login = (localStorage.getItem('hcode') ? true : false);
  const userLogin = (localStorage.getItem('userhcode') ? true : false);


  const [isLogin, setIsLogin] = useState(login);
  const [isUserLogin, setIsUserLogin] = useState(userLogin);

  const clearCacheIfBuildCodeChanged = () => {
    const previousBuildCode = localStorage.getItem('buildCode');

    if (currentBuildCode !== previousBuildCode) {
      // Clear localStorage
      localStorage.clear();

      // Clear all caches
      if ('caches' in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }

      // Save the current build code to localStorage
      localStorage.setItem('buildCode', currentBuildCode);
    }
  };

  // Run the cache clearing function on app load
  useEffect(() => {
    clearCacheIfBuildCodeChanged();
  }, []);



  return (
    <Fragment>
      <AppContext.Provider value={{ isLogin, setIsLogin, isUserLogin, setIsUserLogin }}>
        <Routes>
          <Route path="/dashboard" element={<DashPage screenCode={'SC01'} />} />
          <Route path="/" element={<DashPage screenCode={'SC01'} />} />
          <Route path="/plants" element={<DashPlants screenCode={'SC02'} />} />
          <Route path="/plants/actioncenter/:plantHCode/:plant_name/:urn" element={<DashActionCenter screenCode={'SC23'} />} />
          <Route path="/plant/create" element={<DashPageCreatePlant screenCode={'SC03'} />} />
          <Route path="/viewer/:plantHcode/:plant_name" element={<DashAnalytics screenCode={'SC04'} />} />
          <Route path="/zones/:plant_name" element={<DashZones screenCode={'SC05'} />} />
          <Route path="/zones/create/:plant_name" element={<DashCreateZone screenCode={'SC06'} />} />
          <Route path="/assets/:plant_name/:zone_name" element={<DashAssets screenCode={'SC07'} />} />
          <Route path="/pipes/:plant_name/:zone_name" element={<DashPipes screenCode={'SC08'} />} />
          <Route path="/Instruments/:plant_name/:zone_name" element={<DashInstrument screenCode={'SC09'} />} />
          <Route path="/sensors/:plant_name/:zone_name/:assetHCode" element={<DashSensors />} />
          <Route path="/parts/:plant_name/:zone_name/asset/:hcode" element={<DashParts />} />
          <Route path="/files/:plant_name/:zone_name/asset/:hcode/:type" element={<DashFiles screenCode={'SC12'} />} />
          <Route path="/sensors/create/:plant_name/:zone_name/:assetHCode" element={<DashCreateSensors />} />
          <Route path="/viewer/:plant_name/:zone_name/:assetHCode" element={<DashPageViewer screenCode={'SC12'} />} />
          <Route path="/defineassets/:plant_name/:plantHCode" element={<DashDefineAssets screenCode={'SC15'} />} />
          <Route path="/definepiping/:plant_name/:plantHCode" element={<DashDefinePiping screenCode={'SC16'} />} />
          <Route path="/defineInstruments/:plant_name/:plantHCode" element={<DashDefineInstruments screenCode={'SC17'} />} />
          <Route path="/defineValve/:plant_name/:plantHCode" element={<DashDefineValves screenCode={'SC18'} />} />
          <Route path="/accounts/create" element={<DashPageAddAccounts screenCode={'SC19'} />} />
          <Route path="/users/:acct_name" element={<DashPageUsers screenCode={'SC20'} />} />
          <Route path="/addusers/:acct_name" element={<DashPageAddUsers screenCode={'SC21'} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/resetpwd" element={<SetPasswordPage />} />
          <Route path="/userlogin" element={<UserLoginPage />} />
          <Route path="/forgotuserpassword" element={<UserForgotPasswordPage />} />
          <Route path="/resetuserpwd" element={<SetUserPasswordPage />} />
          <Route path="/home" element={<HomePage screenCode={'SC22'} />} />
          <Route path="/excel/viewer/:fname" element={<DashExcel screenCode={'SC21'} />} />
          <Route path="user/excel/viewer/:fname" element={<DashUserPageExcel screenCode={'SC21'} />} />
        </Routes>
      </AppContext.Provider>
    </Fragment>
  );
}


export default App;
