import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const UploadDefinePipeConnections = () => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { plant_name, plantHCode } = useParams();

    const handleFileUpload = async () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Perform your desired function on each row of the sheet
                const headerIndexes = {};

                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    if (rowIndex + 1 === jsonData.length) {
                        setTimeout(() => {
                            // setSave(false)
                        }, 1000)
                    }
                    const row = jsonData[rowIndex];
                    if (rowIndex === 0) {
                        // Find the indexes of the desired headers
                        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                            const header = row[columnIndex];
                            if (header === 'Pipe Tagname') {
                                headerIndexes.nameIndex = columnIndex;
                            } else if (header === 'From Equipment Tag') {
                                headerIndexes.fromIndex = columnIndex;
                            } else if (header === 'To Equipment Tag') {
                                headerIndexes.toIndex = columnIndex;
                            }
                        }
                        // Log the found indexes (optional)
                    } else {
                        // Extract values based on the found indexes
                        const pipe = row[headerIndexes.nameIndex];
                        const fromEquiment = row[headerIndexes.fromIndex];
                        const toEquipment = row[headerIndexes.toIndex];
                        console.log(pipe, fromEquiment, toEquipment)
                        try {
                            if (pipe && fromEquiment && toEquipment) {
                                let pipeName;
                                if (pipe.startsWith('/')) {
                                    pipeName = pipe.trim();
                                } else {
                                    pipeName = '/' + pipe.trim();
                                }
                                let fromEquipmentName;
                                if (fromEquiment.startsWith('/')) {
                                    fromEquipmentName = fromEquiment.trim();
                                } else {
                                    fromEquipmentName = '/' + fromEquiment.trim();
                                } let toEquipmentName;
                                if (toEquipment.startsWith('/')) {
                                    toEquipmentName = toEquipment.trim();
                                } else {
                                    toEquipmentName = '/' + toEquipment.trim();
                                }

                                console.log(pipeName, fromEquipmentName, toEquipmentName)

                                const resp = await fetch(`${SERVER_URL}/pipeConnection/define?hcode=${localStorage.getItem('hcode')}`, {
                                    method: 'POST',
                                    // Convert the form data object to a JSON string and include it in the request body
                                    body: JSON.stringify({
                                        plant_name: plant_name,
                                        plantHCode: plantHCode,
                                        pipe: pipeName,
                                        from: fromEquipmentName,
                                        to: toEquipmentName
                                    }),
                                    headers: {
                                        // Set the Content-Type header to application/json
                                        // Tells the server that the content type of the request body is JSON.
                                        'Content-Type': 'application/json'
                                    }
                                })
                            }
                        } catch (error) {
                            console.error(error);
                        }
                        // Perform your desired function on the extracted data
                    }
                }

            }
        };

        reader.readAsArrayBuffer(file);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setSave(true);
        setModalOpen(false);
        await handleFileUpload();
    };


    return (
        <>
            <span className='border m-2' style={{ padding: '11px' }}>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadPipeConnectionExcel')}</button>
                <FileDownloadButton filename={'DefinePipeConnections.xlsx'} title={t('downloadPipeConnectionTemplate')}></FileDownloadButton>
            </span>
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title >{t('uploadPipeConnectionExcel')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required />
                            <br />
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default UploadDefinePipeConnections;
