
import React from 'react';

const Overlay = ({ children }) => {
    return (
        <div className="overlay position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark opacity-75" style={{ zIndex: '999' }}>
            <span className="text-light">{children}</span>
        </div>
    );
}


export default Overlay;