import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import ListProperties from '../propertyTable/PropertyTable';
import styles from '../../../common/common.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListParts() {
    const [parts, setParts] = useState([]);
    const [showParts, setShowParts] = useState(false);
    const { hcode, zone_name, plant_name } = useParams();
    const [change, setChange] = useState('');
    const tableRef = useRef(null);
    const [asset_name, setAsset_name] = useState("");

    // 1 indicates it is either Equipment or Pipe
    const ASSET_TYPE = 1;

    useEffect(() => {
        const fetchAssetName = async () => {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hcode}/${ASSET_TYPE}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchAssetName();
    }, [hcode]);

    useEffect(() => {
        fetchParts();
    }, []);

    useEffect(() => {
        fetchParts();
    }, [change]);

    const fetchParts = async () => {
        const response = await fetch(`${SERVER_URL}/parts/${hcode}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setParts(data.parts);
        }

    };

    useEffect(() => {
        if (parts.length > 0) {
            setShowParts(true);
            $(tableRef.current).DataTable();
        }
    }, [parts]);



    return (
        <>
            {showParts ?
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>Plants</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: '#121212' }}>Area</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/assets/${plant_name}/${zone_name}`} style={{ color: '#121212' }}>Asset</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Parts</li>
                        </ol>
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>List of Parts of {asset_name} of {zone_name} of {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>Part Name</th>
                                        <th>Created On</th>
                                        <th>Created By</th>
                                        {/* <th>Properties</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {parts.map((part) => (
                                        <tr key={part.hcode} className={`${styles['row-hover']}`}>
                                            <td>{part.part_name}</td>
                                            <td>{part.created_on.split("T")[0]}</td>
                                            <td>{part.created_by}</td>
                                            {/* <td>
                                                <ListProperties entity_type={6} entity_hcode={part.hcode} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>Plants</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: '#121212' }}>Area</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/assets/${plant_name}/${zone_name}`} style={{ color: '#121212' }}>Asset</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Partss</li>
                        </ol>
                    </nav>
                    <div className="text-center w-100 h3">List of Parts of {asset_name} of {zone_name} of {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        <div className='w-100 text-center h1' style={{ color: '#121212' }}>Nothing to show</div>
                    </div>
                </div>}
        </>
    );
}


export default ListParts;
