import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SpareDetailModal = ({ showViewModal, closeViewModal, spareName }) => {

    const { t } = useTranslation();
    const sparesDetail = [
        ['N031370820', 'FRAME', 'MANHOLE FRAME'],
        ['N031369335', 'ARM', 'SUPPORT ARM'],
        ['N0487879661', 'GASKET TAPE3x20mm', 'GORE SERIES 300 PTFE 68bar GASKET TAPE(3x20mm) – 2372m'],
        ['N0313693381', 'RETAINER', 'PLATE – EN 18286 – STEEL EN 10088-2 -1.4162(10mm) – 80mm × 80mm'],
        ['N048011630', 'EYE BOLT', 'EYE BOLT DIN 444 - M20x140 - A4-70DIN 444 – A4-70'],
        ['N048046414', 'HEX HEAD SCREWM20x60mm', 'ISO 4014 - M20x60 - A4-70ISO 4014 – A4-70'],
        ['574989', '1 HEX NUT M12 ISO 4032 - M12 - A4-70', 'ISO 4032 – A4-70M12 ISO 4032'],
        ['N048046423', 'Hexagon nut ISO 4032-A4-80_M24', 'Hexagon nut ISO 4032-A4-80_M24'],
        ['N048046424', 'Washer Nordlock-A4_NL24', 'Washer Nordlock-A4_NL24'],
        ['N048046425', 'Hexagon head screw ISO 4014-A4-80_M24x60', 'Hexagon head screw ISO 4014-A4-80_M24x60'],
        ['N048046426', 'Socket cap screw ISO 4762-A4-80_M8 x 30', 'Socket cap screw ISO 4762-A4-80_M8 x 30'],
        ['N048046428', 'Anchor', 'ISO 4762-A4-80_M8, ISO 4762-A4-80_M8'],
        ['N048046488', 'Long Lug', 'ISO 4762-RT-80_M8, ISO 4762-D1-80_M8'],
        ['N048046429', 'Short Lug', 'ISO 4762-RT-80_M8, ISO 4762-D1-80_M8'],
    ]
    const [spare, setSpare] = useState([]);

    useEffect(() => {
        const foundSpare = sparesDetail.find(item => item[1] === spareName);
        if (foundSpare) {
            setSpare(foundSpare);
        }
    }, [spareName]);
    // if (!spare) return null;


    return (
        <Modal show={showViewModal} onHide={closeViewModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('spareDetail')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-6 fw-bold">{t('itemNumber')}:</div>
                        <div className="col-6">{spare[0]}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 fw-bold">{t('description')}:</div>
                        <div className="col-6 text-wrap" style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {spare[1]}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 fw-bold">{t('additionDescription')}:</div>
                        <div className="col-6">{spare[2]}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeViewModal}>{t('close')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SpareDetailModal;
