import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import AssetModal from '../../modal/AssetsModal';
import ListProperties from '../propertyTable/PropertyTable';
import styles from '../../../common/common.module.css';
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import { useTranslation } from 'react-i18next';

// The server URL from environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListPipes() {
    const { t } = useTranslation();  // Translation hook
    const [assets, setAssets] = useState([]);  // State to hold asset data
    const [showAssets, setShowAssets] = useState(false);  // State to show/hide assets
    const [selectedAsset, setSelectedAsset] = useState(null);  // State to hold selected asset
    const [showModal, setShowModal] = useState(false);  // State to show/hide modal
    const { zone_name, plant_name } = useParams();  // Extract parameters from URL
    const [change, setChange] = useState(true);  // State to track changes
    const navigate = useNavigate();  // Navigation hook
    const tableRef = useRef(null);  // Ref for the DataTable

    const ASSET_TYPE = 2;  // Constant for asset type

    // Fetch assets when the component mounts or change state changes
    useEffect(() => {
        fetchAssets();
    }, []);

    useEffect(() => {
        fetchAssets();
    }, [change]);

    // Function to fetch assets from the server
    const fetchAssets = async () => {
        const response = await fetch(`${SERVER_URL}/pipes/${plant_name}/${zone_name}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setAssets(data.assets);
        }
    };

    // Initialize DataTable when assets change
    useEffect(() => {
        if (assets.length > 0) {
            setShowAssets(true);
            $(tableRef.current).DataTable({
                destroy: true,
                language: t('dataTable', { returnObjects: true })  // Set language for DataTable
            });
        }
    }, [assets]);

    // Handle asset name click to show modal
    const handleAssetNameClick = (asset) => {
        setSelectedAsset(asset);
        setShowModal(true);
    };

    // Function to close modal
    const closeModal = () => {
        setShowModal(false);
    };

    // Handle view sensors button click
    const handleViewSensors = (asset_hcode) => {
        navigate(`/sensors/${plant_name}/${zone_name}/${asset_hcode}`);
    };

    // Handle view files button click
    const handleViewFiles = (hcode) => {
        navigate(`/files/${plant_name}/${zone_name}/asset/${hcode}/${ASSET_TYPE}`);
    };

    // Handle view parts button click
    const handleViewParts = (hcode) => {
        navigate(`/parts/${plant_name}/${zone_name}/asset/${hcode}`);
    };

    // Handle view analytics button click
    const handleViewAnalytics = (urn, hcode) => {
        window.open(`/viewer/${plant_name}/${zone_name}/${hcode}?urn=${urn}&hcode=${hcode}`, '_blank');
    };

    return (
        <>
            {showAssets ? (
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/plants" style={{ color: 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                            <li class="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: 'rgb(18, 18, 18)' }}>{t('area')}</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">{t('equipment')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfPipeOf')} {zone_name} {t('of')} {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        {/* <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => {
                                    // window.location.href = `/assets/create/${plant_name}/${zone_name}`
                                    navigate(`/assets/create/${plant_name}/${zone_name}`);
                                }}
                            >
                                Add Assets
                            </button>
                        </div> */}
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('pipeTag')}</th>
                                        <th>{t('pipeDescripiton')}</th>
                                        {/* <th>Created On</th>
                                        <th>Created By</th> */}
                                        {/* <th>View Parts</th> */}
                                        <th>{t('viewFiles')}</th>
                                        {/* <th>View Sensors</th> */}
                                        {/* <th>View analytics</th> */}
                                        {/* <th>Properties</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {assets.map((asset) => (
                                        <tr key={asset.hcode} className={`${styles['row-hover']}`}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleAssetNameClick(asset)}>{asset.aname}</td>
                                            <td>{asset.alias_name}</td>
                                            {/* <td>{asset.created_on}</td>
                                            <td>{asset.created_by}</td> */}
                                            {/* <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewParts(asset.hcode)}
                                                >
                                                    View Parts
                                                </button>
                                            </td> */}
                                            <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewFiles(asset.hcode)}
                                                >
                                                    {t('viewFiles')}
                                                </button>
                                            </td>
                                            {/* <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewSensors(asset.hcode)}
                                                >
                                                    View Sensors
                                                </button>
                                            </td> */}
                                            {/* <td>{asset.modelID ?
                                                <>
                                                    <button
                                                        className={`${styles['btn-style']}   btn`}
                                                        style={{ lineHeight: "1" }}
                                                        onClick={() => handleViewAnalytics(asset.modelID, asset.hcode)}
                                                    >
                                                        View Analytics
                                                </button>
                                                </> : <Link to='/plants' >upload model</Link>}
                                            </td> */}
                                            {/* <td>
                                                <ListProperties entity_type={4} entity_hcode={asset.hcode} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/plants" style={{ color: 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                            <li class="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: 'rgb(18, 18, 18)' }}>{t('area')}</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">{t('equipment')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfPipeOf')} {zone_name} {t('of')} {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        {/* <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => navigate(`/assets/create/${plant_name}/${zone_name}`)}
                            >
                                Add Assets
                            </button>
                        </div> */}
                        <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>
                    </div>
                </div>
            )}
            {selectedAsset && (
                <AssetModal
                    asset={selectedAsset}
                    showModal={showModal}
                    closeModal={closeModal}
                    plant_name={plant_name}
                    zname={zone_name}
                    setChange={setChange}
                />
            )}
        </>
    );
}

export default ListPipes;
