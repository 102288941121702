import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import ListPlants from '../../components/tables/plantstable/PlantTable';

function DashPlants({ screenCode }) {
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <ListPlants></ListPlants>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashPlants;