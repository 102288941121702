import React, { useState, useContext } from "react";
import styles from './Form.module.css';
import Alert from '../alert/Alert';
import { Link } from "react-router-dom";
import AppContext from "../../context/loginContext";
import { useTranslation } from "react-i18next";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Form component for the login page.
 *
 * @component
 */
function Form() {
    const { t } = useTranslation();
    const { isLogin, setIsLogin } = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    /**
     * Handle the change event for the username input.
     * @param {object} event - The input change event.
     */
    const handleUserName = (event) => {
        setUsername(event.target.value);
    };

    /**
     * Handle the change event for the password input.
     * @param {object} event - The input change event.
     */
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    /**
     * Handle the form submission.
     * @param {object} event - The form submit event.
     */
    const handleFormSubmit = (event) => {
        event.preventDefault();

        fetch(`${SERVER_URL}/authenticateAdminUser`, {
            method: 'POST',
            body: JSON.stringify({
                email: username,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    localStorage.setItem('hcode', data.hcode);
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => setIsLogin(true), 600);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    };

    return (
        <div className={styles['form-signin']}>
            <form method="post" onSubmit={handleFormSubmit}>
                <div id="msg" className="w-100">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <h1 className="h1 mb-3 fw-normal text-center" style={{ color: '#121212' }}>{t('login')}</h1>
                <div className="mb-2">
                    <label htmlFor="email" className="h4" style={{ color: '#121212' }}>{t('emailAddress')}</label>
                    <input type="email" className="form-control" name="email" placeholder="name@example.com" onChange={handleUserName} autoComplete="off" required />
                </div>
                <div className="mb-2">
                    <label htmlFor="password" className="h4" style={{ color: '#121212' }}>{t('password')}</label>
                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={handlePassword} autoComplete="off" required />
                </div>
                <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center w-100">
                    <Link to="/forgotpassword" className={`btn mt-1 align-self-sm-auto align-self-start ${styles['btn-o-style']}`}>{t('forgotPassword')}?</Link>
                    <button className={`col-sm-4 col-12 btn btn-lg ${styles['btn-style']}`} type="submit">{t('signin')}</button>
                </div>
            </form>
        </div>
    );
}

export default Form;
