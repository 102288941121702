import { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function OverlayWithTabs(prop) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [content, setContent] = useState('');
    const { height, screenCode } = prop

    const ref = useRef(null);

    const popoverHeaderStyle = {
        backgroundColor: '#8ED1B2', // Soft teal color
        color: '#333',
    };



    useEffect(() => {
        const callingFunction = async () => {
            const resp = await fetch(`${SERVER_URL}/helpfile/${screenCode ? screenCode : 'SC01'}/${window.navigator.language}`, {
                method: 'GET',
            })

            const data = await resp.text();
            setContent(data);
        }
        callingFunction();
    }, [])

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <>
            <a onClick={handleClick} ><img src={process.env.PUBLIC_URL + '/images/help.jpg'} height={height ? height : "40"} alt="" /></a>
            <div ref={ref} style={{ borderColor: 'red' }}>
                <Overlay
                    show={show}
                    target={target}
                    placement="top"
                    container={ref}
                    containerPadding={20}
                    style={{ borderColor: 'red' }}
                >
                    <Popover id="popover-contained" style={{ maxWidth: 'none' }}>
                        <Popover.Header as="h3" style={popoverHeaderStyle}>Help</Popover.Header>
                        <Popover.Body>

                            <div dangerouslySetInnerHTML={{ __html: content }} />

                            {/* {content} */}
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div>
        </>
    );
}

export default OverlayWithTabs;