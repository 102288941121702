import React, { useState, useContext } from "react";
import styles from './Form.module.css';
import Alert from '../alert/Alert';
import { Link } from "react-router-dom";
import AppContext from "../../context/loginContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * UserForm component for user login.
 *
 * @component
 */
function UserForm() {
  const { isUserLogin, setIsUserLogin } = useContext(AppContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [responseData, setResponseData] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  /**
   * Handle the change event for the username input.
   * @param {object} event - The input change event.
   */
  const handleUserName = (event) => {
    setUsername(event.target.value);
  };

  /**
   * Handle the change event for the password input.
   * @param {object} event - The input change event.
   */
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  /**
   * Handle the form submission.
   * @param {object} event - The form submit event.
   */
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${SERVER_URL}/authenticateUser`, {
        method: 'POST',
        body: JSON.stringify({
          email: username,
          password: password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (data.statusCode === 200) {
        localStorage.setItem('userhcode', data.hcode);
        setTimeout(() => setIsUserLogin(true), 600);
      }
      setResponseData(data);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000);
    } catch (error) {
      console.error('Error:', error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <div className={styles['form-signin']}>
      <form method="post" onSubmit={handleFormSubmit}>
        <div id="msg" className="w-100">
          {showAlert && <Alert data={responseData}></Alert>}
        </div>
        <h1 className="h1 mb-3 fw-normal text-center" style={{ color: '#121212' }}>User Login</h1>
        <div className="mb-2">
          <label htmlFor="email" className="h4" style={{ color: '#8a9198' }}>Email Address</label>
          <input type="email" className="form-control" name="email" placeholder="name@example.com" onChange={handleUserName} autoComplete="off" required />
        </div>
        <div className="mb-2">
          <label htmlFor="password" className="h4" style={{ color: '#8a9198' }}>Password</label>
          <input type="password" className="form-control" placeholder="Password" name="password" onChange={handlePassword} autoComplete="off" required />
        </div>
        <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center w-100">
          <Link to="/forgotuserpassword" className={`btn mt-1 align-self-sm-auto align-self-start ${styles['btn-o-style']}`}>Forgot Password?</Link>
          <button className={`col-sm-4 col-12 btn btn-lg ${styles['btn-style']}`} type="submit">Sign in</button>
        </div>
      </form>
    </div>
  );
}

export default UserForm;
