import { Link } from 'react-router-dom';
import styles from './sidebar.module.css';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/loginContext';
import { useTranslation } from 'react-i18next';

function Sidebars({ children }) {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleLogOut = () => {
        localStorage.removeItem("hcode");
        setIsLogin(false);
        navigate('/login');
    };

    useEffect(() => {
        console.log('Sidebar re-rendered due to language change');
    }, [i18n.language]);

    return (
        <div className={styles["fixdash"]}>
            <div className={`${styles['flexcontent']} border-danger`}>
                <nav className={`navbar navbar-expand-lg navbar-light ${styles['height']}`}>
                    <div className={`container-fluid flexclass ${styles['alignclass']}`}>
                        <button className={`navbar-toggler ${styles['backcolor']} mb-1`} type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`navbar-collapse collapse ${styles['flexclass']}`} id="navbarSupportedContent">
                            <ul className={`navbar-nav me-auto mb-2 mb-lg-0 list-unstyled ps-0 ${styles['flexclass']}`}>
                                <li className="mb-1">
                                    <button className={`btn btn-toggle lg align-items-center  collapsed ${styles['btn-toggle']}`}
                                        data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
                                        {t('home')}
                                    </button>
                                    <div className="collapse show" id="home-collapse">
                                        <ul className={`btn-toggle-nav list-unstyled fw-normal pb-1 small ms-2 ${styles['btn-toggle-nav']}`}>
                                            <li><Link to="/" className={`${styles.textcolor} `}>{t('accounts')}</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <button className={`btn btn-toggle align-items-center  ${styles['btn-toggle']}`} data-bs-toggle="collapse"
                                        data-bs-target="#dashboard-collapse" aria-expanded="true">
                                        Dashboard
                                    </button>
                                    <div className="collapse show" id="dashboard-collapse">
                                        <ul className={`btn-toggle-nav list-unstyled fw-normal pb-1 small ms-2 ${styles['btn-toggle-nav']}`}>
                                            <li><Link to="/plants" className={`${styles.textcolor} `}>{t('plants')}</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="border-top my-3"></li>
                                <li className="mb-1">
                                    <button className={`btn btn-toggle lg align-items-center  collapsed ${styles['btn-toggle']}`} data-bs-toggle="collapse"
                                        data-bs-target="#account-collapse" aria-expanded="true">
                                        {t('accounts')}
                                    </button>
                                    <div className="collapse show" id="account-collapse">
                                        <ul className={`btn-toggle-nav list-unstyled fw-normal pb-1 small ms-2 ${styles['btn-toggle-nav']}`}>
                                            {/* <li><Link to="/accounts/create" className={`${styles.textcolor} `}>New...</Link></li> */}
                                            {/* <li><a href="#" className={`${styles.textcolor} `}>Profile</a></li> */}
                                            {/* <li><a href="#" className={`${styles.textcolor} `}>Settings</a></li> */}
                                            <li><a id="logout-link" href='#' onClick={() => { handleLogOut() }} className={`${styles.textcolor} `}>{t('signOut')}</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className={`w-100 ${styles['height']}`}>
                {children}
            </div>
        </div>
    );
}

export default Sidebars;
