import React, { useState } from "react";
import styles from './LoginPage.module.css';
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserForm from "../../components/loginpage/UserForm";
import UserHeader from "../../components/header/UserHeader";
// import styled from c



function UserLoginPage() {

    const { isUserLogin, setIsUserLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserLogin === true) {
            navigate('/home');
        }
    }, [isUserLogin, navigate]);

    return (
        <Wrapper>
            <UserHeader />
            <div className={styles['div-style']}>
                <UserForm />
            </div>
            <Footer />
        </Wrapper>
    );
}

export default UserLoginPage;