import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import ListAccounts from '../../components/tables/accounttables/AccountTable';
import AppContext from '../../context/loginContext';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';




function DashPage({ screenCode }) {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
    }, []);
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <ListAccounts></ListAccounts>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashPage;