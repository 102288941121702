import React, { useState } from "react";
import forgotStyle from './Forgot.module.css';
import UserHeader from '../../components/header/UserHeader'
import Footer from "../../components/footer/Footer";
import UserForgotForm from "../../components/forgotpasspage/UserForgotForm";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';




function USerForgotPasswordPage() {
    const { isUserLogin, setIsUserLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('userhcode') !== null || isUserLogin === true) {
            navigate('/home');
        }
    }, [isUserLogin, navigate]);


    return (
        <Wrapper>
            <UserHeader />
            <div className={forgotStyle['div-style']}>
                {/* <div className="justify-content-start"><img className="mb-4 mt-2" src={process.env.PUBLIC_URL + '/images/logo.png'} alt="some text" style={{ maxWidth: '80%' }}
                    height="57" />
                </div> */}
                <UserForgotForm />
            </div>
            <Footer />
        </Wrapper>
    );
}

export default USerForgotPasswordPage;