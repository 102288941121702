import React, { useEffect, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import PropertyModal from '../../modal/PropertyModal';
import styles from './PropertyTable.module.css'



const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Shows properties as key value pair in form of table.
 * @returns
 */
function ListProperties({ entity_type, entity_hcode }) {
    const [properties, setProperties] = useState([]);
    const [show, setShow] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [showPropertyModal, setShowPropertyModal] = useState(false);
    const [change, setChange] = useState('');


    useEffect(() => {
        fetchProperty(entity_type, entity_hcode);
    }, [deleted, change]);

    /**
     * 
     * @param {*} entity_type 
     * @param {*} entity_hcode 
     * Fetch the list of properties
     */
    const fetchProperty = async (entity_type, entity_hcode) => {
        const response = await fetch(
            `${SERVER_URL}/properties/${entity_hcode}/${entity_type}?hcode=${localStorage.getItem(
                'hcode'
            )}`
        );
        const data = await response.json();
        if (data.statusCode === 200 && data.properties.length > 0) {
            setShow(true);
            setProperties(data.properties);
        } else {
            setShow(false);
        }
    };

    /**
     * 
     * @param {*} hcode 
     * Delete Selected Property 
     */
    const handleDelete = (hcode) => {
        fetch(`${SERVER_URL}/property/delete/${hcode}?hcode=${localStorage.getItem(
            'hcode')}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.ok) {
                    // Request was successful
                    setDeleted((prev) => !prev)

                } else {
                    // Handle error response
                    console.error('Error:', response.status);

                }
            })
            .catch(error => {
                // Handle network or other errors
                console.error('Error:', error);
            });
    }

    const handleShowPropertyModal = (hcode) => {
        setShowPropertyModal(true);
    };

    const closePropertyModal = () => {
        setShowPropertyModal(false);
    };




    return (
        <>
            <button
                className={`${styles['btn-style']}   btn`}
                style={{ lineHeight: '1' }}
                onClick={() => handleShowPropertyModal(entity_hcode)}
            >
                Add Property
            </button>
            {show ? (
                <table>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {properties.map((property) => (
                            <tr key={property.hcode}>
                                <td className={`${styles['key-column']}`}>{property.pkey}</td>
                                <td className={`${styles['value-column']}`}>{property.pvalue}</td>
                                <td onClick={() => handleDelete(property.hcode)}><button className='border-0 bg-transparent'><Trash /></button> </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <></>
            )}{showPropertyModal && (
                <PropertyModal showModal={showPropertyModal} closeModal={closePropertyModal} entity_type={entity_type} entity_hcode={entity_hcode} setChange={setChange} />
            )}
        </>
    );
}

export default ListProperties;
