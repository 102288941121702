import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import CreateAsset from '../../components/dashpage/CreateAssets';
import AppContext from "../../context/loginContext";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';



function DashCreateAssets() {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    if (localStorage.getItem('hcode') === null || isLogin === false) {
        localStorage.removeItem("hcode");
        navigate('/login');
    }
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <CreateAsset></CreateAsset>
            </Sidebars>
            <Footer  />
        </Wrapper>
    )
}

export default DashCreateAssets;