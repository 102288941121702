import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import CreateSensor from '../../components/dashpage/CreateSensors';

function DashCreateSensors() {
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <CreateSensor></CreateSensor>
            </Sidebars>
            <Footer />
        </Wrapper>
    )
}

export default DashCreateSensors;