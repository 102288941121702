import React, { useEffect, useState } from 'react';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;


/**
 * The FlashMessage component fetches flash messages for the user from a server and displays them in a
 * styled overlay on the webpage.
 * @returns The FlashMessage component is being returned. It displays a flash message fetched from the
 * server and rendered as a styled overlay on the screen. The message is fetched using an API call to
 * the server and displayed in a div element with specific styling such as font family, background
 * color, and text color.
 */
const FlashMessage = () => {
    const [flashMessage, setFlashMessage] = useState('');


    useEffect(() => {
        fetchMessage();
    }, []);

    const fetchMessage = async () => {
        const response = await fetch(`${SERVER_URL}/user/flashMessages?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            const temp = data.msg
                .filter(msg => msg.isEnabled)
                .map(msg => msg.fmsg)
                .join(' ');
            setFlashMessage(temp);
        }
    };


    return (
        <>
            {flashMessage &&
                <div className="d-flex overlay position-absolute w-100 p-2" style={{ fontFamily:"serif", backgroundColor: "#f1f1f1", overflowX: "hidden", overflowY: "scroll", height: "7vh" }}>
                    <h2 className="" style={{ color: "#333333" }}>{flashMessage}</h2>
                </div>
            }
        </>
    );
};

export default FlashMessage;
