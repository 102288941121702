import React from 'react';
import styles from '../../common/common.module.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const PDFViewer = ({ fname }) => {

    const handleView = async (fname) => {
        const link = document.createElement('a');
        link.href = `${SERVER_URL}/uploads/${fname.split('|')[0]}`;
        link.download = `${fname.split('|')[1]}`;
        link.target = '_blank'; // Optional: Open the download in a new tab/window
        link.setAttribute('rel', 'noopener noreferrer');
        link.click();
    }

    return (
        <>
            <button className={`${styles['btn-style']}   btn mb-1`}
                onClick={() => handleView(fname)}>
                    Open on New Window
            </button>
            <iframe src={`${SERVER_URL}/uploads/${fname.split('|')[0]}`} style = {{ minHeight:'50vh' }} width='100%'></iframe>
        </>
    )
}
