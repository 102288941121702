import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const UploadDefineValve = ({ setSave }) => {
    const fileInputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { plant_name, plantHCode } = useParams();
    const [textToReplace, setTextToReplace] = useState('');
    const [replaceWith, setReplaceWith] = useState('');
    const { t } = useTranslation();



    const handleFileUpload = async () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Perform your desired function on each row of the sheet
                const headerIndexes = {};

                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    if (rowIndex + 1 === jsonData.length) {
                        setTimeout(() => {
                            setSave(false)
                        }, 1000)
                    }
                    const row = jsonData[rowIndex];
                    if (rowIndex === 0) {
                        // Find the indexes of the desired headers
                        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                            const header = row[columnIndex];
                            if (header === 'Valve Tagname') {
                                headerIndexes.nameIndex = columnIndex;
                            } else if (header === 'Description') {
                                headerIndexes.descriptionIndex = columnIndex;
                            } else if (header === 'Equipment Class') {
                                headerIndexes.equipmentClassIndex = columnIndex;
                            } else if (header === 'Plant Code') {
                                headerIndexes.plantUnitIndex = columnIndex;
                            } else if (header === 'EQ/Pipe Ref') {
                                headerIndexes.assetIndex = columnIndex;
                            }
                        }

                        // Log the found indexes (optional)
                    } else {
                        // Extract values based on the found indexes
                        const name = row[headerIndexes.nameIndex];
                        const description = row[headerIndexes.descriptionIndex];
                        const plantUnit = row[headerIndexes.plantUnitIndex];
                        const assetName = row[headerIndexes.assetIndex];
                        try {
                            if (name && plantUnit) {
                                let valveName;
                                if (name.startsWith('/')) {
                                    valveName = name.trim();
                                } else {
                                    valveName = '/' + name.trim();
                                }
                                const dbIds = await new Promise((resolve, reject) => {
                                    console.log(valveName);
                                    window.NOP_VIEWER.model.search(valveName, (dbIds) => {
                                        resolve(dbIds);
                                    }, reject);
                                });
                                const data = await new Promise((resolve, reject) => {
                                    window.NOP_VIEWER.model.getBulkProperties(dbIds, { propFilter: ['dbId', 'name'] }, (data) => {
                                        resolve(data);
                                    }, reject);
                                });

                                for (let i = 0; i < data.length; i++) {
                                    if (data[i].name === valveName) {
                                        window.NOP_VIEWER.fitToView([data[i].dbId]);
                                        window.NOP_VIEWER.isolate([data[i].dbId]);
                                        const resp = await fetch(`${SERVER_URL}/valve/define?hcode=${localStorage.getItem('hcode')}`, {

                                            method: 'POST',
                                            // Convert the form data object to a JSON string and include it in the request body
                                            body: JSON.stringify({
                                                valve: data[i],
                                                description: description,
                                                asset: assetName ? assetName.startsWith('/') ? assetName : '/' + assetName : '',
                                                zoneID: plantUnit,
                                                plant_name: plant_name,
                                                plantHCode: plantHCode
                                            }),
                                            headers: {
                                                // Set the Content-Type header to application/json
                                                // Tells the server that the content type of the request body is JSON.
                                                'Content-Type': 'application/json'
                                            }
                                        })
                                    }
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

            }
        };

        reader.readAsArrayBuffer(file);

    };




    // const handleUnitChange = (e) => {
    //     setPlantUnit(e.target.value);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setSave(true);
        setModalOpen(false);
        await handleFileUpload();
    };

    return (
        <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
            <h3 className=''>{plant_name}</h3>
            <div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadExcel')}</button>
                <FileDownloadButton filename={'DefineValve.xlsx'} title={'Download Valve Template'}></FileDownloadButton>
            </div>
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title >{t('uploadExcelFile')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required />
                            <br />
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </div>
    );
};

export default UploadDefineValve;
