import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const MaintenanceModal = ({ hcode, showModal, closeModal, handleAddedSpare, setActiveTab }) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const [units, setUnits] = useState([]);
    const [comment, setComment] = useState('');
    const [formData, setFormData] = useState({
        spare_name: '',
        quantity: '',
        quantity_unit: '',
        spare_replacement_date: ''
    });
    const [formErrors, setFormErrors] = useState({
        spare_name: '',
        quantity: '',
        quantity_unit: '',
        spare_replacement_date: ''
    });

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const resp = await fetch(`${SERVER_URL}/spareUnits?hcode=${localStorage.getItem('hcode')}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setUnits(data.units);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchUnits();
    }, []);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        if (name === 'comment') {
            setComment(value);
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const validateForm = () => {
        let valid = true;
        const errors = {};

        if (!formData.spare_name.trim()) {
            errors.spare_name = 'Spare Name is required';
            valid = false;
        }

        if (!formData.quantity.trim()) {
            errors.quantity = 'Quantity is required';
            valid = false;
        }

        if (!formData.quantity_unit) {
            errors.quantity_unit = 'Unit is required';
            valid = false;
        }

        if (!formData.spare_replacement_date) {
            errors.spare_replacement_date = 'Replacement date is required';
            valid = false;
        }

        setFormErrors(errors);
        return valid;
    };

    const handleFormSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        try {

            const dataToSend = {
                ...formData,
                comments: comment
            };
            const resp = await fetch(`${SERVER_URL}/spares/create/${hcode}?hcode=${localStorage.getItem('hcode')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });

            if (resp.ok) {
                // Data successfully stored in the database
                setActiveTab('docs');
                
                handleAddedSpare();
                closeModal();
            } else {
                // Handle any errors
                console.error('Error storing data in the database');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Add Spare</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="spare_name">
                        <Form.Label>Spare Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="spare_name"
                            required
                            autoComplete='off'
                            onChange={handleFormChange}
                            isInvalid={!!formErrors.spare_name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.spare_name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="quantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            required
                            onChange={handleFormChange}
                            isInvalid={!!formErrors.quantity}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.quantity}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="quantity_unit">
                        <Form.Label>Unit</Form.Label>
                        <Form.Select
                            name="quantity_unit"
                            required
                            onChange={handleFormChange}
                            isInvalid={!!formErrors.quantity_unit}
                        >
                            <option value="">
                                Select a Unit
                            </option>
                            {units.map((unit) => (
                                <option key={unit.unit_name} value={unit.unit_name}>
                                    {unit.unit_name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {formErrors.quantity_unit}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="spare_replacement_date">
                        <Form.Label>Replacement date</Form.Label>
                        <Form.Control
                            type="date"
                            name="spare_replacement_date"
                            max={currentDate}
                            required
                            onChange={handleFormChange}
                            isInvalid={!!formErrors.spare_replacement_date}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.spare_replacement_date}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <Form.Group controlId="comment">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="comment"
                        onChange={handleFormChange}
                        value={comment}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleFormSubmit}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MaintenanceModal;
