import { useEffect } from 'react';
import { initViewer, loadModel } from './UserHelpViewer';
import './viewer.css';


/**
 * Initialize the container for the model to view in web browser
 */
const UserViewer = (props) => {

    const { width, height, setSelectedPart, dashboard } = props;

    const urn = dashboard.modelID;

    useEffect(() => {
        initViewer(document.getElementById('viewer')).then((viewer) => {
            viewer.addEventListener(window.Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => {
                setSelectedPart(window.NOP_VIEWER.getSelection()[0]);
            })
            loadModel(viewer, urn);
        })
    }, [urn])
    return <div id="viewer" style={{ position: 'relative', width: width, height: height, boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset' }} />;
};


export default UserViewer;
