import React, { Fragment } from "react";
import OverlayWithTabs from "../overlays/overlaysWithTabs";

/**
 * Footer component displaying the footer content.
 * Optionally includes an overlay with tabs based on the screenCode prop.
 *
 * @param {object} props - The component props.
 * @param {string} props.screenCode - The screen code to determine whether to show the overlay with tabs.
 */
function Footer({ screenCode }) {
    return (
        <Fragment>
            {/* Conditionally render the OverlayWithTabs component if screenCode is provided */}
            {/* {screenCode && <div className="d-flex justify-content-center"><OverlayWithTabs screenCode={screenCode} height={'40'} /></div>} */}

            {/* Footer content */}
            <footer className="text-center text-white" style={{ backgroundColor: "#090909" }}>
                <div className="text-center p-3">
                    <ul className="list-group">
                        <li className="" style={{ backgroundColor: "#090909", listStyleType: "none", color: '#ffffff' }}>
                            <span className="copy">Copyright © 2024 Metso</span>
                        </li>
                    </ul>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;
