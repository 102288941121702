import React from 'react';

// FileDownloadButton component allows users to download a file when the button is clicked.
/**
     * Handles the file download process.
     * This function creates a temporary link element, sets its href and download attributes,
     * programmatically clicks the link to trigger the download, and then removes the link element.
     */
const FileDownloadButton = ({ filename, title }) => {
    const downloadFile = () => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        // Set the href and download attributes for the link
        link.setAttribute('href', `${process.env.PUBLIC_URL}/templates/${filename}`);
        link.setAttribute('download', filename);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the temporary link element from the document
        document.body.removeChild(link);
    };

    return (
        // Render a button with an onClick event to trigger the downloadFile function
        <button onClick={downloadFile} title={title}>
            {/* SVG icon for the download button */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
        </button>
    );
};

export default FileDownloadButton;
