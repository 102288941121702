/// import * as Autodesk from "@types/forge-viewer";


import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from '../../context/loginContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Viewer from '../../components/viewer/Viewer';
import { useRef } from 'react';
import Overlay from '../../components/overlayScreen/overlayPage';
import AliasModal from '../../components/modal/AssetAliasModal';
import Sidebars from '../../components/sidebar/sidebar';
import styles from '../../common/common.module.css';
import styleInput from './analyticPageStyle/analytic.module.css'
import UploadDefineAsset from '../../components/buttons/UploadExcelAssets';
import FileDownloadButton from '../../components/buttons/downloadfile';
import { useTranslation } from 'react-i18next';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;



function DashDefineAssets({ screenCode }) {

    const { t } = useTranslation();

    const inputRef = useRef(null);

    const [taglist, setTagList] = useState([]);
    const [assetlist, setAssetList] = useState([]);
    const [save, setSave] = useState(false);

    const [tagName, setTagName] = useState(null);
    const [change, setChange] = useState(true);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [selectedTag, setSelectedTag] = useState('');
    const [highlightTag, sethighlightTag] = useState('');
    const [highlightAsset, sethighlightAsset] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [aliasAsset, setAliasAsset] = useState(null);
    const [modelReady, setModelReady] = useState(false);
    const [modelStatus, setModelStatus] = useState('');



    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const urn = params.get('urn');

    const { plant_name, zone_name } = useParams();
    const [small, setSmall] = useState(false);



    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, [window.innerWidth])


    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
    }, []);



    const handleTagName = (e) => {
        setTagName(e.target.value.trim());
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(true);
        setShow(false);
        const res = await fetch(`${SERVER_URL}/equipmentNames?hcode=${localStorage.getItem('hcode')}`, {
            method: 'POST',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({
                tagName: tagName,
                urn: urn
            }),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })

        const data = await res.json();
        if (data.statusCode === 200) {
            if (data.tagData.length > 0) {
                setChange(prev => !prev);
                setTagList(data.tagData);
                setShow(true);
                setLoad(false);

            } else {
                setShow(false);
                setLoad(false);
            }
        }
    }

    const handleTagClick = (tag) => {
        setSelectedAsset(null);
        sethighlightAsset('');
        setSelectedTag(tag);
        sethighlightTag(tag.dbid);
        window.NOP_VIEWER.isolate([tag.dbid]);
        window.NOP_VIEWER.fitToView([tag.dbid]);
    }

    const handleAssetClick = (asset) => {
        setSelectedTag(null);
        sethighlightTag('');
        setSelectedAsset(asset);
        sethighlightAsset(asset.dbid);
        window.NOP_VIEWER.isolate([asset.dbid]);
        window.NOP_VIEWER.fitToView([asset.dbid]);
    }

    const handleMoveTagClick = (tag) => {
        if (tag && !(assetlist.includes(tag))) {
            tag.alias_name = '';
            tag.adesc = '';
            setAssetList((prev) => [...prev, tag]);
        }
    }

    const handleMoveAssetClick = (asset) => {
        if (asset) {
            const fArray = assetlist.filter((obj) => obj.dbid !== asset.dbid);
            setAssetList(fArray);
        }
    }

    const handleAddButton = (asset) => {
        setAliasAsset(asset);
        setShowModal(true);
    }

    const handleClear = () => {
        inputRef.current.value = '';
        setTagName('');
        setTagList([]);
        setAssetList([]);
        setShow(false);
    }


    // const handleSave = async () => {

    //     if (assetlist.length > 0) {

    //         setSave(true);

    //         const asset_list = await Promise.all(assetlist.map(async ({ dbid, name, alias_name, adesc }) => {
    //             const asset_with_part = {};
    //             asset_with_part['adbid'] = dbid;
    //             asset_with_part['aname'] = name;
    //             asset_with_part['alias_name'] = alias_name;
    //             asset_with_part['adesc'] = adesc;
    //             window.NOP_VIEWER.fitToView([dbid]);
    //             window.NOP_VIEWER.isolate([dbid]);
    //             asset_with_part['parts'] = [];

    //             let tree = window.NOP_VIEWER.model.getInstanceTree();
    //             const getPropertiesPromises = [];

    //             await new Promise((resolve) => {
    //                 tree.enumNodeChildren(dbid, function (id) {
    //                     if (window.NOP_VIEWER.isNodeVisible(id) && id !== dbid) {
    //                         const getPropertiesPromise = new Promise((resolveProperties) => {
    //                             window.NOP_VIEWER.getProperties(id, (data) => {
    //                                 asset_with_part.parts.push({ pname: data.name, pdbid: data.dbId });
    //                                 resolveProperties();
    //                             });
    //                         });

    //                         getPropertiesPromises.push(getPropertiesPromise);
    //                     }
    //                 }, true);

    //                 Promise.all(getPropertiesPromises).then(() => {
    //                     resolve(); // Resolve the outer promise after all getProperties calls have completed
    //                 });
    //             });

    //             return asset_with_part;
    //         }));


    //         // setSave(false);

    //         const resp = await fetch(`${SERVER_URL}/assets/define?hcode=${localStorage.getItem('hcode')}`, {

    //             method: 'POST',
    //             // Convert the form data object to a JSON string and include it in the request body
    //             body: JSON.stringify({
    //                 assetsList: asset_list,
    //                 zname: zone_name,
    //                 plant_name: plant_name
    //             }),
    //             headers: {
    //                 // Set the Content-Type header to application/json
    //                 // Tells the server that the content type of the request body is JSON.
    //                 'Content-Type': 'application/json'
    //             }
    //         })

    //         const data = await resp.json();

    //         if (data.statusCode === 200) {
    //             setSave(false);
    //             alert(data.message);
    //             inputRef.current.value = '';
    //             setTagName('');
    //             setTagList([]);
    //             setAssetList([]);
    //             setShow(false);
    //         } else if (data.statusCode === 409) {
    //             setSave(false);
    //             alert(data.message);
    //         } else {
    //             setSave(false);
    //             alert(data.message);
    //         }
    //     }

    // }

    const modelDetail = async () => {
        const resp = await fetch(`${SERVER_URL}/manifest/${urn}?hcode=${localStorage.getItem('hcode')}`)
        const data = await resp.json();
        setModelStatus(data.resp.body.progress);
        if (data.statusCode === 200) {
            if (data.resp.body.progress === 'complete') {
                setModelReady(true);
            } else {
                setTimeout(() => {
                    modelDetail();
                }, 10)
            }
        }
    }

    useEffect(() => {
        modelDetail();
    }, [])

    const closeModal = () => {
        setShowModal(false);
        setAssetList(assetlist);
    }

    return (
        <Wrapper>
            <Header />
            {save && <Overlay>Saving...</Overlay>}
            <Sidebars>
                {modelReady ? (<>
                    <div className='d-flex flex-row flex-wrap w-100 mt-2' style={{ minHeight: '80vh' }}>
                        <Viewer width={small ? '100%' : '48%'} height={'80vh'} ></Viewer>
                        <div className='w-50 ms-2 pt-1 mt-2 mb-2' style={{ boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(255, 255, 255, 0.2) 0px -3px 0px inset', minHeight: '80vh' }}>
                            <UploadDefineAsset setSave={setSave}></UploadDefineAsset>
                            <form className="mb-4" style={small ? { width: '100%', margin: 'auto' } : { width: '80%', margin: 'auto' }} onSubmit={handleSubmit}>
                                <div className="mt-2">
                                    <span className="block input-group-text mb-2" style={{ width: 'fit-content', border: '1px solid #686e74', background: 'transparent', color: '#686e74' }}>{t('equipmentName')}</span>
                                    <input
                                        type="text"
                                        aria-label="Search Tag"
                                        className={` ${styleInput['new-input']}  form-control w-100 mb-2`}
                                        onChange={handleTagName}
                                        ref={inputRef}
                                        required
                                    />
                                    <div className='w-25 btn btn-secondary' onClick={handleClear}>{t('clear')}</div>
                                    <button type="submit" className={`${styles['btn-style']}   w-25 btn offset-6`}>{t('search')}</button>
                                </div>
                            </form>
                            <div className="d-flex flex-row justify-content-center" style={{ margin: 'auto', minHeight: '50vh' }}>
                                <div className='overflow-auto bg-transparent  p-1' style={{ width: '90%', maxHeight: '45vh', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                                    {show ? (
                                        <ul className="list-group">
                                            {taglist.map((tag) => (
                                                <li key={tag.dbid} onClick={() => { handleTagClick(tag) }}
                                                    className={`list-group-item ${highlightTag === tag.dbid ? `${styleInput['highlight']}` : ''}`}
                                                    style={{ color: '#121212', backgroundColor: 'transparent', borderColor: '#ebb30b' }}
                                                >
                                                    {tag.name}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        load ? <div className='text-muted'>{t('loading')}</div> : <><h4 class="text-center text-muted">{t('noSuchTags')}</h4>
                                            <p className="text-muted text-center">{t('noTagsMatching')}</p></>
                                    )}
                                </div>
                                {/* <div className='d-flex flex-column justify-content-center align-items-center'
                                style={{ width: '10%', maxHeight: '40vh' }}>
                                <button className={`${styles['btn-style']}   btn mb-4`} onClick={() => handleMoveTagClick(selectedTag)}> {`>>`} </button>
                                <button className="btn btn-secondary" onClick={() => handleMoveAssetClick(selectedAsset)}> {`<<`} </button>
                            </div> */}
                                {/* <div style={{ width: '40%', maxHeight: '40vh' }}>
                                <div className='overflow-auto bg-transparent mb-1  p-3' style={{ width: '100%', height: '100%', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
                                    <h2 className='text-center' style={{ color: '#121212' }}>Equipments</h2>
                                    {
                                        <>
                                            <ul className="list-group">
                                                {assetlist.map((asset) => (
                                                    <div className="d-flex flex-row align-items-center justify-content-between gap-2" key={asset.dbid}>
                                                        <li
                                                            className={`list-group-item w-75  ${highlightAsset === asset.dbid ? `${styleInput['highlight']}` : ''}`}
                                                            style={{ color: '#121212', backgroundColor: 'transparent', borderColor: '#ebb30b' }}
                                                            onClick={() => { handleAssetClick(asset) }}>
                                                            {asset.name}
                                                        </li>
                                                        <button className={`${styles['btn-style']}   btn w-25`} onClick={() => handleAddButton(asset)}>+</button>
                                                    </div>
                                                ))}
                                            </ul>

                                        </>
                                    }
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button className={`${styles['btn-style']}   btn w-100`} onClick={handleSave}>Save</button>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <AliasModal showModal={showModal} closeModal={closeModal} asset={aliasAsset} />
                    )}</>)
                    : (<div className='w-100 h-100 d-flex justify-content-center' style={{ alignItems: 'center' }}>
                        <div className='w-100'>
                            <h2 className='text-center' style={{ width: '90%' }}>{t('modelBeingTranslated')} {modelStatus}</h2><br />
                        </div>
                    </div>
                    )}
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashDefineAssets;