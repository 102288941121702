import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import { useParams } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Component to display files associated with connected assets.
 * Manages fetching and displaying files grouped by document types.
 * Allows viewing and downloading of files based on their types.
 * @param {Object} props - Component props containing selectedPart and connectedAssets.
 */
function UserConnectedFileListViewer(props) {
    const [groupedFiles, setGroupedFiles] = useState([]); // State to store grouped files by document types
    const [hcode, setHcode] = useState(null); // State to manage asset HCODE
    const [load, setLoad] = useState(false); // State to manage loading status
    const [asset_name, setAsset_name] = useState(""); // State to store asset name
    const [aliase, setAliase] = useState(null); // State to store alias name
    const { selectedPart, connectedAssets } = props; // Destructuring props to get selectedPart and connectedAssets
    const { plantHcode, plant_name } = useParams(); // Get parameters from URL (assuming 'react-router-dom' is used)

    const [docTypes, setDocTypes] = useState([]); // State to store document types

    // References for DataTable instances
    const EQUIPMENTOUTLINEREF = useRef();
    const EQUIPMENTPIDREF = useRef();
    const PIPEISOMETRICREF = useRef();
    const PIPEPIDREF = useRef();
    const INSTRUMENTREF = useRef();
    const VALVEREF = useRef();

    // Mapping document codes to DataTable references
    const docTables = {
        '11': EQUIPMENTOUTLINEREF,
        '12': EQUIPMENTPIDREF,
        '21': PIPEISOMETRICREF,
        '22': PIPEPIDREF,
        '31': INSTRUMENTREF,
        '41': VALVEREF
    };

    const [activeTab, setActiveTab] = useState(0); // State to manage active tab index

    // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
    // Using ASSET_TYPE to getFiles
    const ASSET_TYPE = 1;

    // Effect to fetch files when connectedAssets changes
    useEffect(() => {
        fetchFiles();
    }, [connectedAssets]);

    // Initial effect to fetch files
    useEffect(() => {
        fetchFiles();
    }, []);

    // Effect to set active tab after 1 second delay
    useEffect(() => {
        setTimeout(() => {
            setActiveTab(0);
        }, 1000);
    }, []);

    // Effect to initialize or reinitialize DataTables based on activeTab and connectedAssets changes
    useEffect(() => {
        // Function to initialize or reinitialize DataTable based on activeTab
        const initializeDataTable = () => {
            switch (activeTab) {
                case 0:
                    $(EQUIPMENTOUTLINEREF.current).DataTable().destroy();
                    $(EQUIPMENTOUTLINEREF.current).DataTable();
                    break;
                case 1:
                    $(EQUIPMENTPIDREF.current).DataTable().destroy();
                    $(EQUIPMENTPIDREF.current).DataTable();
                    break;
                case 2:
                    $(PIPEISOMETRICREF.current).DataTable().destroy();
                    $(PIPEISOMETRICREF.current).DataTable();
                    break;
                case 3:
                    $(PIPEPIDREF.current).DataTable().destroy();
                    $(PIPEPIDREF.current).DataTable();
                    break;
                case 4:
                    $(INSTRUMENTREF.current).DataTable().destroy();
                    $(INSTRUMENTREF.current).DataTable();
                    break;
                case 5:
                    $(VALVEREF.current).DataTable().destroy();
                    $(VALVEREF.current).DataTable();
                    break;
                default:
                    break;
            }
        };

        initializeDataTable();
    }, [activeTab, connectedAssets]);

    // Function to fetch asset details (name and alias) based on hcode
    const fetchAsset = async () => {
        if (hcode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hcode}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname); // Set asset name if fetch is successful
                    setAliase(data.alias_name || null); // Set alias name if available
                }
            } catch (error) {
                console.error(error); // Log error if fetch fails
            }
        }
    };

    // Function to fetch files associated with connectedAssets
    const fetchFiles = async () => {
        let allFiles = [];
        for (const hcode of connectedAssets) {
            try {
                setLoad(true); // Set loading state to true
                const response = await fetch(`${SERVER_URL}/user/assetFiles/${hcode.hcode}/${hcode.type}?hcode=${localStorage.getItem('userhcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    allFiles = [...allFiles, ...data.assetFiles]; // Accumulate fetched files
                } else {
                    setLoad(false); // Set loading state to false if fetch is unsuccessful
                }
            } catch (error) {
                console.error(error); // Log error if fetch fails
            }
        }
        const resultFiles = groupFiles(allFiles); // Group files by document types
        setGroupedFiles(resultFiles); // Set groupedFiles state with grouped files
    };

    // Function to fetch document types
    const GetDocTypes = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/document/types?hcode=${localStorage.getItem('userhcode')}`);
            const data = await response.json();
            if (data.statusCode === 200) {
                setDocTypes(data.docTypes); // Set docTypes state with fetched document types
            }
        } catch (error) {
            console.error(error); // Log error if fetch fails
        }
    };

    // Effect to fetch document types on component mount
    useEffect(() => {
        GetDocTypes();
    }, []);

    // Function to download files
    const handleDownloadFiles = async (fname) => {
        try {
            const response = await fetch(`${SERVER_URL}/user/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('userhcode')}`);
            const file = await response.blob(); // Convert response to Blob
            const url = URL.createObjectURL(file); // Create object URL for the Blob

            const filenameParts = fname.split('|');
            const originalName = filenameParts[1]; // Get original file name

            const link = document.createElement('a'); // Create anchor element for download
            link.href = url; // Set href attribute to object URL
            link.download = originalName; // Set download attribute to original file name
            link.click(); // Trigger download
        } catch (error) {
            console.error('Error downloading file:', error); // Log error if download fails
        }
    };

    // Function to view files
    const handleView = async (fname) => {
        try {
            const fileExtension = fname.split('.').pop().toLowerCase(); // Get file extension

            const response = await fetch(`${SERVER_URL}/user/assetfile/${fname}?hcode=${localStorage.getItem('userhcode')}`);
            const fileBlob = await response.blob(); // Convert response to Blob
            const fileUrl = URL.createObjectURL(fileBlob); // Create object URL for the Blob

            if (fileExtension === 'pdf') {
                window.open(fileUrl, '_blank'); // Open PDF in new tab
            } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
                window.open(fileUrl, '_blank'); // Open image in new tab
            } else if (['xlsx', 'xls'].includes(fileExtension)) {
                window.open(`/user/excel/viewer/${fname}`, '_blank'); // Open Excel in new tab
            } else {
                alert("Unsupported file type for viewing."); // Alert for unsupported file types
            }
        } catch (error) {
            console.error('Error viewing file:', error); // Log error if view fails
        }
    };

    // Function to group files by document types
    const groupFiles = (files) => {
        return files.reduce((acc, file) => {
            const typeId = file.doc_code; // Get document code as typeId
            acc[typeId] = acc[typeId] || []; // Initialize array for typeId if not exists
            acc[typeId].push(file); // Push file to typeId array
            return acc;
        }, {});
    };

    // Render UI based on connectedAssets length
    return (
        connectedAssets.length > 0 ? (
            <div id="content" className='w-100'>
                <div className="mt-3 ps-2">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {/* Render tab headers for each document type */}
                        {docTypes.map((item, index) => (
                            <li className="nav-item" key={item.hcode}>
                                <button
                                    className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {item.doc_type}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {/* Render tab content for each document type */}
                        {docTypes.map((item, index) => (
                            <div
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                key={item.doc_code}
                            >
                                {/* Render table with files grouped by document type */}
                                {groupedFiles[item.doc_code] && groupedFiles[item.doc_code].length > 0 ? (
                                    <table className={`table ${styles.table} table-sm table-bordered`} id={item.hcode} ref={docTables[item.doc_code]}>
                                        <thead>
                                            <tr className={`${styles['border-top-bottom']}`}>
                                                <th>Tag Name</th>
                                                <th>File Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Render rows for each file */}
                                            {groupedFiles[item.doc_code]?.map((file) => (
                                                <tr key={file.tag} className={`${styles['row-hover']}`}>
                                                    <td>{file.tag}</td>
                                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => handleView(file.fname.split('|')[0])}>
                                                        {file.fname.split('|')[1]}
                                                    </td>
                                                    <td>
                                                        {/* Button to open file */}
                                                        <button
                                                            className={`${styles['btn-style']} btn me-2`}
                                                            style={{ lineHeight: "1" }}
                                                            onClick={() => handleView(file.fname.split('|')[0])}
                                                        >
                                                            Open
                                                        </button>
                                                        {/* Button to download file */}
                                                        <span style={{ cursor: 'pointer' }} onClick={() => handleDownloadFiles(file.fname)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className='p-3'>No files available.</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            <div id="content" className='w-100'>
                <h2 className='p-2'>No connected components</h2>
            </div>
        )
    );
}

export default UserConnectedFileListViewer;
