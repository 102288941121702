/// import * as Autodesk from "@types/forge-viewer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


/**
 * The function `getAccessToken` asynchronously fetches an access token from a server URL using a
 * user's hcode stored in localStorage and calls a callback function with the access token and its
 * expiration time.
 * @param callback - The `callback` parameter in the `getAccessToken` function is a function that will
 * be called once the access token is successfully retrieved. It takes two arguments: `access_token`
 * (the token string) and `expires_in` (the expiration time of the token in seconds). You can define
 * this
 */
async function getAccessToken(callback) {
    try {
        const resp = await fetch(`${SERVER_URL}/user/api/auth/token?hcode=${localStorage.getItem('userhcode')}`);
        if (!resp.ok) {
            throw new Error(await resp.text());
        }
        const { access_token, expires_in } = await resp.json();
        callback(access_token, expires_in);
    } catch (err) {
        alert('Could not obtain access token. See the console for more details.');
        console.error(err);
    }
}



// export function initViewer(container) {
//     return new Promise(function (resolve, reject) {
//         window.Autodesk.Viewing.Initializer({ getAccessToken }, function () {
//             const config = {
//                 extensions: ['Autodesk.DocumentBrowser']
//             };
//             const viewer = new window.Autodesk.Viewing.GuiViewer3D(container, config);
//             viewer.start();
//             viewer.setQualityLevel(/* ambient shadows */ false, /* antialiasing */ true);
//             viewer.setTheme('dark-theme');
//             viewer.setGroundShadow(true);
//             viewer.setGroundReflection(false);
//             viewer.setGhosting(false);
//             viewer.setEnvMapBackground(true);
//             viewer.setLightPreset(5);
//             const aecProfileSettings = window.Autodesk.Viewing.ProfileSettings.AEC;
//             // The custom profile settings are cloned from the AEC profile settings.
//             const customProfileSettings = window.Autodesk.Viewing.ProfileSettings.clone(aecProfileSettings);

//             // Turn off progressive rendering
//             // customProfileSettings.settings.progressiveRendering = false;
//             // Open the properties panel when selecting an object.
//             // customProfileSettings.settings.openPropertiesOnSelect = true;
            
//             const customProfile = new window.Autodesk.Viewing.Profile(customProfileSettings);
//             viewer.setProfile(customProfile);
//             viewer.setSelectionColor(new window.THREE.Color(0xEBB30B));
//             resolve(viewer);
//         });
//     });
// }
/**
 * The `initViewer` function initializes an Autodesk viewer in a specified container element and
 * returns a promise that resolves with the viewer instance.
 * @param container - The `container` parameter in the `initViewer` function is the HTML element where
 * the Autodesk viewer will be initialized and displayed. It is the DOM element that will contain the
 * 3D viewer interface.
 * @returns The `initViewer` function returns a Promise that resolves with the viewer instance once it
 * has been initialized successfully, or rejects with an error if there is an issue during
 * initialization.
 */

/**
 * The `initViewer` function initializes an Autodesk viewer in a specified container element and
 * returns a promise that resolves with the viewer instance.
 * @param container - The `container` parameter in the `initViewer` function is the HTML element where
 * the Autodesk viewer will be initialized and displayed. It is the DOM element that will contain the
 * 3D viewer interface.
 * @returns The `initViewer` function returns a Promise. The Promise will resolve with the viewer
 * instance if the initialization is successful, or reject with an error if there is an issue during
 * initialization.
 */
export function initViewer(container) {
    return new Promise((resolve, reject) => {
        window.Autodesk.Viewing.Initializer({ getAccessToken }, function () {
            try {
                const defaultConfig = {}; // No extensions or custom settings
                const viewer = new window.Autodesk.Viewing.GuiViewer3D(container, defaultConfig);

                viewer.start(); // Start the viewer
                resolve(viewer); // Resolve with the viewer instance

            } catch (error) {
                reject(error); // If there's an error during initialization, reject the promise
            }
        });
    });
}

/**
 * The function `loadModel` loads a 3D model into a viewer using a provided URN and returns a promise
 * that resolves when the model is successfully loaded or rejects with an error message if loading
 * fails.
 * @param viewer - The `viewer` parameter is an object that represents the viewer instance used to
 * display the 3D model.
 * @param urn - The `urn` parameter in the `loadModel` function is a unique identifier for the model
 * that you want to load. It is used to specify the location of the model to be loaded by the Autodesk
 * Forge Viewer.
 * @returns The `loadModel` function returns a Promise.
 */
export function loadModel(viewer, urn) {
    return new Promise(function (resolve, reject) {
        function onDocumentLoadSuccess(doc) {
            const defaultNode = doc.getRoot().getDefaultGeometry();
            resolve(viewer.loadDocumentNode(doc, defaultNode));
            // resolve(viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()));
        }
        function onDocumentLoadFailure(code, message, errors) {
            reject({ code, message, errors });
        }
        viewer.setGhosting(false);
        window.Autodesk.Viewing.Document.load(`urn:${urn}`, onDocumentLoadSuccess, onDocumentLoadFailure);
    });
}

