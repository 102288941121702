import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import Sidebars from '../../components/sidebar/sidebar';
import { Navigate, useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import UploadOutlineDrawingsExcel from '../../components/buttons/UploadOutlineDrawing';
import UploadPipingIsometricDrawingsExcel from '../../components/buttons/UploadPipingIsometric';
import MultipleFileUploadButton from '../../components/buttons/UploadFiles';
import UploadPropertyExcel from '../../components/buttons/uploadProperties';
import UploadDefinePipeConnections from '../../components/buttons/UploadExcelPipeConnections';
import UploadPIDDrawingsExcel from '../../components/buttons/UploadPIDDrawing';
import UploadInstrumentDrawingsExcel from '../../components/buttons/UploadInstrumentDrawing';
import UploadValveDrawingsExcel from '../../components/buttons/UploadValveDrawing';
import UploadEquipmentPIDDrawingsExcel from '../../components/buttons/UploadEquipmentPIDDrawing';
import UploadPlantDocumentsExcel from '../../components/buttons/UploadPlantDocuments';
import { useTranslation } from 'react-i18next';


function DashActionCenter({ screenCode }) {

    const { plant_name, plantHCode, urn } = useParams();
    const { t } = useTranslation();

    const handleDefineAssets = () => {
        window.open(`/defineassets/${plant_name}/${plantHCode}?urn=${urn}`, '_blank');
    };

    const handleDefinePipes = () => {
        window.open(`/definepiping/${plant_name}/${plantHCode}?urn=${urn}`, '_blank');
    };

    const handleDefineInstrument = () => {
        window.open(`/defineInstruments/${plant_name}/${plantHCode}?urn=${urn}`, '_blank');
    };

    const handleDefineValve = () => {
        window.open(`/defineValve/${plant_name}/${plantHCode}?urn=${urn}`, '_blank');
    };


    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/plants" style={{ 'color': 'rgb(18, 18, 18)' }}>{t('plants')}</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{plant_name}</li>
                            <li class="breadcrumb-item active" aria-current="page">{t('actionCenter')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <hr />
                    <h3> {t('step1')}</h3>
                    <hr />
                    <div class="">
                        <p>
                            <button
                                className={`${styles['btn-style']}   btn ms-2`}
                                style={{ lineHeight: "1" }}
                                onClick={() => handleDefineAssets()}
                            >
                                {t('defineAsset')}
                            </button>
                            <button
                                className={`${styles['btn-style']}   btn ms-2`}
                                style={{ lineHeight: "1" }}
                                onClick={() => handleDefinePipes()}
                            >
                                {t('definePipes')}
                            </button>
                            <button
                                className={`${styles['btn-style']}   btn ms-2`}
                                style={{ lineHeight: "1" }}
                                onClick={() => handleDefineInstrument()}
                            >
                                {t('defineInstruments')}
                            </button>
                            <button
                                className={`${styles['btn-style']}   btn ms-2`}
                                style={{ lineHeight: "1" }}
                                onClick={() => handleDefineValve()}
                            >
                                {t('defineValve')}
                            </button>
                        </p>
                        <p>
                            <strong>{t('note')}</strong>
                            <ul>
                                <li>{t('defineAandP')}</li>
                                <li>{t('defineIandVafterAandP')}</li>
                            </ul>
                        </p>

                    </div>
                    <hr />
                    <h3>{t('step2')}</h3>
                    <hr />
                    <div className="">
                        <p className="d-flex flex-wrap gap-4">
                            <span className=''><UploadOutlineDrawingsExcel></UploadOutlineDrawingsExcel></span>
                            <span className=''><UploadEquipmentPIDDrawingsExcel></UploadEquipmentPIDDrawingsExcel></span>
                            <span className=''><UploadPipingIsometricDrawingsExcel></UploadPipingIsometricDrawingsExcel></span>
                            <span className=''><UploadPIDDrawingsExcel></UploadPIDDrawingsExcel></span>
                            <span className=''><UploadInstrumentDrawingsExcel></UploadInstrumentDrawingsExcel></span>
                            <span className=''><UploadValveDrawingsExcel></UploadValveDrawingsExcel> </span>
                        </p>
                        <p>
                            <strong>{t('note')}</strong>
                            {t('note1')}
                        </p>
                    </div>
                    <hr />
                    <h3>{t('step3')}</h3>
                    <hr />
                    <div class="">
                        <p><MultipleFileUploadButton></MultipleFileUploadButton></p>
                        <p><strong>{t('note')}</strong> {t('uploadFile')}</p>
                    </div>
                    <hr />
                    <h3> {t('other')}</h3>
                    <hr />
                    <div class="">
                        <p>
                            <UploadPropertyExcel></UploadPropertyExcel>
                            <UploadDefinePipeConnections></UploadDefinePipeConnections>
                            <UploadPlantDocumentsExcel></UploadPlantDocumentsExcel>
                        </p>

                        <p><strong>{t('note')}</strong>{t('DefineVafterPipeandP')}</p>
                    </div>
                    <hr />
                    <br />
                    <div className="ps-2">
                    </div>
                </div>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashActionCenter;