import React, { useEffect, useState } from "react";
import Alert from "../alert/Alert";
import { useParams, useNavigate } from "react-router-dom";
import styles from './../../common/common.module.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Component for creating a new sensor.
 * Fetches asset name based on asset code and allows input for sensor name.
 * Submits the data to the server and shows an alert based on the server response.
 */
function CreateSensor() {
  const [sensorName, setSensorName] = useState("");
  const [responseData, setResponseData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [asset_name, setAsset_name] = useState("");

  const ASSET_TYPE = 1; // 1 indicates it is either Equipment or Pipe

  const { plant_name, zone_name, assetHCode } = useParams();
  const navigate = useNavigate();

  /**
   * Fetches the asset name based on the asset code and asset type.
   */
  useEffect(() => {
    const fetchAssetName = async () => {
      try {
        const resp = await fetch(`${SERVER_URL}/getAssetName/${assetHCode}/${ASSET_TYPE}`);
        const data = await resp.json();
        if (data.statusCode === 200) {
          setAsset_name(data.aname);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAssetName();
  }, [assetHCode]);

  /**
   * Handles changes in the sensor name input field.
   * @param {object} event - The event object containing the input value.
   */
  const handleSensorName = (event) => {
    setSensorName(event.target.value.trim());
  };

  /**
   * Handles form submission.
   * Sends a POST request to the server to create a new sensor with the provided data.
   * Shows an alert based on the server response and navigates to the sensors page if successful.
   * @param {object} event - The form submit event.
   */
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${SERVER_URL}/sensors/create?hcode=${localStorage.getItem("hcode")}`, {
        method: "POST",
        body: JSON.stringify({
          sname: sensorName,
          aname: asset_name,
          zname: zone_name,
          plant_name: plant_name,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      setResponseData(data);
      setShowAlert(true);

      setTimeout(() => {
        if (data.statusCode === 200) {
          navigate(`/sensors/${plant_name}/${zone_name}/${assetHCode}`);
        }
        setShowAlert(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  // Render JSX
  return (
    <>
      <div className="w-100 mt-3" id="content">
        <div id="msg">
          {showAlert && <Alert data={responseData}></Alert>}
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "600px" }}>
          <form className="w-50" onSubmit={handleFormSubmit}>
            <h1 className="h1 mb-3 fw-normal" style={{ color: '#121212', fontSize: '600' }}>Create Sensor</h1>
            <div className="form-floating">
              <input type="text" className="form-control" name="plant_name" placeholder="name@example.com" value={plant_name} disabled readOnly />
              <label htmlFor="plant_name">Plant Name</label>
            </div>
            <br />
            <div className="form-floating">
              <input className="form-control" type="text" name="zone_name" value={zone_name} disabled readOnly />
              <label htmlFor="zone">Area Name</label>
            </div>
            <br />
            <div className="form-floating">
              <input className="form-control" type="text" name="asset_name" value={asset_name} disabled readOnly />
              <label htmlFor="asset">Asset Name</label>
            </div>
            <br />
            <div className="form-floating">
              <input className="form-control" type="text" name="sensor_name" onChange={handleSensorName} placeholder="name@example.com" />
              <label htmlFor="sensor_name">Sensor Name</label>
            </div>
            <br />
            <button className={`w-100 btn btn-lg ${styles['btn-style']}`} type="submit">
              Create Sensor
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateSensor;
