import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../components/wrapper/wrapper';
import Footer from '../../components/footer/Footer';
import AppContext from '../../context/loginContext';
import UserHeader from '../../components/header/UserHeader';
import DashboardModal from '../../components/modal/DashboardModal';
import Alert from '../../components/alert/Alert';
import UserViewer from '../../components/viewer/UserViewer';
import style from '../dash-page/analyticPageStyle/analytic.module.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import UserViewerListFiles from '../../components/tables/filetables/UserViewerFileList';
import UserViewerMeridianFiles from '../../components/tables/filetables/UserViewerMeridianFiles';
import UserViewerSensors from '../../components/Lists/UserViewerSensorLists';
import UserViewerMaintenance from '../../components/tables/maintenancetable/UserMaintenanceViewer';
import UserViewerProcurement from '../../components/tables/procurementtable/UserProcurementsViewer';
import FlashMessage from '../../components/flash/FlashMessage';
import styles from '../../common/common.module.css';
import { PDFViewer } from '../../components/pdfViewer/PDFViewer';
import UserSearchComponent from '../../components/search/userSearch';
import UserViewerProperties from '../../components/properties/userProperites';
import OverlayWithTabs from '../../components/overlays/overlaysWithTabs';
import UserConnectedFileListViewer from '../../components/tables/filetables/UserConnectedFileListViewer';
import { Tooltip } from 'react-tooltip';
import { format } from "date-fns";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = format(date, 'dd-MM-yyyy');
    return formattedDate;
};

const HomePage = ({ screenCode }) => {

    const pdfFiles = [
        { name: 'Operation And Maintenance Manual.pdf', label: 'Operation And Maintenance Manual' }
    ];
    const today = new Date().toISOString().split("T")[0];


    const [activeTab, setActiveTab] = useState('docs');
    const [selectedPart, setSelectedPart] = useState(null);
    const [fromDate, setFromDate] = useState("2023-07-03");
    const [toDate, setToDate] = useState("2023-07-03");
    const [username, setUsername] = useState('User');
    const [ip, setIp] = useState('');
    const [accountHCode, setAccountHCode] = useState('');
    const [dashboards, setDashboards] = useState([]);
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [dashboard, setDashboard] = useState(null);
    const [zones, setZones] = useState([]);
    const [plantName, setPlantName] = useState('');
    const [assets, setAssets] = useState(null);
    const [pipes, setPipes] = useState(null);
    const [instruments, setInstruments] = useState(null);
    const [valves, setValves] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedPipe, setSelectedPipe] = useState(null);
    const [selectedInstrument, setSelectedInstrument] = useState(null);
    const [selectedValve, setSelectedValve] = useState(null);
    const [activeSubTab, setActiveSubTab] = useState('Live');
    const [activeDocSubTab, setActiveDocSubTab] = useState('Local');
    const [startDate, setStartDate] = useState("2023-07-03");
    const [endDate, setEndDate] = useState("2023-07-03");
    const [visible, setVisible] = useState(false);
    const [fname, setFname] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const [small, setSmall] = useState(null);
    const [heatMap, setHeatMap] = useState(false);
    const [access, setAccess] = useState([false, false, false, false, false])
    // 0 - document , 1- training, 2-Spares, 3-Maintainence,4-monitoring 
    const [plantHcode, setPlantHcode] = useState('')
    const [connectedAssets, setConnectedAssets] = useState([]);
    const [connectedDocs, setConnectedDocs] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [subscription, setSubscription] = useState(false);

    const checkAccess = async () => {
        const response = await fetch(`${SERVER_URL}/user/plant/modules/${plantHcode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            // Create a copy of the previous access array to avoid mutating state directly
            const updatedAccess = [...access];

            for (const i of data.modules) {
                if (i.mname === 'TRAINING') {
                    updatedAccess[1] = true;
                } else if (i.mname === 'DOCUMENTATION') {
                    updatedAccess[0] = true;
                } else if (i.mname === 'SPARES') {
                    updatedAccess[2] = true;
                } else if (i.mname === 'MAINTENANCE') { // Corrected the typo here
                    updatedAccess[3] = true;
                } else if (i.mname === 'MONITORING') { // Corrected the typo here
                    updatedAccess[4] = true;
                }
            }

            // Update the state with the modified access array
            setAccess(updatedAccess);
        }
    }


    useEffect(() => {
        checkAccess();
    }, [plantHcode])

    useEffect(() => {
        fetchSubscriptionDetails();
    }, [accountHCode])

    useEffect(() => {
        if (window.innerWidth < 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {

        if (intervalId) {
            clearInterval(intervalId);
            window.NOP_VIEWER.clearThemingColors();
        }

        const updateColors = () => {
            if (assets) {
                if (selectedAsset === null && selectedZone !== null && heatMap) {
                    assets.map((asset) => {
                        if (!asset.aname.includes('SE') && !asset.aname.includes('FL')) {
                            const colors = [new window.THREE.Vector4(1, 0, 0, 1), new window.THREE.Vector4(0, 0, 1, 1)]
                            const randomIndex = Math.floor(Math.random() * colors.length);
                            window.NOP_VIEWER.setThemingColor(parseInt(asset.assetID), colors[randomIndex], null, true);
                        }
                    });
                }
            }
        };

        if (selectedZone !== null && selectedAsset === null && heatMap) {
            updateColors();
            const id = setInterval(updateColors, 1000);
            setIntervalId(id);
        }
    }, [assets, selectedAsset, selectedZone, heatMap]);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStartDate(fromDate);
        setEndDate(toDate);
    }


    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleSubTab = (tab) => {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    };

    const toggleDocSubTab = (tab) => {
        if (activeSubTab !== tab) {
            setActiveDocSubTab(tab);
        }
    };

    const handleClick = () => {
        setHeatMap(prev => !prev);
    };

    const { isUserLogin, setIsUserLogin } = useContext(AppContext);
    const navigate = useNavigate();

    const fetchDashboards = async () => {
        const response = await fetch(`${SERVER_URL}/dashboards?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setDashboards(data.dashboards);
        }
    };

    const fetchSubscriptionDetails = async () => {
        const response = await fetch(`${SERVER_URL}/user/subscription/${accountHCode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setSubscription(data);
        }
    };


    const fetchDashboard = async (hcode) => {
        const response = await fetch(`${SERVER_URL}/dashboard/${hcode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setDashboard(data.dashboard);
            setPlantName(data.dashboard.plant_name);
            setPlantHcode(data.dashboard.plant_hcode);
        }
    };

    const updateDashboard = async (hcode, data) => {
        const response = await fetch(`${SERVER_URL}/dashboard/update/${hcode}?hcode=${localStorage.getItem('userhcode')}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const updateData = await response.json();
        setDashboard(null);
        setChange(prev => !prev);
    };

    const deleteDashboard = async (hcode) => {
        const response = await fetch(`${SERVER_URL}/dashboard/delete/${hcode}?hcode=${localStorage.getItem('userhcode')}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        setDashboard(null);
        setChange(prev => !prev);
    };

    const fetchUser = async () => {
        const response = await fetch(
            `${SERVER_URL}/username?hcode=${localStorage.getItem('userhcode')}`
        );
        const data = await response.json();
        if (data.statusCode === 200) {
            setAccountHCode(data.accountHCode);
            setIp(data.user_ip);
            setUsername(data.username);
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('userhcode')) {
            navigate('/userlogin');
        } else {
            fetchUser();
            fetchDashboards();
        }
    }, [change]);

    useEffect(() => {
        fetchZones();
    }, [plantName])


    const handleDashSelect = (event) => {
        const { name, value } = event.target;

        if (name === 'dash' && value !== "") {
            const selectedDash = dashboards.find((dash) => dash.dash_name === value);
            setSelectedDashboard(selectedDash);
        } else {
            return;
        }
    };

    const handleFetchButtonClick = async () => {
        if (selectedDashboard) {
            await fetchDashboard(selectedDashboard.hcode);
        }
    };

    const handleMakePublicButtonClick = () => {
        const datas = { "isPrivate": 1 };
        updateDashboard(dashboard.hcode, datas);
    };

    const handleDeleteButtonClick = () => {
        deleteDashboard(dashboard.hcode);
    };

    const handleCreateButtonClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleZoneHover = async (hcode) => {
        const selectedId = [];
        // Perform fetch function based on the selected zone
        const response = await fetch(`${SERVER_URL}/user/allassets/${hcode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {

            // Push assetID values into selectedId array
            data.assets.forEach((asset) => {
                if (asset.assetID) {
                    selectedId.push(parseInt(asset.assetID));
                }
            });

            // Call NOP_VIEWER functions when selectedId is filled
            if (selectedId.length > 0) {
                window.NOP_VIEWER.isolate(selectedId);
                window.NOP_VIEWER.fitToView(selectedId);
            }

        }
    };


    const handleZoneSelect = async (zone) => {
        window.NOP_VIEWER.select();
        if (intervalId !== null) {
            clearInterval(intervalId);
            window.NOP_VIEWER.clearThemingColors();
        }

        setSelectedZone(zone);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        // Create two Promises for the two API requests
        const request1 = fetch(`${SERVER_URL}/user/assets/${zone.hcode}?hcode=${localStorage.getItem('userhcode')}`);
        const request2 = fetch(`${SERVER_URL}/user/pipes/${zone.hcode}?hcode=${localStorage.getItem('userhcode')}`);
        const request3 = fetch(`${SERVER_URL}/user/instruments/${zone.hcode}?hcode=${localStorage.getItem('userhcode')}`);
        const request4 = fetch(`${SERVER_URL}/user/valves/${zone.hcode}?hcode=${localStorage.getItem('userhcode')}`);

        try {
            // Use Promise.all to execute both requests concurrently
            const [data1, data2, data3, data4] = await Promise.all([request1, request2, request3, request4].map(async (request) => {
                const response = await request;
                return response.json();
            }));

            // Check the statusCode for both responses
            if (data1.statusCode === 200) {
                setAssets(data1.assets);
            }

            if (data2.statusCode === 200) {
                setPipes(data2.assets);
            }

            if (data3.statusCode === 200) {
                setInstruments(data3.assets);
            }

            if (data4.statusCode === 200) {
                setValves(data4.assets);
            }

        } catch (error) {
            // Handle any errors that may occur during the requests
            console.error("An error occurred:", error);
        }
    };

    const handleAssetHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleInstrumentHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.forgeID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleValveHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.valveID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleAssetSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedAsset(asset);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setConnectedDocs(false);
        setSelectedValve(null);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];

        window.NOP_VIEWER.select(selectedId);
    };

    const getPipeConnections = async (assetCode, type = 0) => {
        const response = await fetch(`${SERVER_URL}/user/pipe/connections/${assetCode}?hcode=${localStorage.getItem('userhcode')}`);
        const data = await response.json();

        const connectedEquipments = data.result.connectedEquipments;
        const allEquipmentIDs = data.result.allEquipmentIDs;

        // Function to get unique objects based on a property
        function getUniqueObjects(array, propertyName) {
            return array.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t[propertyName] === obj[propertyName]
                ))
            );
        }

        // Usage
        const uniqueObjects = getUniqueObjects(allEquipmentIDs, 'hcode');

        // const connectedEquipments = [];
        // const allEquipmentIDs = [];
        // if (data.statusCode === 200) {
        //     // Push assetID values into selectedId array
        //     data.connections.forEach((asset) => {
        //         if (asset.pipeDBID) {
        //             allEquipmentIDs.push(asset.pipe_id_fk);
        //             connectedEquipments.push(parseInt(asset.pipeDBID));
        //         }
        //         if (asset.equiFromDBID) {
        //             allEquipmentIDs.push(asset.equipment_from_id_fk);
        //             connectedEquipments.push(parseInt(asset.equiFromDBID));
        //         }
        //         if (asset.equiToDBID) {
        //             allEquipmentIDs.push(asset.equipment_to_id_fk);
        //             connectedEquipments.push(parseInt(asset.equiToDBID));
        //         }
        //     });
        // }

        // Convert the array to a Set to remove duplicates
        const uniqueEquipmentDBIDs = new Set(connectedEquipments);
        const uniqueEquipmentIDs = new Set(allEquipmentIDs);

        // Convert the Set back to an array if necessary
        const uniqueArray = Array.from(uniqueEquipmentDBIDs);
        let uniqueEquipments = Array.from(uniqueEquipmentIDs);

        // Function to remove an equipment based on assetCode
        function removeEquipmentByAssetCode(array, codeToRemove) {
            return array.filter(item => item.hcode !== codeToRemove);
        }
        // type 0 is asset and pipe
        if (type == 0) {
            // Remove the equipment with the specified asset code
            uniqueEquipments = removeEquipmentByAssetCode(uniqueEquipments, assetCode);
        }

        setConnectedAssets(uniqueEquipments);
        // setConnectedAssets(uniqueObjects);

        return uniqueArray;
    }

    const getPartName = async () => {
        return new Promise((resolve, reject) => {
            window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                const partName = data.name;
                resolve(partName);
            }, reject);
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            if (selectedPart) {
                setActiveTab('docs');
                setActiveDocSubTab('Local');
                const partName = await getPartName();
                const resp = await fetch(`${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?part_name=${partName}&hcode=${localStorage.getItem('userhcode')}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.equipment.aname);
                    let connectedEqui = [];
                    if (data.type == 1) {
                        connectedEqui = await getPipeConnections(data.equipment.hcode);
                    } else {
                        connectedEqui = await getPipeConnections(data.equipment.parenthcode, data.type);
                    }
                    window.NOP_VIEWER.isolate([parseInt(data.equipment.assetID), ...connectedEqui]);
                    window.NOP_VIEWER.fitToView([parseInt(data.equipment.assetID), ...connectedEqui]);
                }
            }
        };

        fetchData();

    }, [selectedPart]);

    const handlePipeSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedPipe(asset);
        setSelectedAsset(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];

        window.NOP_VIEWER.select(selectedId);

    };

    const handleInstrumentSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedInstrument(asset);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedValve(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.forgeID)];

        window.NOP_VIEWER.select(selectedId);

    };

    const handleValveSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedValve(asset);
        setSelectedInstrument(null);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.valveID)];

        window.NOP_VIEWER.select(selectedId);

    };

    const fetchZones = async () => {
        if (plantName) {
            const response = await fetch(`${SERVER_URL}/user/listOfZones/${plantName}?hcode=${localStorage.getItem('userhcode')}`);
            const data = await response.json();
            if (data.statusCode === 200) {
                setZones(data.zones);
            }
        }
    };

    const handleConnectedDocumentsClick = () => {
        setConnectedDocs(true);
    }

    const handleBackClick = () => {
        setConnectedDocs(false);
    }

    const handleClearAll = () => {
        setSelectedPart(null);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        setSelectedZone(null);
        window.NOP_VIEWER.select(null);
        window.NOP_VIEWER.fitToView([1]);
        window.NOP_VIEWER.showAll();
    }

    return (
        <>
            <Wrapper>
                <UserHeader />
                <FlashMessage></FlashMessage>
                <div className={`${subscription.subscription === false ? 'd-flex justify-content-center align-items-center' : ''}`} style={{ minHeight: "86vh" }}>
                    <div style={{ position: 'fixed', bottom: '9vh', right: '1vh', backgroundColor: 'white', borderRadius: '45%', boxShadow: 'box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;' }}>
                        <a href={`https://support.soedigital.com`} target='_blank'>
                            <img src={`${process.env.PUBLIC_URL}/images/tech.png`} alt="Support" width={'50px'} height={'auto'} className="logo-img" />
                        </a>
                    </div>

                    {subscription.subscription === true &&
                        <div className="d-flex flex-wrap justify-content-between px-3 py-2 gap-3">
                            <div>
                                {dashboard &&
                                    <div className="d-flex flex-wrap gap-3">
                                        <DropdownButton id="dropdownMenuLink" title={selectedZone ? selectedZone.zoneID + ' - ' + selectedZone.zname.slice(0, 15) : "Areas"} variant='secondary'>
                                            {zones.map((zone) => (
                                                <Dropdown.Item key={zone.hcode} onClick={() => handleZoneSelect(zone)} onMouseLeave={() => handleZoneHover(null)} onMouseEnter={() => { handleZoneHover(zone.hcode) }}>{zone.zoneID + ' - ' + zone.zname}</Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                        {selectedZone && assets &&
                                            <DropdownButton id="dropdownMenuLink" title={selectedAsset ? selectedAsset.aname.slice(1) : "Assets"} variant='secondary'>
                                                {assets.length === 0 ?
                                                    <Dropdown.Item key={"No Data"}>No Assets from this Zone.</Dropdown.Item> :
                                                    assets.map((asset) => (
                                                        <Dropdown.Item key={asset.assetID} onClick={() => handleAssetSelect(asset)} onMouseEnter={() => { handleAssetHover(asset) }} >{asset.alias_name} ({asset.aname.slice(1)})</Dropdown.Item>
                                                    ))
                                                }
                                            </DropdownButton>
                                        }
                                        {selectedZone && pipes &&
                                            <DropdownButton id="dropdownMenuLink" title={selectedPipe ? selectedPipe.aname.slice(1) : "Pipes"} variant='secondary'>
                                                {pipes.length === 0 ?
                                                    <Dropdown.Item key={"No Data"}>No Pipes from this Zone.</Dropdown.Item> :
                                                    pipes.map((asset) => (
                                                        <Dropdown.Item key={asset.assetID} onClick={() => handlePipeSelect(asset)} onMouseEnter={() => { handleAssetHover(asset) }} >{asset.alias_name} ({asset.aname.slice(1)})</Dropdown.Item>
                                                    ))}
                                            </DropdownButton>
                                        }
                                        {selectedZone && instruments &&
                                            <DropdownButton id="dropdownMenuLink" title={selectedInstrument ? selectedInstrument.instrument_tag.slice(1) : "Instruments"} variant='secondary' >
                                                {instruments.length === 0 ?
                                                    <Dropdown.Item key={"No Data"}>No Instruments from this Zone.</Dropdown.Item> :
                                                    instruments.map((asset) => (
                                                        <Dropdown.Item key={asset.forgeID} onClick={() => handleInstrumentSelect(asset)} onMouseEnter={() => { handleInstrumentHover(asset) }} >{asset.instrument_desc} ({asset.instrument_tag.slice(1)})</Dropdown.Item>
                                                    ))}
                                            </DropdownButton>
                                        }
                                        {selectedZone && valves &&
                                            <DropdownButton id="dropdownMenuLink" title={selectedValve ? selectedValve.valve_tag.slice(1) : "Valves"} variant='secondary' >
                                                {valves.length === 0 ?
                                                    <Dropdown.Item key={"No Data"}>No Valves from this Zone.</Dropdown.Item> :
                                                    valves.map((asset) => (
                                                        <Dropdown.Item key={asset.valveID} onClick={() => handleValveSelect(asset)} onMouseEnter={() => { handleValveHover(asset) }} >{asset.valve_desc} ({asset.valve_tag.slice(1)})</Dropdown.Item>
                                                    ))
                                                }
                                            </DropdownButton>
                                        }
                                        {/* {selectedZone && !selectedAsset &&
                                        <button
                                            className={`${styles['btn-style']}   btn`}
                                            onClick={() => handleClick()}
                                        >
                                            Heat Map
                                        </button>
                                    } */}
                                        {(selectedZone || selectedPart) &&
                                            <button
                                                className={`${styles['btn-style']}   btn`}
                                                onClick={() => handleClearAll()}
                                            >
                                                Clear All
                                            </button>
                                        }
                                        {dashboard.created_by === username &&
                                            <button className={`btn ${style['btn-style']}`} onClick={handleDeleteButtonClick}>
                                                Delete
                                            </button>
                                        }
                                        {dashboard.isPrivate === 1 &&
                                            <button className={`btn ${style['btn-style']}`} onClick={handleMakePublicButtonClick}>
                                                Make Public
                                            </button>
                                        }

                                    </div>
                                }
                            </div>
                            <div className="d-flex gap-3">
                                <select
                                    className="form-select w-75"
                                    name="dash"
                                    value={selectedDashboard ? selectedDashboard.dash_name : ""}
                                    onChange={handleDashSelect}
                                >
                                    <option value="">List of Dashboards</option>
                                    <optgroup label="Private Dashboards">
                                        {dashboards
                                            .filter((dash) => dash.isPrivate)
                                            .map((dash) => (
                                                <option key={dash.hcode} value={dash.dash_name}>
                                                    {dash.dash_name}
                                                </option>
                                            ))}
                                    </optgroup>
                                    <optgroup label="Public Dashboards">
                                        {dashboards
                                            .filter((dash) => !dash.isPrivate)
                                            .map((dash) => (
                                                <option key={dash.hcode} value={dash.dash_name}>
                                                    {dash.dash_name}  ({dash.created_by})
                                                </option>
                                            ))}
                                    </optgroup>
                                </select>
                                <button className={`btn ${style['btn-style']}`} onClick={handleFetchButtonClick}>
                                    Fetch
                                </button>
                                <button className={`btn ${style['btn-style']}`} onClick={handleCreateButtonClick}>
                                    +
                                </button>
                                {/* <div><OverlayWithTabs></OverlayWithTabs></div> */}
                            </div>
                        </div>
                    }

                    {subscription.subscription === false &&
                        <div class="card text-center">
                            <div class="card-header">
                                Subscription
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Your subscription has expired on {formatDate(subscription.endDate)}</h5>
                                <p class="card-text">Access to Metso Digital Solution has expired. Please renew your subscription now.</p>
                                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                            </div>
                            <div class="card-footer text-body-secondary">
                                {subscription.daysUntilSubscriptionEnd} days ago
                            </div>
                        </div>
                    }

                    {showAlert &&
                        < div id="msg" className="w-50 text-center" >
                            <Alert data={responseData} ></Alert>
                        </div>
                    }

                    {dashboard &&
                        <div className='d-flex flex-row justify-content-between w-100 px-3 py-2' style={{ minHeight: '80vh' }}>
                            <UserViewer width={small ? '100%' : '61%'} height={'80vh'} dashboard={dashboard} setSelectedPart={setSelectedPart}></UserViewer>
                            <div className=' overflow-auto p-1' style={{ height: '80vh', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', width: small ? '100%' : '38%', }}>
                                <ul className='nav nav-tabs'>
                                    {access[0] && <li className='nav-item'>
                                        <a
                                            className={`nav-link ${activeTab === 'docs' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('docs')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Docs
                                        </a>
                                    </li>}
                                    {access[4] && <li className='nav-item'>
                                        <a
                                            className={`nav-link ${activeTab === 'Performance' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('Performance')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Asset Monitoring
                                        </a>
                                    </li>}
                                    {access[2] && <li className='nav-item'>
                                        <a
                                            className={`nav-link ${activeTab === 'purchase' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('purchase')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            eCatalogue
                                        </a>
                                    </li>}
                                    {access[3] && <li className={`nav-item`}>
                                        <a
                                            className={`nav-link ${activeTab === 'Maintenance' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('Maintenance')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Maintenance
                                        </a>
                                    </li>}
                                    {access[1] && <li className={`nav-item`}>
                                        <a
                                            className={`nav-link ${activeTab === 'Training' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('Training')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Training Module
                                        </a>
                                    </li>}
                                    <li className={`nav-item`}>
                                        <a
                                            className={`nav-link ${activeTab === 'Search' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('Search')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Search
                                        </a>
                                    </li>
                                    <li className={`nav-item`}>
                                        <a
                                            className={`nav-link ${activeTab === 'Properties' ? `${style.unique} active` : `${style['new-color']}`}`}
                                            onClick={() => toggleTab('Properties')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Properties
                                        </a>
                                    </li>
                                    {visible &&
                                        <li className='nav-item'>
                                            <a
                                                className={`nav-link ${activeTab === 'PDFViewer' ? `${style.unique} active` : `${style['new-color']}`}`}
                                                onClick={() => toggleTab('PDFViewer')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                PDFViewer
                                            </a>
                                        </li>
                                    }
                                </ul>
                                <div className='tab-content overflow-auto' style={{ Height: '80vh' }} >
                                    <div
                                        className={`tab-pane ${activeTab === 'docs' ? 'active' : ''}`}
                                        role='tabpanel'
                                    >
                                        {selectedPart &&
                                            <div className="d-flex gap-3 m-2">
                                                <button className={`btn ${activeDocSubTab === 'Local' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleDocSubTab('Local')}>{selectedPart ? asset_name : selectedAsset.aname}</button>
                                                <button className={`btn ${activeDocSubTab === 'Meridian' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleDocSubTab('Meridian')}>Connected Components</button>
                                            </div>
                                        }
                                        {/* {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Local' &&
                                            <>
                                                {
                                                    connectedDocs ?
                                                        <div>
                                                            <a className="p-1" style={{ cursor: 'pointer', textDecoration: 'none', backgroundColor: '#a6a6a6', color: '#000000' }} onClick={handleBackClick}>Go Back to {selectedPart ? asset_name : selectedAsset.aname}</a>
                                                            <br />
                                                            <UserConnectedFileListViewer selectedPart={selectedPart} connectedAssets={connectedAssets}></UserConnectedFileListViewer>
                                                        </div>
                                                        :
                                                        <div>
                                                            <a className="p-1" id="clickable" style={{ cursor: 'pointer' }} onClick={handleConnectedDocumentsClick}>
                                                                Click Me<img src={process.env.PUBLIC_URL + '/images/connected components.png'} height="40" alt='Connected Components' />
                                                            </a>
                                                            <Tooltip anchorSelect="#clickable" clickable place={'right'}>
                                                                <p>Click to view files of connected components</p>
                                                            </Tooltip>
                                                            <br />
                                                            <UserViewerListFiles selectedPart={selectedPart} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname} plantHcode={plantHcode}></UserViewerListFiles>
                                                        </div>
                                                }
                                            </>
                                        } */}
                                        {activeTab === 'docs' && activeDocSubTab === 'Local' &&
                                            <UserViewerListFiles selectedPart={selectedPart} connectedAssets={connectedAssets} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname} plantHcode={plantHcode} plantName={plantName}></UserViewerListFiles>
                                        }
                                        {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Meridian' &&
                                            <UserConnectedFileListViewer selectedPart={selectedPart} connectedAssets={connectedAssets}></UserConnectedFileListViewer>
                                        }
                                        {/* {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Meridian' && <UserViewerMeridianFiles selectedPart={selectedPart} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname}></UserViewerMeridianFiles>} */}
                                        {/* {!selectedPart && <h4 style={{ color: '#121212' }}>Select Part in Model</h4>} */}
                                        {/* Add your content for the 'Docs' tab here */}
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'Performance' ? 'active' : ''}`}
                                        role='tabpanel'
                                    >
                                        <div className="d-flex gap-3 m-2">
                                            <button className={`btn ${activeSubTab === 'Live' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleSubTab('Live')}>Live</button>
                                            <button className={`btn ${activeSubTab === 'Date' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleSubTab('Date')}>Date</button>
                                        </div>
                                        {
                                            activeSubTab === 'Date' ?

                                                <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                                                    <div className="input-group w-100 d-flex flex-column gap-2">
                                                        <div className='d-flex gap-2'>
                                                            <span className="input-group-text w-25">Date From</span>
                                                            <input
                                                                type="date"
                                                                aria-label="From date"
                                                                className="form-control w-75"
                                                                value={fromDate}
                                                                onChange={handleFromDateChange}
                                                                max={today}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='d-flex gap-2'>
                                                            <span className="input-group-text w-25 text-center">TO</span>
                                                            <input
                                                                type="date"
                                                                aria-label="To date"
                                                                className="form-control w-75"
                                                                value={toDate}
                                                                onChange={handleToDateChange}
                                                                max={today}
                                                                min={fromDate}
                                                                required
                                                            />
                                                        </div>
                                                        <button type="submit" className='btn '>FetchData</button>
                                                    </div>
                                                </form> : <> </>
                                        }

                                        {selectedPart && activeTab === 'Performance' ? <UserViewerSensors activeSubTab={activeSubTab} selectedPart={selectedPart} startDate={startDate} endDate={endDate} plantHcode={plantHcode} ></UserViewerSensors> : <h4 style={{ color: '#121212' }}>Select Part in Model</h4>}
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'purchase' ? 'active' : ''}`}
                                        role='tabpanel'
                                    >
                                        {selectedPart && activeTab === 'purchase' ? <UserViewerProcurement selectedPart={selectedPart} setActiveTab={setActiveTab} plantHcode={plantHcode}></UserViewerProcurement> : <h4 style={{ color: '#121212' }}>Select Part in Model</h4>}

                                        {/* Add your content for the 'Purchase' tab here */}
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'Maintenance' ? 'active' : ''}`}
                                        role='tabpanel'
                                    >
                                        {selectedPart && activeTab === 'Maintenance' ? <UserViewerMaintenance selectedPart={selectedPart} setActiveTab={setActiveTab} plantHcode={plantHcode}></UserViewerMaintenance> : <h4 style={{ color: '#121212' }}>Select Part in Model</h4>}
                                    </div>
                                    <div
                                        className={`tab-pane pt-2 ${activeTab === 'Training' ? 'active' : ''}`}
                                        role='tabpanel'
                                    >
                                        {activeTab === 'Training' && <div>
                                            <ul className="list-group">
                                                {pdfFiles.map((file, index) => (
                                                    <li key={index}
                                                        className='d-flex justify-content-between pe-2 list-group-item w-100  align-items-center'
                                                        style={{ color: '#121212', backgroundColor: 'transparent', borderColor: '#000000' }}>
                                                        <a href={`${process.env.PUBLIC_URL}/pdf/${file.name}`} style={{ color: '#121212', textDecoration: 'none' }}
                                                            target="_blank" rel="noopener noreferrer">
                                                            {file.label}
                                                        </a>
                                                        <a href={`${process.env.PUBLIC_URL}/pdf/${file.name}`} className={`${styles['btn-style']}   btn w-25`} download>
                                                            Download
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>}
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'PDFViewer' ? 'active' : ''}`}
                                        role='tabpanel'
                                        style={{ minHeight: '55vh', width: '100%' }}
                                    >
                                        <PDFViewer fname={fname}></PDFViewer>
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'Search' ? 'active' : ''}`}
                                        role='tabpanel'
                                        style={{ minHeight: '55vh', width: '100%' }}
                                    >
                                        <UserSearchComponent plantHcode={plantHcode} selectedZone={selectedZone}></UserSearchComponent>
                                    </div>
                                    <div
                                        className={`tab-pane ${activeTab === 'Properties' ? 'active' : ''}`}
                                        role='tabpanel'
                                        style={{ minHeight: '55vh', width: '100%' }}
                                    >
                                        <UserViewerProperties selectedPart={selectedPart} plantHcode={plantHcode} ></UserViewerProperties>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Footer screenCode={screenCode} />
            </Wrapper >
            {showModal &&
                <DashboardModal
                    accountHCode={accountHCode}
                    showModal={showModal}
                    closeModal={closeModal}
                    setChange={setChange}
                    setResponseData={setResponseData}
                    setShowAlert={setShowAlert}
                />
            }
        </>
    );
};

export default HomePage;
