import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import { format } from "date-fns";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchData = async (data, hcode) => {
    const response = await fetch(`${SERVER_URL}/account/update/${hcode}?hcode=${localStorage.getItem('hcode')}`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

const AccountModal = ({ account, showModal, closeModal, setChange }) => {
    const [accountData, setAccountData] = useState(account);

    useEffect(() => {
        setAccountData(account);
    }, [account]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setAccountData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSaveChanges = async () => {
        const response = await fetchData(accountData, accountData.hcode);
        const data = await response.json();
        closeModal();
        setChange(prev => !prev);
    };

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const formattedDate = format(date, 'dd-MM-yyyy');
    //     return formattedDate;
    // };

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle cases where dateString is undefined or null
    
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return ''; // Handle cases where dateString is not a valid date
    
        // Extract year, month, and day components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
    
        // Return formatted date as 'yyyy-MM-dd' which is accepted by HTML date input
        return `${year}-${month}-${day}`;
    };
    

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Edit Account Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="acct_name">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="acct_name"
                            value={accountData.acct_name}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="start_date">
                        <Form.Label>Subscription Start Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="start_date"
                            value={formatDate(accountData.start_date)}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="end_date">
                        <Form.Label>Subscription End Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="end_date"
                            value={formatDate(accountData.end_date)}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="created_by">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_by"
                            value={accountData.created_by}
                            disabled
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AccountModal;
