import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import MaintenanceModal from '../../modal/MaintenanceModal';
import MaintenanceEditModal from '../../modal/MaintenanceEditModal';
import styles from '../../../common/common.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewerMaintenance(prop) {
    const { t } = useTranslation();
    const [spares, setSpares] = useState([]);
    const [showMaintenanceHistory, setShowMaintenanceHistory] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedSpare, setSelectedSpare] = useState(null);
    const [hcode, setHcode] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [change, setChange] = useState(false);
    const [addedSpare, setAddedSpare] = useState(false);
    const { selectedPart, setActiveTab } = prop;
    const [aliase, setaliase] = useState(null);
    const { plantHcode, plant_name } = useParams();
    const tableRef = useRef(null);

    const closeModal = () => {
        setShowModal(false);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    // Fetch asset name based on the selected part
    const fetchAssetName = async () => {
        try {
            let part_name = '';

            // Fetch part name from the NOP_VIEWER
            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            fetchEquipment(part_name);
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch equipment details based on part name
    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('hcode'));

        try {
            const resp = await fetch(`${SERVER_URL}/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString());
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.equipment.aname);
                setHcode(data.equipment.hcode);
                fetchSpares(data.equipment.hcode, data.type);
                fetchAsset(data.equipment.hcode, data.type);
            } else {
                setHcode(null);
                setShowMaintenanceHistory(false);
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch asset details
    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                    if (data.alias_name !== '') {
                        setaliase(data.alias_name);
                    } else {
                        setaliase(null);
                    }
                } else {
                    setShowMaintenanceHistory(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Fetch spares based on the hash code
    const fetchSpares = async (hashCode, type) => {
        if (hashCode) {
            try {
                const response = await fetch(`${SERVER_URL}/spares/${hashCode}?hcode=${localStorage.getItem('hcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    if (data.spares.length === 0) {
                        setSpares([{
                            "hcode": "7f89f6881505c09ecff844452c82c10542d3fcbf5eab",
                            "spare_name": "Flange",
                            "spare_replacement_date": "2024-01-02T00:00:00.000Z",
                            "quantity": 6,
                            "quantity_unit": "Pieces",
                            "created_by": "email@email.com",
                            "created_on": "2024-01-30T10:26:23.000Z",
                            "comments": "Periodic Maintenance.",
                            "aname": "/HH51-AU001"
                        },
                        {
                            "hcode": "ae8be2c22bc08c8ebeec3a36700412da9b617e502215",
                            "spare_name": "Bolt",
                            "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                            "quantity": 74,
                            "quantity_unit": "Pieces",
                            "created_by": "email@email.com",
                            "created_on": "2024-01-30T10:26:50.000Z",
                            "comments": "Periodic Maintenance- Extra 10 Pieces Placed at Inventory Record",
                            "aname": "/HH51-AU001"
                        },
                        {
                            "hcode": "45110e7f8b714dd57f536a923be94063ea235683fd8a",
                            "spare_name": "Gasket",
                            "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                            "quantity": 1,
                            "quantity_unit": "Pieces",
                            "created_by": "email@email.com",
                            "created_on": "2024-01-30T10:27:13.000Z",
                            "comments": "Periodic Maintenance.",
                            "aname": "/HH51-AU001"
                        },
                        {
                            "hcode": "82d4165478628c621321591b102505cb5601381dc252",
                            "spare_name": "Weld Neck Flange- WNF(#600)",
                            "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                            "quantity": 4,
                            "quantity_unit": "Pieces",
                            "created_by": "email@email.com",
                            "created_on": "2024-01-30T10:27:38.000Z",
                            "comments": "Periodic Maintenance.",
                            "aname": "/HH51-AU001"
                        }]);
                    } else {
                        setSpares(data.spares);
                    }
                    setShowMaintenanceHistory(true);
                } else {
                    setSpares([{
                        "hcode": "7f89f6881505c09ecff844452c82c10542d3fcbf5eab",
                        "spare_name": "Flange",
                        "spare_replacement_date": "2024-01-02T00:00:00.000Z",
                        "quantity": 6,
                        "quantity_unit": "Pieces",
                        "created_by": "email@email.com",
                        "created_on": "2024-01-30T10:26:23.000Z",
                        "comments": "Periodic Maintenance.",
                        "aname": "/HH51-AU001"
                    },
                    {
                        "hcode": "ae8be2c22bc08c8ebeec3a36700412da9b617e502215",
                        "spare_name": "Bolt",
                        "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                        "quantity": 74,
                        "quantity_unit": "Pieces",
                        "created_by": "email@email.com",
                        "created_on": "2024-01-30T10:26:50.000Z",
                        "comments": "Periodic Maintenance- Extra 10 Pieces Placed at Inventory Record",
                        "aname": "/HH51-AU001"
                    },
                    {
                        "hcode": "45110e7f8b714dd57f536a923be94063ea235683fd8a",
                        "spare_name": "Gasket",
                        "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                        "quantity": 1,
                        "quantity_unit": "Pieces",
                        "created_by": "email@email.com",
                        "created_on": "2024-01-30T10:27:13.000Z",
                        "comments": "Periodic Maintenance.",
                        "aname": "/HH51-AU001"
                    },
                    {
                        "hcode": "82d4165478628c621321591b102505cb5601381dc252",
                        "spare_name": "Weld Neck Flange- WNF(#600)",
                        "spare_replacement_date": "2024-01-29T00:00:00.000Z",
                        "quantity": 4,
                        "quantity_unit": "Pieces",
                        "created_by": "email@email.com",
                        "created_on": "2024-01-30T10:27:38.000Z",
                        "comments": "Periodic Maintenance.",
                        "aname": "/HH51-AU001"
                    }]);
                    setShowMaintenanceHistory(false);
                    setLoad(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Fetch asset name when the selected part changes
    useEffect(() => {
        fetchAssetName();
    }, [selectedPart]);

    // Fetch spares when hcode, change, or addedSpare changes
    useEffect(() => {
        fetchSpares(hcode);
    }, [change, addedSpare]);

    // Initialize and destroy DataTable when spares change
    useEffect(() => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        if (spares.length > 0) {
            setShowMaintenanceHistory(true);
            const dataTable = $(tableRef.current).DataTable({
                destroy: true,
                language: t('dataTable', { returnObjects: true })
            });
            const searchInput = $(tableRef.current).closest('.dataTables_wrapper').find('input[type="search"]');
            searchInput.css('left', '-6px');
            searchInput.css('position', 'relative');
            dataTable.draw();
        }
        else {
            setShowMaintenanceHistory(false);
        }
    }, [spares]);

    // Handle adding a new spare part
    const handleAddSpare = () => {
        setShowModal(true);
    }

    // Handle when a spare part is added
    const handleAddedSpare = () => {
        setAddedSpare(prev => !prev);
    }

    // Handle editing a spare part
    const handleEditSpare = (spare) => {
        setSelectedSpare(spare);
        setShowEditModal(true);
    }

    return (
        <>
            {hcode &&
                <>
                    <button className={`${styles['btn-style']} btn offset-9 w-25`} onClick={handleAddSpare}>
                        {t('addSpare')}
                    </button>
                    <br />
                </>
            }
            {spares.length > 0 ?
                <div id="content" className='w-100' style={{ maxWidth: '100%' }}>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>
                        {t('sparePartHistoryOf')} {asset_name}{aliase && `(${aliase})`}
                    </div>
                    <span className='fw-bold fs-5' style={{ color: '#121212' }}>
                        {t('link')} :
                    </span>
                    <Link className={`${styles['btn-style']} btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>
                        {t('sparePartList')}
                    </Link>
                    <br />
                    <div className="ps-2">
                        <div className="w-100 overflow" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('spareName')}</th>
                                        <th>{t('replacementDate')}</th>
                                        <th>{t('quantity')}</th>
                                        <th>{t('createdOn')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {spares.map((spare) => (
                                        <tr key={spare.hcode} className={`${styles['row-hover']}`}>
                                            <td style={{ cursor: 'pointer' }} onDoubleClick={() => handleEditSpare(spare)}>
                                                {spare.spare_name}
                                            </td>
                                            <td>{spare.spare_replacement_date.split('T')[0]}</td>
                                            <td>{spare.quantity + ' ' + spare.quantity_unit}</td>
                                            <td>{spare.created_on.split('T')[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                : <>
                    {load ?
                        <div id="content" className='w-100'>
                            <div className="ps-2">
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>
                                    {t('loading')}...
                                </div>
                            </div>
                        </div>
                        :
                        <div id="content" className='w-100 mt-1'>
                            <div className="ps-2 ">
                                <span className='fw-bold fs-5' style={{ color: '#121212' }}>
                                    {t('link')} :
                                </span>
                                <Link className={`${styles['btn-style']} btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>
                                    {t('sparePartList')}
                                </Link>
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>
                                    {t('nothingToShow')}
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            {showModal &&
                <MaintenanceModal
                    setActiveTab={setActiveTab}
                    hcode={hcode}
                    closeModal={closeModal}
                    showModal={showModal}
                    handleAddedSpare={handleAddedSpare}
                />
            }
            {showEditModal &&
                <MaintenanceEditModal
                    spare={selectedSpare}
                    closeEditModal={closeEditModal}
                    showEditModal={showEditModal}
                    setChange={setChange}
                />
            }
        </>
    );
}

export default ViewerMaintenance;
