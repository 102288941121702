import React, { useState } from "react";
import styles from './LoginPage.module.css';
import Form from '../../components/loginpage/Form'
import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import styled from c



function LoginPage() {

    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('hcode') !== null || isLogin === true) {

            navigate('/dashboard');
        }
    }, [isLogin, navigate]);

    return (
        <Wrapper>
            <Header />
            <div className={styles['div-style']}>
                {/* <div className="justify-content-start"><img className="mb-4 mt-2" src={process.env.PUBLIC_URL + '/images/logo.png'} alt="some text" style={{ maxWidth: '80%' }}
                    height="57" />
                </div> */}
                <Form />
            </div>
            <Footer />
        </Wrapper>
    );
}

export default LoginPage;