import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import style from './style/modal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const AliasModal = ({ showModal, closeModal, asset }) => {
    const [ aliasName, setAliasName] = useState('');
    const [adesc, setAdesc] = useState('');
    const navigate = useNavigate();

    const handleKeyChange = (event) => {
        setAliasName(event.target.value.trim());
    }

    const handleValueChange = (event) => {
        setAdesc(event.target.value.trim());
    }

    const handleSubmit = () => {
        if (aliasName === '' || adesc === '') {
            alert('Please enter Alias Name and Asset Description.');
            return;
        }
        else{
            asset.alias_name = aliasName;
            asset.adesc = adesc;
            closeModal();
        }

        // fetch(`${SERVER_URL}/property/create?hcode=${localStorage.getItem('hcode')}`, {

        //     method: 'POST',
        //     body: JSON.stringify({
        //         pkey: key, pvalue: value, entity_hcode, entity_type
        //     }),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }).then(response => response.json())
        //     .then(data => {
        //         if (data.statusCode === 200) {
        //             closeModal();
        //             alert(data.message);
        //             setChange((prev)=>!prev);
        //         } else {
        //             alert(data.message);
        //         }
        //     })

    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Add Alias</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="alias_name">
                        <Form.Label>Asset Alias Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="alias_name"
                            defaultValue={asset.alias_name}
                            onChange={handleKeyChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="adesc">
                        <Form.Label>Asset Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="adesc"
                            defaultValue={asset.adesc}
                            onChange={handleValueChange}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};




export default AliasModal;