import UserHeader from '../../components/header/UserHeader';
import Wrapper from '../../components/wrapper/wrapper';
import Footer from '../../components/footer/Footer';
import * as GC from '@grapecity/spread-sheets'; // Importing SpreadJS
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css'; // Importing SpreadJS styles
import * as GCExcel from '@grapecity/spread-excelio'; // Import Excel I/O
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function DashUserPageExcel({ screenCode }) {
    const { fname } = useParams();

    const spreadRef = useRef(null);

    useEffect(() => {
        let isMounted = true; // Flag to ensure effect is running only when component is mounted
        const loadExcelData = async (fname) => {
            try {
                const response = await fetch(
                    `${SERVER_URL}/user/assetfile/${fname}?hcode=${localStorage.getItem('userhcode')}`
                );

                const fileBlob = await response.blob();
                const arrayBuffer = await fileBlob.arrayBuffer();

                if (isMounted) {
                    const spread = new GC.Spread.Sheets.Workbook(spreadRef.current);
                    const excelIO = new GCExcel.IO();

                    excelIO.open(arrayBuffer, (json) => {
                        if (isMounted) spread.fromJSON(json); // Ensure component is mounted before updating
                    }, (error) => {
                        console.error('Error loading Excel data:', error);
                    });
                }
            } catch (error) {
                console.error('Error fetching Excel data:', error);
            }
        };

        loadExcelData(fname); // Call the function

        return () => {
            isMounted = false; // Clean-up function
        };
    }, []); // Only run once on mount


    return (
        <Wrapper>
            <UserHeader />
            <div id="content" className='w-100' style={{ height: "86vh" }}>
                <div ref={spreadRef} className="m-4" style={{ height: "93%", width: "95%" }}></div>
            </div>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashUserPageExcel;