import { useEffect } from 'react';
import { initViewer, loadModel } from './helpViewer';
import { useLocation } from 'react-router-dom';
import './viewer.css';

/**
 * Initialize the container for the model to view in web browser
 */
const Viewer = (props) => {

    const { width, height } = props;

    // Get the current location object
    const location = useLocation();
    // Parse URL search parameters
    const params = new URLSearchParams(location.search);
    // Get the 'urn' parameter from the URL
    const urn = params.get('urn');

    useEffect(() => {
        initViewer(document.getElementById('viewer')).then((viewer) => {
            loadModel(viewer, urn);
        })

    }, [])
    return <div id="viewer" className='mb-2 mt-2 ms-2' style={{ position: 'relative', width: width, height: height, 'box-shadow': 'rgba(0, 0, 0, 0.4) 0px 0px 0px 2px, rgba(0, 0, 0, 0.65) 0px 4px 6px -1px, rgba(0, 0, 0, 0.08) 0px 1px 0px inset' }} />;
};


export default Viewer;
