import React, { useState, useRef } from 'react';
import styles from '../../common/common.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ModelUploadButton = (prop) => {
  const { setChange, setUploadModel } = prop;
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      const selectedFile = fileInputRef.current.files[0];
      setUploading(true);
      setUploadModel(true);
      const formData = new FormData();
      formData.append('model-file', new Blob([selectedFile]));
      formData.append('filename', selectedFile.name);  
      formData.append('plant_hcode', prop.hcode);  

      fetch(`${SERVER_URL}/api/upload?hcode=${localStorage.getItem('hcode')}`, {
        method: 'POST',
        body: formData
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          setChange(prev => !prev);
        })
        .catch((error) => {
          console.log(error);
          // Handle the error
        })
        .finally(() => {
          setUploading(false);
          setUploadModel(false);
          // Clear the file input after upload
          fileInputRef.current.value = null;
        });
    }
  };

  return (
    <div>
      <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
      <button className={`${styles['btn-style']}   btn`} style={{ lineHeight: "1" }} onClick={() => fileInputRef.current.click()} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload Model'}
      </button>
    </div>
  );
};

export default ModelUploadButton;
