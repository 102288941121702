import Chart from "react-google-charts";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function GraphSensors(prop) {

    const { sname, shcode, startDate, endDate, showModal, setShowModal } = prop;

    const startTime = new Date(`${startDate}T00:00:00Z`);
    const endTime = new Date(`${endDate}T23:59:59Z`);

    // const [showModal, setShowModal] = useState(false);
    const [sensorData, setSensorData] = useState([]);
    const [data, setData] = useState([]);
    const [avg, setAvg] = useState(0);
    const [unit, setUnit] = useState('');


    const fetchSensors = async (shcode) => {
        if (shcode) {
            const response = await fetch(`${SERVER_URL}/sensorsData/${shcode}?hcode=${localStorage.getItem('hcode')}&startDate=${startDate}&endDate=${endDate}`);
            const data = await response.json();
            setUnit(data.unit);
            setSensorData(data.sensorData);
        }
    };

    useEffect(() => {
        fetchSensors(shcode);
        // console.log(startDate, endDate);
    }, [startDate, endDate]);



    useEffect(() => {
        let total = 0;
        const data = sensorData.map(([time, date, value], index) => {
            if (index === 0) {
                return [{ type: "datetime", label: "x" }, 'Value']
            }
            total += value;
            return [new Date(`${date}T${time}Z`), value];
        })

        data.sort((a, b) => a[0] - b[0]);

        setData(data);
        setAvg((total / (sensorData.length - 1)).toFixed(2));

    }, [sensorData]);

    // const data = [

    //     [{ type: "datetime", label: "x" }, "some"],
    //     ["2023-06-02T02:00:40Z", 1.56098356172],
    //     ["2023-06-01T01:00:34Z", 3.1443309575507],
    //     ["2023-06-02T03:00:36Z", 5.2833104799442],
    //     ["2023-06-01T05:00:38Z", 2.4221933706297],
    //     ["2023-06-01T02:00:40Z", 6.56098356172],
    //     ["2023-06-01T01:00:40Z", 8.56098356172],

    //   ];

    const options = {
        hAxis: {
            title: 'Time',
            format: 'YYYY-MM-dd hh:mm:ss',
            viewWindow: {
                min: startTime,
                max: endTime,
            },
        },
        vAxis: {
            title: `Value in ${unit}`,
        },
        legend: { position: 'bottom', alignment: 'start' },
    }



    const optionsModal = {
        hAxis: {
            title: 'Time',
            format: 'YYYY-MM-dd hh:mm:ss',
            viewWindow: {
                min: startTime,
                max: endTime,
            },

        },
        vAxis: {
            title: `Value in ${unit}`,
        },
        legend: { position: 'bottom', alignment: 'start' },
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 4.0
        },
    }


    const downloadCsv = () => {
        const csvData = sensorData.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${sname}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const handleChartClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    return (<>
        {/* <div onClick={
            handleChartClick
        }>
            <Chart
                chartType="LineChart"
                data={data}
                options={options}
            />
        </div> */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{sname}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="card-subtitle text-muted">Average Value: {avg}</h6>
                    <Button variant="secondary" onClick={downloadCsv}>
                        Download CSV
                    </Button>
                </div>
                <Chart
                    chartType="LineChart"
                    data={data}
                    options={optionsModal}
                    width={"100%"}
                    height={400}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
}


export default GraphSensors;