import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ConnectedFileListViewer(props) {
    const { t } = useTranslation();
    const [groupedFiles, setGroupedFiles] = useState({});
    const [load, setLoad] = useState(false);
    const { connectedAssets } = props;
    const { plantHcode } = useParams();
    const [docTypes, setDocTypes] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const docTables = {
        '11': useRef(null),
        '12': useRef(null),
        '21': useRef(null),
        '22': useRef(null),
        '31': useRef(null),
        '41': useRef(null)
    };

    useEffect(() => {
        fetchFiles();
        GetDocTypes();
    }, [connectedAssets]);

    useEffect(() => {
        initializeDataTable(activeTab);
    }, [activeTab, connectedAssets]);

    const fetchFiles = async () => {
        try {
            let allFiles = [];
            for (const hcode of connectedAssets) {
                setLoad(true);
                const response = await fetch(`${SERVER_URL}/assetFiles/${hcode.hcode}/${hcode.type}?hcode=${localStorage.getItem('hcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    allFiles = [...allFiles, ...data.assetFiles];
                } else {
                    setLoad(false);
                }
            }
            const resultFiles = groupFiles(allFiles);
            setGroupedFiles(resultFiles);
        } catch (error) {
            console.error('Error fetching files:', error);
            setLoad(false);
        }
    };

    const GetDocTypes = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/document/types?hcode=${localStorage.getItem('hcode')}`);
            const data = await response.json();
            if (data.statusCode === 200) {
                setDocTypes(data.docTypes);
            }
        } catch (error) {
            console.error('Error fetching document types:', error);
        }
    };

    const initializeDataTable = (tabIndex) => {
        Object.keys(docTables).forEach(key => {
            if (parseInt(key) === tabIndex) {
                $(docTables[key].current).DataTable().destroy();
                $(docTables[key].current).DataTable({
                    destroy: true,
                    language: t('dataTable', { returnObjects: true })
                });
            }
        });
    };

    const handleView = async (fname) => {
        try {
            const fileExtension = fname.split('.').pop().toLowerCase();
            const response = await fetch(`${SERVER_URL}/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('hcode')}`);
            const fileBlob = await response.blob();
            const fileUrl = URL.createObjectURL(fileBlob);

            if (fileExtension === 'pdf' || ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
                window.open(fileUrl, '_blank');
            } else if (['xlsx', 'xls'].includes(fileExtension)) {
                window.open(`/excel/viewer/${fname}`, '_blank');
            } else {
                alert("Unsupported file type for viewing.");
            }
        } catch (error) {
            console.error('Error viewing file:', error);
        }
    };

    const handleDownloadFiles = async (fname) => {
        try {
            const response = await fetch(`${SERVER_URL}/assetfile/${fname.split('|')[0]}?hcode=${localStorage.getItem('hcode')}`);
            const file = await response.blob();
            const url = URL.createObjectURL(file);
            const filenameParts = fname.split('|');
            const originalName = filenameParts[1];
            const link = document.createElement('a');
            link.href = url;
            link.download = originalName;
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const groupFiles = (files) => {
        return files.reduce((acc, file) => {
            const typeId = file.doc_code;
            acc[typeId] = acc[typeId] || [];
            acc[typeId].push(file);
            return acc;
        }, {});
    };

    return (
        <div id="content" className='w-100'>
            {connectedAssets.length > 0 ? (
                <div className="mt-3 ps-2">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {docTypes.map((item, index) => (
                            <li className="nav-item" key={item.hcode}>
                                <button
                                    className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {item.doc_type}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {docTypes.map((item, index) => (
                            <div
                                key={item.doc_code}
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                            >
                                {groupedFiles[item.doc_code]?.length > 0 ? (
                                    <table className={`table ${styles.table} table-sm table-bordered`} ref={docTables[item.doc_code]}>
                                        <thead>
                                            <tr className={`${styles['border-top-bottom']}`}>
                                                <th>{t('tagName')}</th>
                                                <th>{t('fileName')}</th>
                                                <th>{t('actions')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedFiles[item.doc_code].map((file, idx) => (
                                                <tr key={idx} className={`${styles['row-hover']}`}>
                                                    <td>{file.tag}</td>
                                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => handleView(file.fname.split('|')[0])}>
                                                        {file.fname.split('|')[1]}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className={`${styles['btn-style']} btn me-2`}
                                                            onClick={() => handleView(file.fname.split('|')[0])}
                                                        >
                                                            {t('open')}
                                                        </button>
                                                        <span onClick={() => handleDownloadFiles(file.fname)} style={{ cursor: 'pointer' }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                            </svg>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className='p-3'>{t('noFilesAvailable')}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div id="content" className='w-100'>
                    <h2 className='p-2'>{t('noConnectedComponents')}</h2>
                </div>
            )}
        </div>
    );
}

export default ConnectedFileListViewer;
