import React, { useState } from "react";
import Alert from '../alert/Alert';
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from './../../common/common.module.css';
import { useTranslation } from "react-i18next";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Component for creating a new account.
 * Allows input for account name, description, subscription start date, and subscription end date.
 * Submits the data to the server and shows an alert based on the server response.
 */
function CreateAccountForm() {
    const { t } = useTranslation();
    const [acct_name, setAcct_name] = useState('');
    const [acct_desc, setAcct_desc] = useState('');
    const [sub_start_date, setSubStartDate] = useState('');
    const [sub_end_date, setSubEndDate] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    /**
     * Handles changes in the account name input field.
     * @param {object} event - The event object containing the input value.
     */
    const handleAcct_name = (event) => {
        setAcct_name(event.target.value.trim())
    }

    /**
     * Handles changes in the account description textarea.
     * @param {object} event - The event object containing the input value.
     */
    const handleAcct_desc = (event) => {
        setAcct_desc(event.target.value.trim())
    }

    /**
     * Handles changes in the subscription start date input field.
     * @param {object} event - The event object containing the input value.
     */
    const handleSubStartDate = (event) => {
        setSubStartDate(event.target.value)
    }

    /**
     * Handles changes in the subscription end date input field.
     * @param {object} event - The event object containing the input value.
     */
    const handleSubEndDate = (event) => {
        setSubEndDate(event.target.value)
    }

    const navigate = useNavigate(); // Provides navigation functionality

    /**
     * Handles form submission.
     * Sends a POST request to the server to create a new account with the provided data.
     * Shows an alert based on server response and navigates to the home page if successful.
     * @param {object} event - The form submit event.
     */
    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior

        // Validate required fields
        if (!acct_name || !sub_start_date || !sub_end_date) {
            return;
        }

        fetch(`${SERVER_URL}/accounts/create?hcode=${localStorage.getItem('hcode')}`, {
            method: 'POST',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({
                acct_name: acct_name,
                acct_desc: acct_desc,
                sub_start_date: sub_start_date,
                sub_end_date: sub_end_date
            }),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()) // Parse the response body as JSON
            .then(data => {
                setResponseData(data); // Set response data for displaying alert
                setShowAlert(true); // Show alert component
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/`); // Navigate to home page if successful
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000); // Hide alert after 2 seconds
            })
            .catch(error => console.error(error)); // Log any errors to the console
    }

    // Render JSX
    return (
        <>
            <div className="w-100 mt-3" id="content">
                <div id="msg">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
                    <form method="post" className="w-50" onSubmit={handleFormSubmit}>
                        <h1 className="h1 mb-3 fw-normal" style={{ color: '#121212', fontSize: '600' }}>{t('createAccount')}</h1>
                        <div className="form-floating">
                            <input type="text" className="form-control" name="acct_name" placeholder="name@example.com" onChange={handleAcct_name} required="" />
                            <label htmlFor="acct_name">{t('accountName')}</label>
                        </div>
                        <br />
                        <div className="form-floating mb-2">
                            <textarea className="form-control" id="floatingTextarea" name="acct_desc" rows="3" onChange={handleAcct_desc} placeholder="Leave a comment here" style={{ height: 'fit-content' }}></textarea>
                            <label htmlFor="acct_desc">{t('accountDescription')}</label>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-2">
                                    <input type="date" className="form-control" name="sub_start_date" onChange={handleSubStartDate} required="" />
                                    <label htmlFor="sub_start_date">{t('subscriptionStartDate')}</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-2">
                                    <input type="date" className="form-control" name="sub_end_date" onChange={handleSubEndDate} required="" />
                                    <label htmlFor="sub_end_date">{t('subscriptionEndDate')}</label>
                                </div>
                            </div>
                        </div>
                        <button className={`w-100 btn btn-lg ${styles['btn-style']}`} type="submit">{t('createAccount')}</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CreateAccountForm;
