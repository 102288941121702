import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import { useNavigate } from 'react-router-dom';
import styles from './../../common/common.module.css';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Component for creating a new plant.
 * Allows input for plant name, description, selecting an account, and selecting modules.
 * Submits the data to the server and shows an alert based on the server response.
 */
function CreatePlantForm() {
    const { t } = useTranslation();
    const [plant_name, setPlant_name] = useState('');
    const [plant_desc, setPlant_desc] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);

    const navigate = useNavigate(); // Provides navigation functionality

    /**
     * Handles changes in the plant name input field.
     * @param {object} event - The event object containing the input value.
     */
    const handlePlant_name = (event) => {
        setPlant_name(event.target.value.trim());
    }

    /**
     * Handles changes in the plant description textarea.
     * @param {object} event - The event object containing the input value.
     */
    const handlePlant_desc = (event) => {
        setPlant_desc(event.target.value.trim());
    }

    /**
     * Handles changes in the selected account dropdown.
     * @param {object} event - The event object containing the selected value.
     */
    function handleOptionChange(event) {
        setSelectedUser(event.target.value.trim());
    }

    /**
     * Handles changes in the selected modules.
     * @param {array} selectedValues - The selected module options.
     */
    const handleChange = (selectedValues) => {
        setSelectedOptions(selectedValues);
    };

    /**
     * Fetches the list of available modules from the server and sets the options for the Select component.
     */
    const listModules = async () => {
        const response = await fetch(`${SERVER_URL}/modules?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            const modules = data.modules;
            const options = modules.map(module => ({
                value: module.mname,
                label: module.mname
            }));
            setOptions(options);
        }
    }

    useEffect(() => {
        listModules();
    }, []);

    /**
     * Fetches the list of available accounts from the server and sets the accounts state.
     */
    useEffect(() => {
        fetch(`${SERVER_URL}/listOfAccounts?hcode=${localStorage.getItem('hcode')}`)
            .then(response => response.json())
            .then(data => {
                setAccounts(data.accounts);
            })
            .catch(error => console.error(error));
    }, []);

    /**
     * Handles form submission.
     * Sends a POST request to the server to create a new plant with the provided data.
     * Shows an alert based on server response and navigates to the plants page if successful.
     * @param {object} event - The form submit event.
     */
    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents default form submission behavior

        fetch(`${SERVER_URL}/plants/create?hcode=${localStorage.getItem('hcode')}`, {
            method: 'POST',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({
                acct_name: selectedUser,
                pdesc: plant_desc,
                plant_name: plant_name,
                modules: selectedOptions
            }),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()) // Parse the response body as JSON
            .then(data => {
                setResponseData(data); // Set response data for displaying alert
                setShowAlert(true); // Show alert component
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/plants`); // Navigate to plants page if successful
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000); // Hide alert after 2 seconds
            })
            .catch(error => console.error(error)); // Log any errors to the console
    }

    // Render JSX
    return (
        <>
            <div className="w-100 mt-3" id="content">
                <div id="msg">
                    {showAlert && <Alert data={responseData} />}
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
                    <form method="post" className="w-50" onSubmit={handleFormSubmit}>
                        <h1 className="h1 mb-3 fw-normal" style={{ color: '#121212', fontSize: '600' }}>{t('createPlant')}</h1>
                        <div className="form-floating">
                            <input type="text" className="form-control" name="plant_name" placeholder="name@example.com" onChange={handlePlant_name} required />
                            <label htmlFor="plant_name">{t('plantName')}</label>
                        </div>
                        <br />
                        <div className="form-floating mb-2">
                            <textarea className="form-control" id="floatingTextarea" name="acct_desc" rows="3" onChange={handlePlant_desc} placeholder="Leave a comment here" style={{ height: 'fit-content' }}></textarea>
                            <label htmlFor="plant_desc">{t('plantDesc')}</label>
                        </div>
                        <div className="form-floating">
                            <select value={selectedUser} onChange={handleOptionChange} className="form-select">
                                <option value="">{t('selectAccount')}</option>
                                {accounts.map(({ acct_name }) => (
                                    <option key={acct_name} value={acct_name}>
                                        {acct_name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="user_id">{t('accounts')}</label>
                        </div>
                        <br />
                        <div className="border p-3 rounded" style={{ borderColor: '#e0e4e7' }}>
                            <label htmlFor="user_id">{t('module')}</label>
                            <Select
                                isMulti
                                options={options}
                                value={selectedOptions}
                                onChange={handleChange}
                            />
                        </div>
                        <br />
                        <button className={`w-100 btn btn-lg ${styles['btn-style']}`} type="submit">{t('createPlant')}</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CreatePlantForm;
