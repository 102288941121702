import React from "react";

/**
 * Alert component to display success or error messages based on authentication status.
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data object containing the statusCode and message.
 * @param {number} props.data.statusCode - The status code indicating the result of an operation.
 * @param {string} props.data.message - The message to be displayed in the alert.
 * @returns {JSX.Element} The JSX to render the alert component.
 */
function Alert(props) {
    // Variable to track if the user is authenticated
    let isAuthenticated = false;

    // Check if the statusCode from props is 200 to set isAuthenticated to true
    if (props.data.statusCode === 200) {
        isAuthenticated = true;
    }

    // Return the JSX to render the alert div
    // Conditional className based on isAuthenticated value
    return (
        <div className={"alert alert-dismissible fade show" + (isAuthenticated ? ' alert-success' : ' alert-danger')}>
            {/* Display the message from props.data */}
            <h3>{props.data.message}</h3>
            {/* Close button for the alert */}
            <button className="btn-close pb-2" aria-label="close" data-bs-dismiss="alert">
            </button>
        </div>
    );
}

export default Alert;
