import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery for DOM manipulation
import 'datatables.net-bs5'; // Import DataTables for table enhancements
import styles from '../../../common/common.module.css'; // Import CSS modules for styling
import '../../../common/Datatable.css'; // Additional stylesheet for DataTables

import ListProperties from '../propertyTable/PropertyTable'; // Import other components
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import { format } from "date-fns"; // Import date-fns for date formatting utilities
import AccountModal from '../../modal/AccountModal';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations

const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

/**
 * Component for listing accounts and managing their data.
 * Uses DataTables for table rendering and navigation hooks for routing.
 */
function ListAccounts() {
    const { t } = useTranslation(); // Translation function
    const [accounts, setAccounts] = useState([]); // State to hold accounts data
    const [selectedAccount, setSelectedAccount] = useState(null); // State to manage selected account for modal
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [change, setChange] = useState(true); // State to trigger data fetching

    const navigate = useNavigate(); // Navigation hook from react-router-dom
    const tableRef = useRef(null); // Ref to hold reference to the table DOM element

    /**
     * Effect to fetch accounts data when 'change' state updates.
     * Fetches accounts from the server based on localStorage hcode.
     * Handles authentication errors by redirecting to login page.
     */
    useEffect(() => {
        fetchAccounts();
    }, [change]);

    /**
     * Function to fetch accounts data from the server.
     * Updates accounts state with fetched data or handles authentication error.
     */
    const fetchAccounts = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/accounts?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
            const data = await response.json();

            if (data.statusCode === 200) {
                setAccounts(data.accounts); // Update accounts state with fetched data
            } else if (data.statusCode === 401) {
                localStorage.removeItem("hcode"); // Clear invalid hcode from local storage
                navigate('/login'); // Redirect to login page on authentication error
            }
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    /**
     * Effect to initialize DataTables when 'accounts' state updates.
     * Destroys previous DataTable instance and creates a new one with localized language settings.
     */
    useEffect(() => {
        if (accounts.length > 0) {
            $(tableRef.current).DataTable({
                destroy: true, // Destroy previous instance if exists
                language: t('dataTable', { returnObjects: true }) // Localized language settings
            });
        }
    }, [accounts, t]);

    /**
     * Function to handle click on 'View Users' button.
     * Navigates to user list page for the selected account.
     * Additional logic for handling view users button click can be added here.
     * @param {string} acctName - Account name
     */
    const handleViewUsersClick = (acctName) => {
        navigate(`/users/${acctName}`);
    };

    /**
     * Function to format date string using date-fns.
     * @param {string} dateString - Date string from server
     * @returns {string} Formatted date in 'dd-MM-yyyy' format
     */
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd-MM-yyyy');
        return formattedDate;
    };

    /**
     * Function to handle click on account name to display modal.
     * Sets selected account for modal display and shows the modal.
     * @param {object} account - Selected account object
     */
    const handleAccountNameClick = (account) => {
        setSelectedAccount(account);
        setShowModal(true);
    };

    /**
     * Function to close the modal.
     * Hides the modal by updating showModal state to false.
     */
    const closeModal = () => {
        setShowModal(false);
    };

    // Render component
    return (
        <>
            <div id="content" className='w-100'>
                {/* Breadcrumb navigation */}
                <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">{t('accounts')}</li>
                    </ol>
                    {/* Optionally, add other elements like overlays here */}
                </nav>

                {/* Header */}
                <div className="text-center w-100 h1" style={{ color: '#121212', fontSize: '600' }}>{t('listOfAccounts')}</div>
                <br />

                {/* Create Account Button */}
                <div className="ps-2">
                    <div className="row row-cols-5 mb-2">
                        <button
                            className={`${styles['btn-o-style']} btn offset-9`}
                            onClick={() => navigate(`/accounts/create`)}
                        >
                            {t('createAccount')}
                        </button>
                    </div>

                    {/* Table */}
                    <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                        <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                            <thead>
                                <tr className={`${styles['border-top-bottom']}`}>
                                    <th>{t('companyName')}</th>
                                    <th>{t('createdBy')}</th>
                                    <th>{t('startDate')}</th>
                                    <th>{t('endDate')}</th>
                                    <th>{t('viewUsers')}</th>
                                    {/* Uncomment below to add more columns */}
                                    {/* <th>Properties</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {/* Render each account row */}
                                {accounts.map((acct) => (
                                    <tr key={acct.hcode} className={`${styles['row-hover']}`} >
                                        <td style={{ cursor: 'pointer' }} onClick={() => { handleAccountNameClick(acct) }}>{acct.acct_name}</td>
                                        <td>{acct.created_by}</td>
                                        <td>{acct.start_date ? formatDate(acct.start_date) : `NA`}</td>
                                        <td>{acct.end_date ? formatDate(acct.end_date) : `NA`}</td>
                                        <td>
                                            <button
                                                className={`${styles['btn-style']}   btn`}
                                                style={{ lineHeight: "1" }}
                                                onClick={() => handleViewUsersClick(acct.acct_name)}
                                            >
                                                {t('viewUsers')}
                                            </button>
                                        </td>
                                        {/* Uncomment below to add more columns */}
                                        {/* <td>
                                            <ListProperties entity_type={1} entity_hcode={acct.hcode} />
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Modal for displaying account details */}
                {selectedAccount && (
                    <AccountModal
                        account={selectedAccount}
                        showModal={showModal}
                        closeModal={closeModal}
                        setChange={setChange} // Pass setChange function to update parent component state
                    />
                )}
            </div>
        </>
    );
}

export default ListAccounts;
