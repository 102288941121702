import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import SensorModal from '../../modal/SensorModal';
import ListProperties from '../propertyTable/PropertyTable';
import styles from '../../../common/common.module.css';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * The `ListSensors` function in JavaScript fetches and displays a list of sensors for a specific
 * asset, allowing users to view sensor details and add new sensors.
 * @returns The `ListSensors` function is returning JSX elements that display a list of sensors for a
 * specific asset. The returned JSX includes breadcrumb navigation, a table displaying sensor
 * information such as name, creation date, and creator, as well as buttons for adding sensors. If
 * sensors are available, they are displayed in the table rows. If no sensors are available, a message
 * indicating "nothing to show" is
 */
function ListSensors() {
    const { t } = useTranslation();
    const [sensors, setSensors] = useState([]);
    const [showSensors, setShowSensors] = useState(false);
    const [selectedSensor, setSelectedSensor] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { assetHCode, zone_name, plant_name } = useParams();
    const [change, setChange] = useState('');
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [asset_name, setAsset_name] = useState("");

    // 1 indicates it is either Equipment or Pipe
    const ASSET_TYPE = 1;

    const fetchAssetName = async () => {
        try {
            const resp = await fetch(`${SERVER_URL}/getAssetName/${assetHCode}/${ASSET_TYPE}`);
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.aname);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchAssetName();
    }, [assetHCode]);

    useEffect(() => {
        fetchSensors();
    }, []);

    useEffect(() => {
        fetchSensors();
    }, [change]);

    const fetchSensors = async () => {
        const response = await fetch(`${SERVER_URL}/sensors/${assetHCode}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setSensors(data.sensors);
        }

    };

    useEffect(() => {
        if (sensors.length > 0) {
            setShowSensors(true);
            $(tableRef.current).DataTable();
        }
    }, [sensors]);

    const handleSensorNameClick = (sensors) => {
        setSelectedSensor(sensors);
        setShowModal(true);
    };




    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {showSensors ?
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>{t('plants')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: '#121212' }}>{t('area')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/assets/${plant_name}/${zone_name}`} style={{ color: '#121212' }}>{t('assets')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('sensors')}</li>
                        </ol>
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfSensorOf')} {asset_name} {t('of')} {zone_name} {t('of')} {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => {
                                    // window.location.href = `/assets/create/${plant_name}/${zone_name}`
                                    navigate(`/sensors/create/${plant_name}/${zone_name}/${assetHCode}`);
                                }
                                }
                            >
                                {t('addSensor')}
                            </button>
                        </div>
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('sensorName')}</th>
                                        <th>{t('createdOn')}</th>
                                        <th>{t('createdBy')}</th>
                                        {/* <th>Properties</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sensors.map((sensor) => (
                                        <tr key={sensor.hcode} className={`${styles['row-hover']}`}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleSensorNameClick(sensor) }}>{sensor.sname}</td>
                                            <td>{sensor.created_on.split("T")[0]}</td>
                                            <td>{sensor.created_by}</td>
                                            {/* <td>
                                                <ListProperties entity_type={5} entity_hcode={sensor.hcode} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>{t('plants')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/zones/${plant_name}`} style={{ color: '#121212' }}>{t('area')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to={`/assets/${plant_name}/${zone_name}`} style={{ color: '#121212' }}>{t('assets')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('sensors')}</li>
                        </ol>
                    </nav>
                    <div className="text-center w-100 h3">{t('listOfSensorOf')} {asset_name} {t('of')} {zone_name} {t('of')} {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => navigate(`/sensors/create/${plant_name}/${zone_name}/${assetHCode}`)}
                            >
                                {t('addSensor')}
                            </button>
                        </div>
                        <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>

                    </div>
                </div>}{selectedSensor && (

                    <SensorModal
                        sensor={selectedSensor}
                        showModal={showModal}
                        closeModal={closeModal}
                        plant_name={plant_name}
                        zname={zone_name}
                        aname={asset_name}
                        setChange={setChange}

                    />

                )
            }
        </>
    );
}


export default ListSensors;
