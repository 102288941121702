import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import PlantModal from '../../modal/PlantModal';
import ListProperties from '../propertyTable/PropertyTable';
import { Trash } from 'react-bootstrap-icons';
import ModelUploadButton from '../../buttons/UploadModel';
import Overlay from '../../overlayScreen/overlayPage';
import styles from '../../../common/common.module.css';
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import { useTranslation } from 'react-i18next';

// Server URL fetched from environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListPlants() {
    const { t } = useTranslation();  // Translation hook
    const [plants, setPlants] = useState([]);  // State to hold plant data
    const [showPlants, setShowPlants] = useState(false);  // State to show/hide plants table
    const [selectedPlant, setSelectedPlant] = useState(null);  // State to hold selected plant
    const [showModal, setShowModal] = useState(false);  // State to show/hide modal
    const [change, setChange] = useState('');  // State to track changes
    const [uploadModel, setUploadModel] = useState(false);  // State to track model upload status
    const [deleteModel, setDeleteModel] = useState(false);  // State to track model deletion status
    const tableRef = useRef(null);  // Ref for the DataTable
    const navigate = useNavigate();  // Navigation hook

    // Fetch plants when the component mounts or change state changes
    useEffect(() => {
        fetchPlants();
    }, []);

    useEffect(() => {
        fetchPlants();
    }, [change]);

    // Function to fetch plants from the server
    const fetchPlants = async () => {
        const response = await fetch(`${SERVER_URL}/plants?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setPlants(data.plants);
        }
    };

    // Initialize DataTable when plants change
    useEffect(() => {
        if (plants.length > 0) {
            setShowPlants(true);
            $(tableRef.current).DataTable({
                destroy: true,
                language: t('dataTable', { returnObjects: true })  // Set language for DataTable
            });
        }
    }, [plants]);

    // Handle plant name click to show modal
    const handlePlantNameClick = (plant) => {
        setSelectedPlant(plant);
        setShowModal(true);
    };

    // Function to close modal
    const closeModal = () => {
        setShowModal(false);
    };

    // Handle view zones button click
    const handleViewZones = (plant_name) => {
        navigate(`/zones/${plant_name}`);
    };

    // Handle delete model button click
    const handleDeleteModel = (hcode, model_name) => {
        const confirmation = window.confirm('Are sure you want to delete Model?');
        if (confirmation) {
            setDeleteModel(true);
            fetch(`${SERVER_URL}/delete/${hcode}/${model_name}?hcode=${localStorage.getItem('hcode')}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => response.json()).then(() => {
                setDeleteModel(false);
                setChange((prev) => !prev);
            }).catch((error) => {
                console.error(error);
            });
        } else {
            alert('Model is not deleted');
        }
    };

    // Handle viewer button click
    const handleViewer = (urn, hcode, plant_name) => {
        window.open(`/viewer/${hcode}/${plant_name}?urn=${urn}`, '_blank');
    };

    // Handle action center button click
    const handleActionCenter = (urn, hcode, plant_name) => {
        navigate(`/plants/actioncenter/${hcode}/${plant_name}/${urn}`);
    };

    return (
        <>
            {uploadModel && <Overlay>uploading...</Overlay>}
            {deleteModel && <Overlay>deleting...</Overlay>}

            {showPlants ? (
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">{t('plants')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <div className="text-center w-100 h1" style={{ color: '#121212' }}>{t('listOfPlants')}</div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']} btn offset-9`}
                                onClick={() => navigate(`/plant/create`)}
                            >
                                {t('createPlant')}
                            </button>
                        </div>
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('plantName')}</th>
                                        <th>{t('accountName')}</th>
                                        <th>{t('view3DModel')}</th>
                                        <th>{t('actionCenter')}</th>
                                        <th>{t('viewProcessArea')}</th>
                                        {/* <th>Properties</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {plants.map((plant) => (
                                        <tr key={plant.hcode} className={`${styles['row-hover']}`}>
                                            <td>
                                                <a style={{ cursor: 'pointer' }} onClick={() => handlePlantNameClick(plant)}>
                                                    {plant.plant_name}
                                                </a>
                                            </td>
                                            <td>{plant.acct_name}</td>
                                            <td>
                                                {plant.modelID ? (
                                                    <>
                                                        <button
                                                            className={`${styles['btn-style']} btn`}
                                                            style={{ lineHeight: "1" }}
                                                            onClick={() => handleViewer(plant.modelID, plant.hcode, plant.plant_name)}
                                                        >
                                                            {t('view3DModel')}
                                                        </button>
                                                        <Trash onClick={() => handleDeleteModel(plant.hcode, plant.model_name)} />
                                                    </>
                                                ) : (
                                                    <ModelUploadButton hcode={plant.hcode} setChange={setChange} setUploadModel={setUploadModel} />
                                                )}
                                            </td>
                                            <td>
                                                {plant.modelID ? (
                                                    <button
                                                        className={`${styles['btn-style']} btn`}
                                                        style={{ lineHeight: "1" }}
                                                        onClick={() => handleActionCenter(plant.modelID, plant.hcode, plant.plant_name)}
                                                    >
                                                        {t('open')}
                                                    </button>
                                                ) : (
                                                    <>{t('uploadModel')}</>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className={`${styles['btn-style']} btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewZones(plant.plant_name)}
                                                >
                                                    {t('viewAreas')}
                                                </button>
                                            </td>
                                            {/* <td>
                                                <ListProperties entity_type={2} entity_hcode={plant.hcode} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">{t('plants')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <div className="text-center w-100 h1" style={{ color: '#121212' }}>{t('listOfPlants')}</div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']} btn`}
                                onClick={() => navigate(`/plant/create`)}
                            >
                                {t('createPlant')}
                            </button>
                        </div>
                        <div>{t('nothingToShow')}</div>
                    </div>
                </div>
            )}
            {selectedPlant && (
                <PlantModal
                    plant={selectedPlant}
                    showModal={showModal}
                    closeModal={closeModal}
                    change={setChange}
                />
            )}
        </>
    );
}

export default ListPlants;
