import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppContext from '../../context/loginContext';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * UserHeader component for displaying the header navigation bar for logged-in users.
 *
 * @component
 */
function UserHeader() {
  const [user, setUser] = useState('User'); // State to store the username
  const [ip, setIp] = useState(''); // State to store the user's IP address
  const navigate = useNavigate();
  const { isUserLogin, setIsUserLogin } = useContext(AppContext);

  /**
   * Handle user logout by clearing local storage and navigating to the login page.
   */
  const handleLogOut = () => {
    localStorage.removeItem("userhcode");
    setIsUserLogin(false);
    navigate('/userlogin');
  };

  /**
   * Fetch user data from the server.
   */
  const fetchData = async () => {
    const userHCode = localStorage.getItem('userhcode');
    if (userHCode) {
      const response = await fetch(`${SERVER_URL}/username?hcode=${userHCode}`);
      const data = await response.json();
      if (data.statusCode === 200) {
        const userName = data.username.split('@')[0];
        setUser(userName);
        setIp(data.user_ip);
      } else if (data.statusCode === 401) {
        localStorage.removeItem("userhcode");
        navigate('/userlogin');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <nav className="navbar px-2" style={{ backgroundColor: '#121212' }}>
      <div className="d-flex justify-content-between w-100 px-2">
        <a className="navbar-brand" href="https://www.metso.com/" target="_blank">
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} height="40" alt="Metso logo" />
        </a>
        {isUserLogin && (
          <>
            <h2 style={{ color: '#fff', fontFamily: 'serif' }}>Metso Digital Solution</h2>
            <div style={{ color: '#fff' }}>
              <div className="d-flex flex-column text-capitalize">
                <h5>Welcome, {user}</h5>
                <a id="logout-link" href='#' onClick={handleLogOut} className="align-self-end" style={{ color: '#fff', textDecoration: 'none' }}>Sign out</a>
              </div>
              {/* <div className='fst-italic fw-light fs-6'>You have logged in from {ip}</div> */}
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default UserHeader;
