import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';

const GraphLive = () => {
  
  const [data, setData] = useState([]);
  const [viewWindow, setViewWindow] = useState([]);
  const options = {
    hAxis: {
      title: 'Time',
      viewWindow: {
        min: viewWindow[0],
        max: viewWindow[1]
      },
      format: 'HH:mm:ss',
      titleTextStyle: {
        color: '#ff0000'
      },
      textStyle: {
        color: '#ff0000'
      },
      gridlines: {
        color: '#eee',
        count: -1
      }
    },
    vAxis: {
      title: 'Value',
      minValue: 20,
      maxValue: 140,
      titleTextStyle: {
        font: '25px',
        color: '#ff0000'
      },
      textStyle: {
        color: '#ff0000'
      },
      gridlines: {
        color: '#eee'
      }
    },
    legend: {
      position: 'top', // Set legend position to bottom
      alignment:'end',
      textStyle: {
        color: '#ff0000' // Set legend text color
      }
    },
    colors: ['#ff0000'], // Set line color to #ebb30b
    backgroundColor: 'transparent' // Set background color to transparent
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const randomValue = Math.floor(Math.random() * (125 - 50 + 1) + 50);

      setData(prevData => [...prevData, [currentTime, randomValue]]);
      setViewWindow([new Date(currentTime.getTime() - 10000), currentTime]);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="LineChart"
      loader={<div>Loading Chart...</div>}
      data={[['Time', 'Value'], ...data]}
      options={options}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

export default GraphLive;
