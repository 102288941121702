import { useState } from "react";
import Graph from "../googleCharts/Graph";
import GraphLive from "../googleCharts/liveGraph";


function DefaultGraph({ activeSubTab, startDate, endDate, defaultSensor }) {
    const [droppedObject, setDroppedObject] = useState(null);

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const data = JSON.parse(event.dataTransfer.getData('text/plain'));
        setDroppedObject(data);
    };

    return (
        <>
            <div className="card w-100" style={{ "width": "18rem", backgroundColor: 'transparent' }}>
                <div className="card-body" onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    {!droppedObject ?
                        <>
                            <h5 className="card-title" style={{ color: '#121212' }}>{defaultSensor.sname}</h5>
                            {
                                activeSubTab === 'Live' ?
                                <GraphLive shcode={defaultSensor.hcode} startDate={startDate} endDate={endDate} />
                                : <Graph shcode={defaultSensor.hcode} startDate={startDate} endDate={endDate}></Graph>
                            }
                        </>
                        :
                        <>
                            <h5 className="card-title" style={{ color: '#121212' }} >{droppedObject.sname}</h5>
                            {
                                activeSubTab === 'Live' ?
                                <GraphLive shcode={droppedObject.hcode} startDate={startDate} endDate={endDate} />
                                : <Graph shcode={droppedObject.hcode} startDate={startDate} endDate={endDate}></Graph>
                            }
                        </>
                    }

                </div>
            </div>
        </>
    )
}



export default DefaultGraph;