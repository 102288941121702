import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, sensorHCode) => {
    const response = await fetch(`${SERVER_URL}/sensors/update/${sensorHCode}?hcode=asdfemail`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};



const SensorModal = ({ sensor, showModal, closeModal, setChange }) => {
    const sensorHCode = sensor.hcode;
    const [sensorData, setSensorData] = useState(sensor);
    const [accounts, setAccounts] = useState([]);
    const [plants, setPlants] = useState([]);
    const [zones, setZones] = useState([]);
    const [assets, setAssets] = useState([]);
    const [acctName, setAcctName] = useState(sensor.acct_name);
    const [plantName, setPlantName] = useState(sensor.plant_name);
    const [zoneName, setZoneName] = useState(sensor.zname);
    const [assetName, setAssetName] = useState(sensor.aname);




    const fetchAccounts = async () => {
        const response = await fetch(`${SERVER_URL}/listOfAccounts?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setAccounts(data.accounts);
    };

    const fetchPlants = async (acctName) => {
        const response = await fetch(`${SERVER_URL}/listOfPlants/${acctName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setPlants(data.plants);
    };

    const fetchZones = async (plantName) => {
        const response = await fetch(`${SERVER_URL}/listOfZones/${plantName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setZones(data.zones);
    };

    const fetchAssets = async (plantName, zoneName) => {
        const response = await fetch(`${SERVER_URL}/listOfAssets/${plantName}/${zoneName}?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setAssets(data.assets);
    };

    useEffect(() => {
        fetchAssets(plantName, zoneName);
        fetchZones(plantName);
        fetchPlants(acctName);
        fetchAccounts();
    }, []);


    useEffect(() => {
        setSensorData(sensor);
    }, [sensor]);





    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'sname') {
            setSensorData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'acct_name') {
            setAcctName(value);
            fetchPlants(value);
            setSensorData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'plant_name') {
            setPlantName(value);
            fetchZones(value);
            setSensorData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'zname') {
            setZoneName(value);
            setSensorData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'aname') {
            setAssetName(value);
            setSensorData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };



    const handleSaveChanges = async () => {
        // Logic to save the changes to the plant data
        const response = await fetchData(sensorData, sensorHCode);
        const data = await response.json();

        closeModal();
        setChange(response);
        // window.location.reload();
    };




    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Edit Sensor</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`} s>
                <Form className={`${style['custom-form']}`}>
                    <Form.Group controlId="sensorName">
                        <Form.Label>Sensor Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="sname"
                            value={sensorData.sname}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="createdOn">
                        <Form.Label>Created On</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_on"
                            value={sensorData.created_on}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="createdBy">
                        <Form.Label>Created By</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_by"
                            value={sensorData.created_by}
                            // onChange={handleInputChange}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="accountName">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Select
                            name="acct_name"
                            value={acctName}
                            onChange={handleInputChange}
                            required
                        >
                            <option disabled value=''>Select a Account</option>
                            {accounts.map((account) => (
                                <option key={account.hcode} value={account.acct_name}>
                                    {account.acct_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="plantName">
                        <Form.Label>Plant Name</Form.Label>
                        <Form.Select
                            name="plant_name"
                            value={plantName}
                            onChange={handleInputChange}
                            disabled={plants.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option disabled value=''>Select a Plant</option>
                            {plants.length === 0 ? (
                                <option disabled>No plants available</option>
                            ) : (
                                plants.map((plant) => (
                                    <option key={plant.hcode} value={plant.plant_name}>
                                        {plant.plant_name}
                                    </option>
                                ))
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="zname">
                        <Form.Label>Area Name</Form.Label>
                        <Form.Select
                            name="zname"
                            value={zoneName}
                            onChange={handleInputChange}
                            disabled={zones.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option disabled value=''>Select a Zone</option>
                            {zones.length === 0 ? (
                                <option disabled>No Zones available</option>
                            ) : (
                                zones.map((zone) => (
                                    <option key={zone.hcode} value={zone.zname}>
                                        {zone.zname}
                                    </option>
                                ))
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="aname">
                        <Form.Label>Asset Name</Form.Label>
                        <Form.Select
                            name="aname"
                            value={assetName}
                            onChange={handleInputChange}
                            disabled={assets.length === 0} // Disable the select when there are no plants
                            required
                        >
                            <option disabled value=''>Select a Asset</option>
                            {assets.length === 0 ? (
                                <option disabled>No Assets available</option>
                            ) : (
                                assets.map((asset) => (
                                    <option key={asset.hcode} value={asset.aname}>
                                        {asset.aname}
                                    </option>
                                ))
                            )}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};




export default SensorModal;