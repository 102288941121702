import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../common/common.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function UserViewerProperties(prop) {
    const [properties, setProperties] = useState([]);
    const [showProperties, setShowProperties] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const { selectedPart, plantHcode } = prop;
    const [aliase, setaliase] = useState(null);


    const tableRef = useRef(null);

    const fetchAssetName = async () => {
        try {
            let part_name = '';

            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            fetchEquipment(part_name);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('userhcode'));

        try {
            if (selectedPart) {
                const resp = await fetch(`${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString());
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.equipment.aname);
                    // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
                    // Getting ASSET_TYPE value as type in getEquipment API
                    // Using ASSET_TYPE to getProperties
                    fetchProperties(data.equipment.hcode, data.type);
                    fetchAsset(data.equipment.hcode, data.type);
                } else {
                    setShowProperties(false);
                    setLoad(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                    if (data.alias_name !== '') {
                        setaliase(data.alias_name);
                    } else {
                        setaliase(null)
                    }
                } else {
                    setShowProperties(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };


    const fetchProperties = async (hashCode, type) => {
        if (hashCode) {
            try {
                setLoad(true);
                const response = await fetch(`${SERVER_URL}/user/assetProperties/${hashCode}?hcode=${localStorage.getItem('userhcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    setProperties(data.assetProperties);
                    setShowProperties(true);
                } else {
                    setProperties([]);
                    setShowProperties(false);
                    setLoad(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };


    useEffect(() => {
        fetchAssetName();
    }, [selectedPart]);

    useEffect(() => {
        if (properties.length > 0) {
            setShowProperties(true);
            $(tableRef.current).DataTable();
        } else {
            setShowProperties(false);
        }
    }, [properties]);




    return (
        <>
            {properties.length > 0 ?
                <div id="content" className='w-100'>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>Properites of {asset_name}{aliase && `(${aliase})`}</div>
                    <br />
                    <div className="ps-2">
                        <div className="w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm table-bordered`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>Property</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {properties.map((property) => (
                                        <tr className={`${styles['border-top-bottom']}`}>
                                            <td style={{ fontWeight: 'bolder ' }}>{property.prop_name}</td>
                                            <td>{property.prop_value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <>{
                    load ? <div id="content" className='w-100'>
                        <div className="ps-2">
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>Loading...</div>
                        </div>
                    </div> : <div id="content" className='w-100'>
                        <div className="ps-2">
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>Nothing to show</div>
                        </div>
                    </div>
                }</>
            }
        </>
    );
}


export default UserViewerProperties;
