import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import ZoneModal from '../../modal/ZoneModal';
import OverlayWithTabs from '../../overlays/overlaysWithTabs';
import ListProperties from '../propertyTable/PropertyTable';
import { useTranslation } from 'react-i18next';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


/**
 * The `ListZones` function in JavaScript fetches and displays a list of zones for a specific plant,
 * allowing users to view assets, pipes, and instruments within each zone.
 * @returns The `ListZones` function returns JSX elements that display a list of zones for a specific
 * plant. It includes a breadcrumb navigation, a table displaying zone information, buttons to view
 * assets, pipes, and instruments for each zone, and buttons to define assets, piping, and instruments.
 * The function also includes conditional rendering based on whether there are zones to display or not.
 * If a zone is selected,
 */
function ListZones() {
    const { t } = useTranslation();
    const [zones, setZones] = useState([]);
    const [showZones, setShowZones] = useState(false);
    const [selectedZone, setSelectedZone] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');

    
    const { plant_name } = useParams();

    const tableRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchZones();
    }, []);

    useEffect(() => {
        fetchZones();
    }, [change]);

   /**
    * The function `fetchZones` fetches data about zones related to a specific plant from a server and
    * sets the zones if the response status code is 200.
    */
    const fetchZones = async () => {
        const response = await fetch(`${SERVER_URL}/zones/${plant_name}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setZones(data.zones);
        }
    };

    useEffect(() => {
        if (zones.length > 0) {
            setShowZones(true);
            $(tableRef.current).DataTable({
                destroy: true,
                language: t('dataTable', { returnObjects: true })
            });
        }
    }, [zones]);

    const handleZoneNameClick = (zone) => {
        setSelectedZone(zone);
        setShowModal(true);
    };




    const closeModal = () => {
        setShowModal(false);
    };


    const handleViewAssets = (zone_name) => {
        navigate(`/assets/${plant_name}/${zone_name}`);
    };

    const handleViewPipes = (zone_name) => {
        navigate(`/pipes/${plant_name}/${zone_name}`);
    };

    const handleViewInstruments = (zone_name) => {
        navigate(`/instruments/${plant_name}/${zone_name}`);
    };

    const handleDefineAssets = (zone_name, urn) => {
        window.open(`/defineassets/${plant_name}/${zone_name}?urn=${urn}`, '_blank');
    };

    const handleDefinePiping = (zone_name, urn) => {
        window.open(`/definepiping/${plant_name}/${zone_name}?urn=${urn}`, '_blank');
    };

    const handleDefineInstrument = (zone_name, urn) => {
        window.open(`/defineinstruments/${plant_name}/${zone_name}?urn=${urn}`, '_blank');
    };


    return (
        <>
            {showZones ?
                <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>{t('plants')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('area')}</li>
                        </ol>
                        {/* <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a> */}
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfArea')} {plant_name} </div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => navigate(`/zones/create/${plant_name}`)}
                            >
                                {t('createArea')}
                            </button>
                        </div>
                        <div className="overflow-y w-100" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('areaName')}</th>
                                        <th>{t('areaCode')}</th>
                                        <th>{t('createdOn')}</th>
                                        {/* <th>Define</th> */}
                                        <th>{t('view')}</th>
                                        {/* <th>Properties</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {zones.map((zone) => (
                                        <tr key={zone.hcode} className={`${styles['row-hover']}`}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => handleZoneNameClick(zone)}>{zone.zname}</td>
                                            <td>{zone.zoneID}</td>
                                            <td>{zone.created_on.split("T")[0]}</td>
                                            {/* <td>{zone.modelID ? <><button
                                                className={`${styles['btn-style']}   btn`}
                                                style={{ lineHeight: "1" }}
                                                onClick={() => handleDefinePiping(zone.zname, zone.modelID)}
                                            >
                                                Piping
                                            </button>
                                                <button
                                                    className={`${styles['btn-style']}   btn ms-2`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleDefineAssets(zone.zname, zone.modelID)}
                                                >
                                                    Assets
                                                </button>
                                                <button
                                                    className={`${styles['btn-style']}   btn ms-2`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleDefineInstrument(zone.zname, zone.modelID)}
                                                >
                                                    Instruments
                                                </button>
                                            </>
                                                : <Link to='/plants' >Upload Model</Link>}</td> */}

                                            <td>
                                                <button
                                                    className={`${styles['btn-style']}   btn`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewAssets(zone.zname)}
                                                >
                                                    {t('assets')}
                                                </button>
                                                <button
                                                    className={`${styles['btn-style']}   btn ms-3`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewPipes(zone.zname)}
                                                >
                                                    {t('pipes')}
                                                </button>
                                                <button
                                                    className={`${styles['btn-style']}   btn ms-3 mt-1`}
                                                    style={{ lineHeight: "1" }}
                                                    onClick={() => handleViewInstruments(zone.zname)}
                                                >
                                                    {t('instruments')}
                                                </button>
                                            </td>
                                            {/* <td>
                                                <ListProperties entity_type={3} entity_hcode={zone.hcode} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <div id="content" className='w-100'>
                    <nav aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/plants" style={{ color: '#121212' }}>{t('plants')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('area')}</li>
                        </ol>
                        <a className='me-2'><OverlayWithTabs></OverlayWithTabs></a>
                    </nav>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('listOfArea')} {plant_name}</div>
                    <br />
                    <div className="ps-2">
                        <div className="row row-cols-5 mb-2">
                            <button
                                className={`${styles['btn-style']}   btn offset-9`}
                                onClick={() => navigate(`/zones/create/${plant_name}`)}
                            >
                                {t('createArea')}
                            </button>
                        </div>
                        <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>

                    </div>
                </div>}
            {selectedZone && (

                <ZoneModal
                    zone={selectedZone}
                    showModal={showModal}
                    closeModal={closeModal}
                    setChange={setChange}

                />
            )
            }
        </>
    );
}


export default ListZones;
