
import React, { useState } from "react";
import styles from './SetPassword.module.css';
import SetPasswordForm from '../../components/set-pass/SetPasswordForm';
import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import styled from c


function SetPasswordPage() {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('hcode') !== null || isLogin === true) {

            navigate('/dashboard');
        }
    }, [isLogin, navigate]);


    return (
        <Wrapper>
            <Header />
            <div className={styles['div-style']}>
                <SetPasswordForm />
            </div>
            <Footer />
        </Wrapper>
    );
}
export default SetPasswordPage;