import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';

// The server URL from environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function UserViewerMaintenance(prop) {
    const [spares, setSpares] = useState([]);  // State to hold spares data
    const [showMaintenanceHistory, setShowMaintenanceHistory] = useState(null);  // State to show/hide maintenance history
    const [hcode, setHcode] = useState(null);  // State to hold hcode
    const [load, setLoad] = useState(false);  // State to manage loading status
    const [asset_name, setAsset_name] = useState("");  // State to hold asset name
    const [change, setChange] = useState(false);  // State to track changes
    const { selectedPart, setActiveTab, plantHcode } = prop;  // Props passed to the component
    const [aliase, setaliase] = useState(null);  // State to hold asset alias name

    const tableRef = useRef(null);  // Ref for the DataTable

    // Fetch asset name based on the selected part
    const fetchAssetName = async () => {
        try {
            let part_name = '';

            // Fetch part name from the NOP_VIEWER
            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            fetchEquipment(part_name);  // Fetch equipment details using the part name
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch equipment details based on part name
    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('userhcode'));

        try {
            const resp = await fetch(`${SERVER_URL}/user/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString());
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.equipment.aname);
                fetchSpares(data.equipment.hcode, data.type);  // Fetch spares using the equipment hcode and type
                fetchAsset(data.equipment.hcode, data.type);  // Fetch asset details using the equipment hcode and type
            } else {
                setShowMaintenanceHistory(false);
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch asset details based on hash code and type
    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                    if (data.alias_name !== '') {
                        setaliase(data.alias_name);
                    } else {
                        setaliase(null);
                    }
                } else {
                    setShowMaintenanceHistory(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Fetch spares based on hash code and type
    const fetchSpares = async (hashCode, type) => {
        if (hashCode) {
            try {
                setLoad(true);
                const response = await fetch(`${SERVER_URL}/user/spares/${hashCode}?hcode=${localStorage.getItem('userhcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    setSpares(data.spares);
                    setShowMaintenanceHistory(true);
                } else {
                    setSpares([]);
                    setShowMaintenanceHistory(false);
                    setLoad(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Fetch asset name when the selected part changes
    useEffect(() => {
        fetchAssetName();
    }, [selectedPart]);

    // Initialize and destroy DataTable when spares change
    useEffect(() => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        if (spares.length > 0) {
            setShowMaintenanceHistory(true);
            const dataTable = $(tableRef.current).DataTable();

            // Customize the table filter input
            const searchInput = $(tableRef.current).closest('.dataTables_wrapper').find('input[type="search"]');
            searchInput.css('left', '-6px');
            searchInput.css('position', 'relative');

            // Apply DataTables initialization
            dataTable.draw();
        }
        else {
            setShowMaintenanceHistory(false);
        }
    }, [spares]);

    return (
        <>
            {spares.length > 0 ? (
                <div id="content" className='w-100' style={{ maxWidth: '100%' }}>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>
                        Spare Part History of {asset_name}{aliase && `(${aliase})`}
                    </div>
                    <span className='fw-bold fs-5' style={{ color: '#121212' }}>
                        Link:
                    </span>
                    <Link className={`${styles['btn-style']} btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>
                        Spare part list
                    </Link>
                    <br />
                    <div className="ps-2">
                        <div className="w-100 overflow" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>Spare Name</th>
                                        <th>Replacement Date</th>
                                        <th>Quantity</th>
                                        <th>Created On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {spares.map((spare) => (
                                        <tr key={spare.hcode} className={`${styles['row-hover']}`}>
                                            <td>{spare.spare_name}</td>
                                            <td>{spare.spare_replacement_date.split('T')[0]}</td>
                                            <td>{spare.quantity + ' ' + spare.quantity_unit}</td>
                                            <td>{spare.created_on.split('T')[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {load ? (
                        <div id="content" className='w-100'>
                            <div className="ps-2">
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>Loading...</div>
                            </div>
                        </div>
                    ) : (
                        <div id="content" className='w-100'>
                            <div className="m-3 ps-2">
                                <span className='fw-bold fs-5' style={{ color: '#121212' }}>
                                    Link:
                                </span>
                                <Link className={`${styles['btn-style']} btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>
                                    Spare part list
                                </Link>
                                <div className='w-100 text-center h1' style={{ color: '#121212' }}>Nothing to show</div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default UserViewerMaintenance;
