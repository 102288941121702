import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from '../../context/loginContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Viewer from '../../components/viewer/Viewer';
import ListAllSensors from '../../components/Lists/SensorList';
import Sidebars from '../../components/sidebar/sidebar';
import style from './analyticPageStyle/analytic.module.css'




function DashPageViewer({ screenCode }) {
    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();

    const today = new Date().toISOString().split("T")[0];
    const [fromDate, setFromDate] = useState("2023-06-01");
    const [toDate, setToDate] = useState("2023-06-01");
    const [startDate, setStartDate] = useState("2023-06-01");
    const [endDate, setEndDate] = useState("2023-06-01");

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStartDate(fromDate);
        setEndDate(toDate);
    }

    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
    }, []);
    return (
        <Wrapper>
            <Header />
            <Sidebars>
                <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                    <div className="input-group w-50">
                        <span className="input-group-text">Date From</span>
                        <input
                            type="date"
                            aria-label="From date"
                            className="form-control"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            max={today}
                            required
                        />
                        <span className="input-group-text">TO</span>
                        <input
                            type="date"
                            aria-label="To date"
                            className="form-control"
                            value={toDate}
                            onChange={handleToDateChange}
                            max={today}
                            min={fromDate}
                            required
                        />
                        <button type="submit" className='-end'>FetchData</button>
                    </div>
                </form>

                <div className='d-flex flex-row w-100'>
                    <Viewer width={'65%'} height={'600px'} ></Viewer>
                </div>
                <ListAllSensors startDate={startDate} endDate={endDate}></ListAllSensors>
            </Sidebars>
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashPageViewer;