import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import ProcurementModal from '../../modal/ProcurementModal';
import ProcurementEditModal from '../../modal/ProcurementEditModal';
import styles from '../../../common/common.module.css';
import { useParams } from 'react-router-dom';
import SpareDetailModal from '../../modal/SpareDetailModal';
import { useTranslation } from 'react-i18next';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ViewerProcurement(prop) {

    const { t } = useTranslation();
    const [procurements, setProcurements] = useState([]);
    const [showProcurements, setShowProcurements] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedSpareProcurement, setSelectedSpareProcurement] = useState(null);
    const [hcode, setHcode] = useState(null);
    const [load, setLoad] = useState(false);
    const [asset_name, setAsset_name] = useState("");
    const [aliase, setaliase] = useState(null);
    const [change, setChange] = useState(false);
    const [addedProcurement, setAddedProcurement] = useState(false);
    const { selectedPart, setActiveTab } = prop;
    const { plantHcode, plant_name } = useParams();
    const [showSparesDetail, setShowSparesDetail] = useState(false);
    const [spareName, setSpareName] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const urn = params.get('urn');

    const tableRef = useRef(null);



    // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
    // Getting ASSET_TYPE value as type in getEquipment API
    // Using ASSET_TYPE to getAssetName
    const [assetType, setAssetType] = useState(0);

    const closeModal = () => {
        setShowModal(false);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const fetchAssetName = async () => {
        try {
            let part_name = '';

            await new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                    part_name = data.name;
                    resolve();
                }, reject);
            });

            fetchEquipment(part_name);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEquipment = async (part_name) => {
        const newParams = new URLSearchParams();
        newParams.append('part_name', part_name);
        newParams.append('hcode', localStorage.getItem('hcode'));

        try {
            const resp = await fetch(`${SERVER_URL}/getEquipment/${plantHcode}/${selectedPart}?` + newParams.toString());
            const data = await resp.json();
            if (data.statusCode === 200) {
                setAsset_name(data.equipment.aname);
                // ASSET_TYPE indicates (1 = ASSET, PIPE) OR (3 = INSTRUMENT) OR (4 = VALVE)
                // Getting ASSET_TYPE value as type in getEquipment API
                // Using ASSET_TYPE to getProperties
                setHcode(data.equipment.hcode);
                fetchSpares(data.equipment.hcode, data.type);
                fetchAsset(data.equipment.hcode, data.type);
            } else {
                setHcode(null);
                setShowProcurements(false);
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const fetchAsset = async (hashCode, type) => {
        if (hashCode) {
            try {
                const resp = await fetch(`${SERVER_URL}/getAssetName/${hashCode}/${type}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.aname);
                    if (data.alias_name !== '') {
                        setaliase(data.alias_name);
                    } else {
                        setaliase(null)
                    }
                } else {
                    setShowProcurements(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        console.log(procurements);
    }, [procurements])


    /**
     * 
     * @param {*} hashCode 
     * @param {*} type
     * Update Spare List
     */
    const fetchSpares = async (hashCode, type) => {
        if (hashCode && asset_name) {
            try {
                setShowProcurements(false);
                const response = await fetch(`${SERVER_URL}/spares/procurements/${hashCode}?hcode=${localStorage.getItem('hcode')}`);
                const data = await response.json();
                if (data.statusCode === 200) {
                    if (data.procurements.length === 0) {
                        if (urn === 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bW9kZWxidWNrZXQtYmFzaWMyMDI0LzE3MTYyNzAxMDgzMzUuMzY1Mk9VNjAyMzE5NzkxLklHUw') {
                            setProcurements([{
                                "hcode": "e0c133656b04032b876003dc597266521bc634745a1f",
                                "spare_name": "Socket cap screw ISO 4762-A4-80_M8 x 30",
                                "price": 70,
                                "purchase_date": "2024-01-15T00:00:00.000Z",
                                "quantity": 1,
                                "quantity_unit": "Pieces",
                                "supplier": "Metso",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:13:06.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [451, 461, 471, 481, 491, 501, 511, 521, 531, 541, 551, 561]
                            },
                            {
                                "hcode": "7b86c7e07707b40f4dcc3904bc4dba8dab4b337730fc",
                                "spare_name": "Hexagon head screw ISO 4014-A4-80_M24x60",
                                "price": 150,
                                "purchase_date": "2024-01-19T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:08.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [1834, 2293, 2308, 2323, 2338, 2353, 2368, 2383, 2398, 2413, 2428, 2443]
                            },
                            {
                                "hcode": "8169c736cfaecb051f6577cca483f55736954ebf613e",
                                "spare_name": "Washer Nordlock-A4_NL24",
                                "price": 130,
                                "purchase_date": "2024-01-09T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:35.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [8127, 1849, 1879, 1886, 1893, 1900, 1907, 1914, 1921, 1928, 1935, 1942, 1949, 1956,
                                    2216, 2223, 2230, 2237, 2244, 2251, 2258, 2265, 2272, 2279, 2286
                                ]
                            },
                            {
                                "hcode": "5b38db47f0f5415f5c78b6fae34989fe63a19960f89e",
                                "spare_name": "Hexagon nut ISO 4032-A4-80_M24",
                                "price": 250,
                                "purchase_date": "2024-01-16T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:25:01.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [1856, 1963, 1986, 2009, 2032, 2055, 2078, 2101, 2124, 2147, 2170, 2193]
                            }]);
                        } else if (asset_name === '/K43-C741_VENTURI') {
                            setProcurements([{
                                "hcode": "e0c133656b04032b876003dc597266521bc634745a1f",
                                "spare_name": "Long Lug",
                                "price": 70,
                                "purchase_date": "2024-01-15T00:00:00.000Z",
                                "quantity": 1,
                                "quantity_unit": "Pieces",
                                "supplier": "Metso",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:13:06.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [9108, 9106, 9110, 9112, 9104, 9102, 9100]
                            },
                            {
                                "hcode": "7b86c7e07707b40f4dcc3904bc4dba8dab4b337730fc",
                                "spare_name": "Short Lug",
                                "price": 150,
                                "purchase_date": "2024-01-19T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:08.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [9150, 9146, 9142, 9138]
                            },
                            {
                                "hcode": "8169c736cfaecb051f6577cca483f55736954ebf613e",
                                "spare_name": "Anchor",
                                "price": 130,
                                "purchase_date": "2024-01-09T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:35.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [9246, 9248, 9244, 9242, 9240, 9238, 9236, 9234, 9232, 9230, 9228, 9226]
                            }]);
                        } else if (asset_name === '/K43-C741_TOWER') {
                            setProcurements([{
                                "hcode": "e0c133656b04032b876003dc597266521bc634745a1f",
                                "spare_name": "Long Lug",
                                "price": 70,
                                "purchase_date": "2024-01-15T00:00:00.000Z",
                                "quantity": 1,
                                "quantity_unit": "Pieces",
                                "supplier": "Metso",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:13:06.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [9392, 9391, 9393, 9394, 9395, 9396, 9397, 9398, 9399, 9400, 9401, 9402, 9403, 9404, 9405, 9406, 9407, 9411, 9408, 9409, 9410, 9412]
                            },
                            {
                                "hcode": "8169c736cfaecb051f6577cca483f55736954ebf613e",
                                "spare_name": "Anchor",
                                "price": 130,
                                "purchase_date": "2024-01-09T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:35.000Z",
                                "aname": "/HH51-AU001",
                                dbid: [9363, 9362, 9361, 9360, 9359, 9364, 9365, 9366, 9358, 9357, 9356, 9355, 9354, 9353, 9352, 9351, 9350, 9349]
                            }]);
                        }
                        else {
                            setProcurements([{
                                "hcode": "e0c133656b04032b876003dc597266521bc634745a1f",
                                "spare_name": "GASKET TAPE3x20mm",
                                "price": 70,
                                "purchase_date": "2024-01-15T00:00:00.000Z",
                                "quantity": 1,
                                "quantity_unit": "Pieces",
                                "supplier": "Metso",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:13:06.000Z",
                                "aname": "/HH51-AU001"
                            },
                            {
                                "hcode": "7b86c7e07707b40f4dcc3904bc4dba8dab4b337730fc",
                                "spare_name": "RETAINER",
                                "price": 150,
                                "purchase_date": "2024-01-19T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:08.000Z",
                                "aname": "/HH51-AU001"
                            },
                            {
                                "hcode": "8169c736cfaecb051f6577cca483f55736954ebf613e",
                                "spare_name": "EYE BOLT",
                                "price": 130,
                                "purchase_date": "2024-01-09T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:24:35.000Z",
                                "aname": "/HH51-AU001"
                            },
                            {
                                "hcode": "5b38db47f0f5415f5c78b6fae34989fe63a19960f89e",
                                "spare_name": "HEX HEAD SCREWM20x60mm",
                                "price": 250,
                                "purchase_date": "2024-01-16T00:00:00.000Z",
                                "quantity": 64,
                                "quantity_unit": "Pieces",
                                "supplier": "Foo Inc.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:25:01.000Z",
                                "aname": "/HH51-AU001"
                            },
                            {
                                "hcode": "f631f11b4508dccc4dc1cc2d87ae40326c3fa2856acc",
                                "spare_name": "1 HEX NUT M12 ISO 4032 - M12 - A4-70",
                                "price": 600,
                                "purchase_date": "2024-01-10T00:00:00.000Z",
                                "quantity": 6,
                                "quantity_unit": "Pieces",
                                "supplier": "Zickwolff LLP",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:25:26.000Z",
                                "aname": "/HH51-AU001"
                            },
                            {
                                "hcode": "d549bda225b19b802c773e31f844182fa26d877bc04a",
                                "spare_name": "ARM",
                                "price": 56,
                                "purchase_date": "2024-01-10T00:00:00.000Z",
                                "quantity": 4,
                                "quantity_unit": "Pieces",
                                "supplier": "Zick LLP.",
                                "created_by": "email@email.com",
                                "created_on": "2024-01-30T10:25:50.000Z",
                                "aname": "/HH51-AU001"
                            }]);
                        }
                    } else {
                        setProcurements(data.procurements);
                    }
                    setShowProcurements(true);
                } else {
                    setLoad(false);
                    setShowProcurements(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        fetchAssetName();
    }, [selectedPart]);

    useEffect(() => {
        fetchSpares(hcode);
    }, [change, addedProcurement, asset_name]);


    useEffect(() => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            $(tableRef.current).DataTable().destroy();
        }
        if (procurements.length > 0) {
            setShowProcurements(true);
            $(tableRef.current).DataTable().destroy();
            const dataTable = $(tableRef.current).DataTable({
                destroy: true,
                language: t('dataTable', { returnObjects: true })
            });
            const searchInput = $(tableRef.current).closest('.dataTables_wrapper').find('input[type="search"]');
            searchInput.css('left', '-6px');
            searchInput.css('position', 'relative');
            dataTable.draw();
        }
        else {
            setShowProcurements(false);
        }
    }, [procurements]);

    const handleAddSpare = () => {
        setShowModal(true);
    }

    const handleAddedSpare = () => {
        setAddedProcurement(prev => !prev);
    }


    const handleEditSpareProcurement = (spare) => {
        setSelectedSpareProcurement(spare);
        setShowEditModal(true);
    }

    const closeSpareDetailModal = () => {
        setShowSparesDetail(false);
    }


    return (
        <>  {hcode && <> <button className={`${styles['btn-style']}   btn offset-5 w-50`} onClick={handleAddSpare}>{t('addPurchaseInfo')}</button><br></br></>}
            {procurements.length > 0 ?
                <div id="content" className='w-100' style={{ maxWidth: '100%' }}>
                    <div className="text-center w-100 h2" style={{ color: '#121212' }}>{t('sparePurchaseHistoryOf')} {asset_name} {aliase && `(${aliase})`}</div>
                    <span className='fw-bold fs-5' style={{ color: '#121212' }}>{t('link')}: </span><Link className={`${styles['btn-style']}   btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>{t('sparePartList')}</Link>
                    <br />
                    <div className="ps-2">
                        <div className="w-100 overflow" style={{ maxHeight: "100%" }}>
                            <table className={`table ${styles.table} table-sm`} id="table" ref={tableRef}>
                                <thead>
                                    <tr className={`${styles['border-top-bottom']}`}>
                                        <th>{t('spareName')}</th>
                                        <th>{t('createdOn')}</th>
                                        <th>{t('supplier')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {procurements.map((spare) => (
                                        <tr key={spare.hcode} className={`${styles['row-hover']}`} onClick={() => {
                                            setShowSparesDetail(true); setSpareName(spare.spare_name);
                                            if (spare.hasOwnProperty('dbid')) {
                                                window.NOP_VIEWER.clearThemingColors();
                                                const colors = [new window.THREE.Vector4(0, 0, 1, 1)]
                                                const randomIndex = Math.floor(Math.random() * colors.length);
                                                spare.dbid.forEach(dbid => {
                                                    const randomColor = colors[randomIndex];
                                                    window.NOP_VIEWER.setThemingColor(dbid, randomColor, null, true);
                                                });
                                                window.NOP_VIEWER.fitToView(spare.dbid);
                                            }

                                        }}>
                                            <td style={{ cursor: 'pointer' }} onDoubleClick={() => handleEditSpareProcurement(spare)}>{spare.spare_name}</td>
                                            <td >{spare.purchase_date.split('T')[0]}</td>
                                            <td >{spare.supplier}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
                : <>{
                    load ? <div id="content" className='w-100'>
                        <div className="ps-2">
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('loading')}...</div>
                        </div>
                    </div> : <div id="content" className='w-100'>
                        <div className="ps-2 mt-1">
                            <span className='fw-bold fs-5' style={{ color: '#121212' }}>{t('link')} : </span><Link className={`${styles['btn-style']}   btn`} to='https://spareparts.mogroup.com' target='_blank' style={{ textDecoration: 'none', color: '#ffffff', border: '1px solid #121212' }}>{t('sparePartList')}</Link>
                            <div className='w-100 text-center h1' style={{ color: '#121212' }}>{t('nothingToShow')}</div>
                        </div>
                    </div>
                }</>
            }
            {
                showModal && <ProcurementModal setActiveTab={setActiveTab} hcode={hcode} closeModal={closeModal} showModal={showModal} handleAddedSpare={handleAddedSpare}></ProcurementModal>
            }
            {
                showEditModal && <ProcurementEditModal spare={selectedSpareProcurement} closeEditModal={closeEditModal} showEditModal={showEditModal} setChange={setChange}></ProcurementEditModal>
            }
            {
                showSparesDetail && <SpareDetailModal showViewModal={showSparesDetail} closeViewModal={closeSpareDetailModal} spareName={spareName}></SpareDetailModal>
            }
        </>
    );
}


export default ViewerProcurement;
