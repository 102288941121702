import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function SearchComponent({ selectedZone }) {
    const { t } = useTranslation();
    const { plantHcode } = useParams();
    const [searchOptions, setSearchOptions] = useState({
        searchBy: 'tag',
        searchTerm: '',
        searchIn: '1',
        zone: selectedZone ? selectedZone.hcode : '',
    });

    useEffect(() => {
        setSearchOptions((prevSearchOptions) => ({
            ...prevSearchOptions,
            zone: selectedZone ? selectedZone.hcode : '',
        }));
    }, [selectedZone]);

    const [listAssets, setListAssets] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");


    const handleSearchOptionChange = (event) => {
        const { name, value, type, checked } = event.target;
        setSearchOptions((prevSearchOptions) => ({
            ...prevSearchOptions,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleMouseEnter = (assetID) => {
        window.NOP_VIEWER.fitToView([assetID]);
    }


    useEffect(() => {
        if (listAssets.length !== 0) {
            const selectedId = [];
            listAssets.forEach((asset) => {
                if (asset.assetID) {
                    selectedId.push(parseInt(asset.assetID));
                }
            })
            window.NOP_VIEWER.isolate(selectedId)
            window.NOP_VIEWER.fitToView(selectedId);

        }
    }, [listAssets])

    const handleSearch = async () => {
        if (searchOptions.searchTerm.trim() !== '') {
            const resp = await fetch(`${SERVER_URL}/filter/asset/${plantHcode}?hcode=${localStorage.getItem('hcode')}`, {
                method: 'POST',
                body: JSON.stringify(
                    searchOptions
                ),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await resp.json()
            if (data.statusCode === 200) {
                setListAssets(data.assets)
            }
        } else {
            alert('Search term is empty. Please enter a search term.');
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredAssets = listAssets.filter((asset) => {
        const a = `${asset.aname} - ${asset.alias_name}`;
        return a.toLowerCase().includes(searchQuery.toLowerCase())
    }
    );

    return (
        <>
            <div className='m-1 border p-2 border-dark'>
                <div className='mb-2'>
                    <label>
                        <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{t('searchBy')}:</span>
                        <select
                            name="searchBy"
                            value={searchOptions.searchBy}
                            onChange={handleSearchOptionChange}
                            className="form-select"
                        >
                            <option value="tag">{t('tag')}</option>
                            <option value="description">{t('description')}</option>
                        </select>
                    </label>
                </div>
                <div className="mb-2">
                    <label>
                        <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{t('searchTerm')}:</span>
                        <input
                            type="text"
                            name="searchTerm"
                            value={searchOptions.searchTerm}
                            onChange={handleSearchOptionChange}
                            className="form-control"
                        />
                    </label>
                </div>
                <div className="mb-2">
                    <label style={{ fontWeight: 'bold' }}>{t('searchIn')}:</label>
                    <br></br>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="1" name="searchIn" checked={searchOptions.searchIn === '1'}
                                onChange={handleSearchOptionChange} id='equipmentCheck' />
                            <label class="form-check-label" >{t('equipment')}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="2" name="searchIn" checked={searchOptions.searchIn === '2'}
                                onChange={handleSearchOptionChange} id='pipeCheck' />
                            <label class="form-check-label" >{t('pipes')}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="0" name="searchIn" checked={searchOptions.searchIn === '0'}
                                onChange={handleSearchOptionChange} id='allCheck' />
                            <label class="form-check-label" >{t('all')}</label>
                        </div>
                    </div>
                </div>
                <div className="ms-2">
                    <label style={{ fontWeight: 'bold' }}>{t('miscellaneous')}:</label>
                    <br></br>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="4" name="searchIn" checked={searchOptions.searchIn === '4'}
                                onChange={handleSearchOptionChange} id='allCheck' />
                            <label class="form-check-label" >{t('valve')}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="3" name="searchIn" checked={searchOptions.searchIn === '3'}
                                onChange={handleSearchOptionChange} id='instrumentCheck' />
                            <label class="form-check-label" >{t('instruments')}</label>
                        </div>
                    </div>
                </div>
                <button className="btn btn-dark" onClick={handleSearch}>
                    {t('search')}
                </button>
            </div>

            {listAssets.length !== 0 && (
                <div className='border border-dark p-1 m-1'>
                    <h2>{t('searchResult')}</h2>
                    <div className="mb-3 ms-1">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="form-control"
                        />
                    </div>
                    <ul style={{
                        listStyle: 'none',
                        padding: '0',
                        overflow: 'auto', // Add this to enable horizontal and/or vertical scrolling
                        maxHeight: '250px', // Optional: Set a maximum height to enable vertical scrolling if needed
                        maxWidth: '100%', // Optional: Set a maximum width to enable horizontal scrolling if needed
                    }}>
                        {filteredAssets.map((asset) => (
                            <li key={asset.assetID} className='m-1 border border-dark p-1 rounded'
                                onMouseEnter={(e) => {
                                    handleMouseEnter(asset.assetID);
                                    e.target.style.backgroundColor = 'lightgray';
                                    e.target.style.cursor = 'pointer';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'white';
                                    e.target.style.cursor = 'default';
                                    e.target.style.color = 'black';

                                }}
                                style={{ transition: 'background-color 0.3s' }}
                                onClick={(e) => {
                                    const selectedId = [parseInt(asset.assetID)];
                                    window.NOP_VIEWER.select(selectedId);
                                    //external code
                                    if (selectedId[0] == 298435) {
                                        window.NOP_VIEWER.isolate([...selectedId, 366484, 646148]);
                                    }
                                    e.target.style.backgroundColor = 'black';
                                    e.target.style.color = 'white';
                                }}
                            >
                                {asset.aname} - {asset.alias_name}
                            </li>
                        ))}

                    </ul>
                </div>
            )}
            {listAssets.length === 0 && (
                <div className='border border-dark p-1 m-1'>
                    <h2>{t('searchResult')}</h2>
                    <div className="mb-3 ms-1">
                        <input
                            type="text"
                            placeholder={`${t('search')}...`}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="form-control"

                        />
                    </div>

                    <span>{t('noResultFound')}</span>
                </div>
            )}
        </>
    );
}

export default SearchComponent;
