/// import * as Autodesk from "@types/forge-viewer";


import Header from '../../components/header/Header'
import Footer from "../../components/footer/Footer";
import Wrapper from "../../components/wrapper/wrapper";
import AppContext from '../../context/loginContext';
import { useContext, useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import MainViewer from '../../components/viewer/MainViewer';
import ViewerListFiles from '../../components/tables/filetables/ViewerFileList';
import ViewerMeridianFiles from '../../components/tables/filetables/ViewerMeridianFiles';
import ViewerSensors from '../../components/Lists/ViewerSensorLists';
import ViewerMaintenance from '../../components/tables/maintenancetable/maintenanceViewer';
import ViewerProcurement from '../../components/tables/procurementtable/procurementsViewer';
import Sidebars from '../../components/sidebar/sidebar';
import style from './analyticPageStyle/analytic.module.css'
import './analyticPageStyle/styles.css';
import styles from '../../common/common.module.css';
import { PDFViewer } from '../../components/pdfViewer/PDFViewer';
import SearchComponent from '../../components/search/SearchComponent';
import ViewerProperties from '../../components/properties/Properites';
import OverlayWithTabs from '../../components/overlays/overlaysWithTabs';
import ConnectedFileListViewer from '../../components/tables/filetables/ConnectedFileListViewer';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;



function DashAnalytics(prop) {

    const { t, i18n } = useTranslation();
    const modelLang = localStorage.getItem('modelLang');

    useEffect(() => {

    }, [modelLang]);

    const pdfFiles = [
        { name: 'Operation And Maintenance Manual.pdf', label: t('operationAndMaintenanceManual') }
    ];

    const videoFiles = [{ name: 'Animated Safety Training_ Near Miss - Helmet.mp4', label: t('trainingVideo') }]
    const [activeTab, setActiveTab] = useState('docs');
    const [selectedPart, setSelectedPart] = useState(null);


    const { isLogin, setIsLogin } = useContext(AppContext);
    const navigate = useNavigate();
    const { screenCode } = prop

    const { plantHcode, plant_name } = useParams();
    const today = new Date().toISOString().split("T")[0];
    const [fromDate, setFromDate] = useState("2023-07-03");
    const [toDate, setToDate] = useState("2023-07-03");
    const [startDate, setStartDate] = useState("2023-07-03");
    const [endDate, setEndDate] = useState("2023-07-03");
    const [zones, setZones] = useState([]);
    const [assets, setAssets] = useState(null);
    const [pipes, setPipes] = useState(null);
    const [instruments, setInstruments] = useState(null);
    const [valves, setValves] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedPipe, setSelectedPipe] = useState(null);
    const [selectedInstrument, setSelectedInstrument] = useState(null);
    const [selectedValve, setSelectedValve] = useState(null);
    const [activeSubTab, setActiveSubTab] = useState('Live');
    const [activeDocSubTab, setActiveDocSubTab] = useState('Local');
    const [visible, setVisible] = useState(false);
    const [fname, setFname] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const [small, setSmall] = useState(false);
    const [heatMap, setHeatMap] = useState(false);
    const [access, setAccess] = useState([false, false, false, false, false])
    const [modelReady, setModelReady] = useState(false);
    const [modelStatus, setModelStatus] = useState('');
    const [connectedAssets, setConnectedAssets] = useState([]);
    const [connectedDocs, setConnectedDocs] = useState(false);
    const [asset_name, setAsset_name] = useState("");

    // 0 - document , 1- training, 2-Spares, 3-Maintainence,4-monitoring 

    const params = new URLSearchParams(window.location.search);
    const urn = params.get('urn');

    useEffect(() => {
        const element = document.getElementById('ViewerModelStructurePanel');
        const elementTip = document.getElementById('toolbar-modelStructureTool');
        if (elementTip) {
            const displayStyle = window.getComputedStyle(element).display;
            if (displayStyle === 'none') {
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                });
                elementTip.dispatchEvent(clickEvent);
            }
        }

    }, [selectedPart]);


    const checkAccess = async () => {
        const response = await fetch(`${SERVER_URL}/plant/modules/${plantHcode}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            // Create a copy of the previous access array to avoid mutating state directly
            const updatedAccess = [...access];

            for (const i of data.modules) {
                if (i.mname === 'TRAINING') {
                    updatedAccess[1] = true;
                } else if (i.mname === 'DOCUMENTATION') {
                    updatedAccess[0] = true;
                } else if (i.mname === 'SPARES') {
                    updatedAccess[2] = true;
                } else if (i.mname === 'MAINTENANCE') { // Corrected the typo here
                    updatedAccess[3] = true;
                } else if (i.mname === 'MONITORING') { // Corrected the typo here
                    updatedAccess[4] = true;
                }
            }

            // Update the state with the modified access array
            setAccess(updatedAccess);
        }
    }

    useEffect(() => {
        checkAccess();
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setSmall(true);
        } else {
            setSmall(false);
        }
    }, [window.innerWidth])

    useEffect(() => {
        if (!heatMap) {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            try {
                window.NOP_VIEWER.clearThemingColors();
            } catch (err) {
                console.log(err);
            }
        }
    }, [heatMap]);

    useEffect(() => {

        if (intervalId) {
            clearInterval(intervalId);
            window.NOP_VIEWER.clearThemingColors();

        }

        const updateColors = () => {
            if (assets) {
                if (selectedAsset === null && selectedZone !== null && heatMap) {

                    assets.map((asset) => {
                        if (!asset.aname.includes('SE') && !asset.aname.includes('FL')) {
                            const colors = [new window.THREE.Vector4(1, 0, 0, 1), new window.THREE.Vector4(0, 0, 1, 1)]
                            const randomIndex = Math.floor(Math.random() * colors.length);
                            window.NOP_VIEWER.setThemingColor(parseInt(asset.assetID), colors[randomIndex], null, true);
                        }
                    });
                }
            }

        };
        if (selectedZone !== null && selectedAsset === null && heatMap) {
            updateColors();
            const id = setInterval(updateColors, 1000);
            setIntervalId(id);
        }

    }, [assets, selectedAsset, selectedZone, heatMap]);



    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStartDate(fromDate);
        setEndDate(toDate);
    }

    const handleZoneHover = async (hcode) => {
        const selectedId = [];
        // Perform fetch function based on the selected zone
        const response = await fetch(`${SERVER_URL}/allassets/${hcode}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            // Push assetID values into selectedId array
            data.assets.forEach((asset) => {
                if (asset.assetID) {
                    selectedId.push(parseInt(asset.assetID));
                }
            });

            // Call NOP_VIEWER functions when selectedId is filled
            if (selectedId.length > 0) {
                window.NOP_VIEWER.isolate(selectedId);
                window.NOP_VIEWER.fitToView(selectedId);
            }

        }
    };


    const handleZoneSelect = async (zone) => {
        window.NOP_VIEWER.select();
        if (intervalId !== null) {
            clearInterval(intervalId);
            window.NOP_VIEWER.clearThemingColors();
        }

        setSelectedZone(zone);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        // Create two Promises for the two API requests
        const request1 = fetch(`${SERVER_URL}/assets/${zone.hcode}?hcode=${localStorage.getItem('hcode')}`);
        const request2 = fetch(`${SERVER_URL}/pipes/${zone.hcode}?hcode=${localStorage.getItem('hcode')}`);
        const request3 = fetch(`${SERVER_URL}/instruments/${zone.hcode}?hcode=${localStorage.getItem('hcode')}`);
        const request4 = fetch(`${SERVER_URL}/valves/${zone.hcode}?hcode=${localStorage.getItem('hcode')}`);

        try {
            // Use Promise.all to execute both requests concurrently
            const [data1, data2, data3, data4] = await Promise.all([request1, request2, request3, request4].map(async (request) => {
                const response = await request;
                return response.json();
            }));

            // Check the statusCode for both responses
            if (data1.statusCode === 200) {
                setAssets(data1.assets);
            }

            if (data2.statusCode === 200) {
                setPipes(data2.assets);
            }

            if (data3.statusCode === 200) {
                setInstruments(data3.assets);
            }

            if (data4.statusCode === 200) {
                setValves(data4.assets);
            }

        } catch (error) {
            // Handle any errors that may occur during the requests
            console.error("An error occurred:", error);
        }
    };

    const handleAssetHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleInstrumentHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.forgeID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleValveHover = (asset) => {
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.valveID)];

        window.NOP_VIEWER.fitToView(selectedId);
    };

    const handleAssetSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedAsset(asset);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];
        window.NOP_VIEWER.isolate(selectedId);
        window.NOP_VIEWER.select(selectedId);
    };


    const getPipeConnections = async (assetCode, type = 0) => {
        const response = await fetch(`${SERVER_URL}/pipe/connections/${assetCode}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();

        const connectedEquipments = data.result.connectedEquipments;
        const allEquipmentIDs = data.result.allEquipmentIDs;

        // Function to get unique objects based on a property
        function getUniqueObjects(array, propertyName) {
            return array.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t[propertyName] === obj[propertyName]
                ))
            );
        }

        // Usage
        const uniqueObjects = getUniqueObjects(allEquipmentIDs, 'hcode');

        // const connectedEquipments = [];
        // const allEquipmentIDs = [];
        // if (data.statusCode === 200) {
        //     // Push assetID values into selectedId array
        //     data.connections.forEach((asset) => {
        //         if (asset.pipeDBID) {
        //             allEquipmentIDs.push(asset.pipe_id_fk);
        //             connectedEquipments.push(parseInt(asset.pipeDBID));
        //         }
        //         if (asset.equiFromDBID) {
        //             allEquipmentIDs.push(asset.equipment_from_id_fk);
        //             connectedEquipments.push(parseInt(asset.equiFromDBID));
        //         }
        //         if (asset.equiToDBID) {
        //             allEquipmentIDs.push(asset.equipment_to_id_fk);
        //             connectedEquipments.push(parseInt(asset.equiToDBID));
        //         }
        //     });
        // }

        // Convert the array to a Set to remove duplicates
        const uniqueEquipmentDBIDs = new Set(connectedEquipments);
        const uniqueEquipmentIDs = new Set(allEquipmentIDs);

        // Convert the Set back to an array if necessary
        const uniqueArray = Array.from(uniqueEquipmentDBIDs);
        let uniqueEquipments = Array.from(uniqueEquipmentIDs);

        // Function to remove an equipment based on assetCode
        function removeEquipmentByAssetCode(array, codeToRemove) {
            return array.filter(item => item.hcode !== codeToRemove);
        }
        // type 0 is asset and pipe
        if (type == 0) {
            // Remove the equipment with the specified asset code
            uniqueEquipments = removeEquipmentByAssetCode(uniqueEquipments, assetCode);
        }

        setConnectedAssets(uniqueEquipments);
        // setConnectedAssets(uniqueObjects);

        return uniqueArray;
    }

    const getPartName = async () => {
        return new Promise((resolve, reject) => {
            window.NOP_VIEWER.getProperties(selectedPart, (data) => {
                const partName = data.name;
                resolve(partName);
            }, reject);
        });
    }


    useEffect(() => {
        const fetchData = async () => {
            if (selectedPart) {
                setActiveTab('docs');
                setActiveDocSubTab('Local');
                const partName = await getPartName();
                const resp = await fetch(`${SERVER_URL}/getEquipment/${plantHcode}/${selectedPart}?part_name=${partName}&hcode=${localStorage.getItem('hcode')}`);
                const data = await resp.json();
                if (data.statusCode === 200) {
                    setAsset_name(data.equipment.aname);
                    let connectedEqui = [];
                    if (data.type == 1 || data.type == 2) {
                        connectedEqui = await getPipeConnections(data.equipment.hcode);
                    } else {
                        if (data.equipment.parenthcode) {
                            connectedEqui = await getPipeConnections(data.equipment.parenthcode, data.type);
                        } else {
                            setConnectedAssets([]);
                        }
                    }
                    window.NOP_VIEWER.isolate([parseInt(data.equipment.assetID), ...connectedEqui]);
                    window.NOP_VIEWER.fitToView([parseInt(data.equipment.assetID), ...connectedEqui]);
                }
            }
        };

        fetchData();

    }, [selectedPart]);


    const handlePipeSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedPipe(asset);
        setSelectedAsset(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.assetID)];

        window.NOP_VIEWER.isolate(selectedId);
        window.NOP_VIEWER.select(selectedId);
    };


    const handleInstrumentSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedInstrument(asset);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedValve(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.forgeID)];
        window.NOP_VIEWER.isolate(selectedId);
        window.NOP_VIEWER.select(selectedId);
    };

    const handleValveSelect = async (asset) => {
        if (intervalId !== null) {
            window.NOP_VIEWER.clearThemingColors();
            clearInterval(intervalId);
        }
        setHeatMap(false);
        setSelectedValve(asset);
        setSelectedInstrument(null);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setConnectedDocs(false);
        setVisible(false);
        setActiveTab('docs');
        setActiveDocSubTab('Local');
        // Call NOP_VIEWER functions when selectedId is filled
        const selectedId = [parseInt(asset.valveID)];
        window.NOP_VIEWER.isolate(selectedId);
        window.NOP_VIEWER.select(selectedId);
    };

    const fetchZones = async () => {
        const response = await fetch(`${SERVER_URL}/listOfZones/${plant_name}?hcode=${localStorage.getItem('hcode')}`);
        const data = await response.json();
        if (data.statusCode === 200) {
            setZones(data.zones);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
        fetchZones();
    }, []);


    useEffect(() => {
        if (localStorage.getItem('hcode') === null || isLogin === false) {
            localStorage.removeItem("hcode");
            setIsLogin(false);
            navigate('/login');
        }
    }, []);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleSubTab = (tab) => {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    };

    const toggleDocSubTab = (tab) => {
        if (activeSubTab !== tab) {
            setActiveDocSubTab(tab);
        }
    };

    const handleClick = () => {
        setHeatMap(prev => !prev);
    };

    const modelDetail = async () => {
        const resp = await fetch(`${SERVER_URL}/manifest/${urn}?hcode=${localStorage.getItem('hcode')}`)
        const data = await resp.json();
        console.log(data)
        setModelStatus(data.resp.progress);
        if (data.statusCode === 200) {
            if (data.resp.progress === 'complete') {
                setModelReady(true);
            } else {
                setTimeout(() => {
                    modelDetail();
                }, 10)
            }
        }
    }

    useEffect(() => {
        modelDetail();
    }, [])

    const handleConnectedDocumentsClick = () => {
        setConnectedDocs(true);
    }

    const handleBackClick = () => {
        setConnectedDocs(false);
    }

    const handleClearAll = () => {
        setSelectedPart(null);
        setSelectedAsset(null);
        setSelectedPipe(null);
        setSelectedInstrument(null);
        setSelectedValve(null);
        setSelectedZone(null);
        window.NOP_VIEWER.select(null);
        window.NOP_VIEWER.fitToView([1]);
        window.NOP_VIEWER.showAll();
    }

    return (
        <Wrapper>
            <Header />
            {/* <Sidebars> */}
            {modelReady ? (<>
                <div className="d-flex ms-2 me-3 mt-1 justify-content-between">
                    <div className='d-flex flex-wrap gap-3'>
                        <DropdownButton id="dropdownMenuLink" title={selectedZone ? selectedZone.zoneID + ' - ' + selectedZone.zname.slice(0, 15) : t('area')} variant='secondary'>
                            {zones.map((zone) => (
                                <Dropdown.Item key={zone.hcode} onClick={() => handleZoneSelect(zone)} onMouseLeave={() => handleZoneHover(null)} onMouseEnter={() => { handleZoneHover(zone.hcode) }}>{zone.zoneID + ' - ' + zone.zname}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                        {selectedZone && assets &&
                            <DropdownButton id="dropdownMenuLink" title={selectedAsset ? selectedAsset.aname.slice(1) : t('assets')} variant='secondary'>
                                {assets.length === 0 ?
                                    <Dropdown.Item key={"No Data"}>{t('noAssetsFromThisZone')}</Dropdown.Item> :
                                    assets.map((asset) => (
                                        <Dropdown.Item key={asset.assetID} onClick={() => handleAssetSelect(asset)} onMouseEnter={() => { handleAssetHover(asset) }} >{asset.alias_name} ({asset.aname.slice(1)})</Dropdown.Item>
                                    ))
                                }
                            </DropdownButton>
                        }
                        {selectedZone && pipes &&
                            <DropdownButton id="dropdownMenuLink" title={selectedPipe ? selectedPipe.aname.slice(1) : t('pipes')} variant='secondary'>
                                {pipes.length === 0 ?
                                    <Dropdown.Item key={"No Data"}>{t('noPipesFromThisZone')}</Dropdown.Item> :
                                    pipes.map((asset) => (
                                        <Dropdown.Item key={asset.assetID} onClick={() => handlePipeSelect(asset)} onMouseEnter={() => { handleAssetHover(asset) }} >{asset.alias_name} ({asset.aname.slice(1)})</Dropdown.Item>
                                    ))}
                            </DropdownButton>
                        }
                        {selectedZone && instruments &&
                            <DropdownButton id="dropdownMenuLink" title={selectedInstrument ? selectedInstrument.instrument_tag.slice(1) : t('instruments')} variant='secondary' >
                                {instruments.length === 0 ?
                                    <Dropdown.Item key={"No Data"}>{t('noInstrumentsFromThisZone')}</Dropdown.Item> :
                                    instruments.map((asset) => (
                                        <Dropdown.Item key={asset.forgeID} onClick={() => handleInstrumentSelect(asset)} onMouseEnter={() => { handleInstrumentHover(asset) }} >{asset.instrument_desc} ({asset.instrument_tag.slice(1)})</Dropdown.Item>
                                    ))}
                            </DropdownButton>
                        }
                        {selectedZone && valves &&
                            <DropdownButton id="dropdownMenuLink" title={selectedValve ? selectedValve.valve_tag.slice(1) : t('valve')} variant='secondary' >
                                {valves.length === 0 ?
                                    <Dropdown.Item key={"No Data"}>{t('noValvesFromThisZone')}</Dropdown.Item> :
                                    valves.map((asset) => (
                                        <Dropdown.Item key={asset.valveID} onClick={() => handleValveSelect(asset)} onMouseEnter={() => { handleValveHover(asset) }} >{asset.valve_desc} ({asset.valve_tag.slice(1)})</Dropdown.Item>
                                    ))
                                }
                            </DropdownButton>
                        }
                        {/* {selectedZone && !selectedAsset &&
                                <button
                                    className={`${styles['btn-style']}   btn`}
                                    onClick={() => handleClick()}
                                >
                                    Heat Map
                                </button>
                            } */}
                        {(selectedZone || selectedPart) &&
                            <button
                                className={`${styles['btn-style']}   btn`}
                                onClick={() => handleClearAll()}
                            >
                                {t('clearAll')}
                            </button>
                        }
                    </div>

                    {/* <div><OverlayWithTabs></OverlayWithTabs></div> */}
                </div>
                <div className='d-flex flex-row flex-wrap w-100' style={{ minHeight: '80vh' }}>
                    {small ? <MainViewer width={'95%'} height={'80vh'} setSelectedPart={setSelectedPart} heatMap={heatMap}></MainViewer>
                        : <MainViewer width={'63%'} height={'80vh'} setSelectedPart={setSelectedPart} heatMap={heatMap}></MainViewer>
                    }
                    <div className='ms-2 mt-2  overflow-auto p-1' style={small ? { height: '80vh', boxShadow: '0 2px 4px rgba(0, 0,0, 0.2)', width: '100%', marginBottom: '7px' } : {
                        height: '80vh', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', width: '35%'
                    }}>
                        <ul className='d-flex flex-row flex-wrap nav nav-tabs mb-3'>
                            {access[0] && <li className='nav-item'>
                                <a
                                    className={`nav-link ${activeTab === 'docs' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('docs')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('docs')}
                                </a>
                            </li>}
                            {access[4] && <li className='nav-item'>
                                <a
                                    className={`nav-link ${activeTab === 'Performance' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('Performance')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('assetMonitoring')}
                                </a>
                            </li>}
                            {access[2] && <li className='nav-item'>
                                <a
                                    className={`nav-link ${activeTab === 'purchase' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('purchase')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('spares')}
                                </a>
                            </li>}
                            {access[3] && <li className={`nav-item`}>
                                <a
                                    className={`nav-link ${activeTab === 'Maintenance' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('Maintenance')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('spareHistory')}
                                </a>
                            </li>}
                            {access[1] && <li className={`nav-item`}>
                                <a
                                    className={`nav-link ${activeTab === 'Training' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('Training')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('trainingModule')}
                                </a>
                            </li>}
                            <li className={`nav-item`}>
                                <a
                                    className={`nav-link ${activeTab === 'Search' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('Search')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('search')}
                                </a>
                            </li>
                            <li className={`nav-item`}>
                                <a
                                    className={`nav-link ${activeTab === 'Properties' ? `${style.unique} active` : `${style['new-color']}`}`}
                                    onClick={() => toggleTab('Properties')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t('properties')}
                                </a>
                            </li>
                            {visible &&
                                <li className='nav-item'>
                                    <a
                                        className={`nav-link ${activeTab === 'PDFViewer' ? `${style.unique} active` : `${style['new-color']}`}`}
                                        onClick={() => toggleTab('PDFViewer')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {t('pdfViewer')}
                                    </a>
                                </li>
                            }

                        </ul>
                        <div className='tab-content overflow-auto' style={{ Height: '80vh' }} >
                            <div
                                className={`tab-pane ${activeTab === 'docs' ? 'active' : ''}`}
                                role='tabpanel'
                            >
                                {selectedPart &&
                                    <div className="d-flex gap-3 m-2">
                                        <button className={`btn ${activeDocSubTab === 'Local' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleDocSubTab('Local')}>{selectedPart ? asset_name : selectedAsset.aname}</button>
                                        <button className={`btn ${activeDocSubTab === 'Meridian' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleDocSubTab('Meridian')}>{t('connectedComponents')}</button>
                                    </div>
                                }
                                {/* {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Local' &&
                                        <>
                                            {
                                                connectedDocs ?
                                                    <div>
                                                        <a className="p-1" style={{ cursor: 'pointer', textDecoration: 'none', backgroundColor: '#a6a6a6', color: '#000000' }} onClick={handleBackClick}>Go Back to {selectedPart ? asset_name : selectedAsset.aname}</a>
                                                        <br />
                                                        <ConnectedFileListViewer selectedPart={selectedPart} connectedAssets={connectedAssets}></ConnectedFileListViewer>
                                                    </div>
                                                    :
                                                    <div>
                                                        <a className="p-1" id="clickable" style={{ cursor: 'pointer' }} onClick={handleConnectedDocumentsClick}>
                                                            Click here!!!
                                                        </a>
                                                        <Tooltip anchorSelect="#clickable" clickable place={'right'}>
                                                            <p>Click to view files of connected components</p>
                                                        </Tooltip>
                                                        <br />
                                                        <ViewerListFiles selectedPart={selectedPart} connectedAssets={connectedAssets} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname}></ViewerListFiles>
                                                    </div>
                                            }
                                        </>
                                    } */}
                                {activeTab === 'docs' && activeDocSubTab === 'Local' &&
                                    <ViewerListFiles selectedPart={selectedPart} connectedAssets={connectedAssets} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname}></ViewerListFiles>
                                }
                                {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Meridian' &&
                                    <ConnectedFileListViewer selectedPart={selectedPart} connectedAssets={connectedAssets}></ConnectedFileListViewer>
                                }
                                {/* {selectedPart && activeTab === 'docs' && activeDocSubTab === 'Meridian' && <ViewerMeridianFiles selectedPart={selectedPart} setActiveTab={setActiveTab} visible={visible} setVisible={setVisible} setFname={setFname}></ViewerMeridianFiles>} */}
                                {/* {!selectedPart && <h4 style={{ color: '#121212' }}>Select Part in Model</h4>} */}
                                {/* Add your content for the 'Docs' tab here */}
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'Performance' ? 'active' : ''}`}
                                role='tabpanel'
                            >
                                <div className="d-flex gap-3 mb-2">
                                    <button className={`btn ${activeSubTab === 'Live' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleSubTab('Live')}>{t('live')}</button>
                                    <button className={`btn ${activeSubTab === 'Date' ? `${style['btn-style']}` : `${style['sub-color']}`}`} onClick={() => toggleSubTab('Date')}>{t('date')}</button>
                                </div>
                                {
                                    activeSubTab === 'Date' ?

                                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                                            <div className="input-group w-100 d-flex flex-column gap-2">
                                                <div className='d-flex gap-2'>
                                                    <span className="input-group-text w-25">{t('dateFrom')}</span>
                                                    <input
                                                        type="date"
                                                        aria-label="From date"
                                                        className="form-control w-75"
                                                        value={fromDate}
                                                        onChange={handleFromDateChange}
                                                        max={today}
                                                        required
                                                    />
                                                </div>
                                                <div className='d-flex gap-2'>
                                                    <span className="input-group-text w-25 text-center">{t('to')}</span>
                                                    <input
                                                        type="date"
                                                        aria-label="To date"
                                                        className="form-control w-75"
                                                        value={toDate}
                                                        onChange={handleToDateChange}
                                                        max={today}
                                                        min={fromDate}
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className='btn '>{t('fetchData')}</button>
                                            </div>
                                        </form> : <> </>
                                }

                                {selectedPart && activeTab === 'Performance' ? <ViewerSensors activeSubTab={activeSubTab} selectedPart={selectedPart} startDate={startDate} endDate={endDate} ></ViewerSensors> : <h4 style={{ color: '#121212' }}>{t('selectPartInModel')}</h4>}
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'purchase' ? 'active' : ''}`}
                                role='tabpanel'
                            >
                                {selectedPart && activeTab === 'purchase' ? <ViewerProcurement selectedPart={selectedPart} setActiveTab={setActiveTab}></ViewerProcurement> : <h4 style={{ color: '#121212' }}>{t('selectPartInModel')}</h4>}

                                {/* Add your content for the 'Purchase' tab here */}
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'Maintenance' ? 'active' : ''}`}
                                role='tabpanel'
                            >
                                {selectedPart && activeTab === 'Maintenance' ? <ViewerMaintenance selectedPart={selectedPart} setActiveTab={setActiveTab}></ViewerMaintenance> : <h4 style={{ color: '#121212' }}>{t('selectPartInModel')}</h4>}
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'Training' ? 'active' : ''}`}
                                role='tabpanel'
                            >
                                {activeTab === 'Training' && <div>
                                    <ul className="list-group">
                                        {pdfFiles.map((file, index) => (
                                            <li key={index}
                                                className='d-flex justify-content-between pe-2 list-group-item w-100  align-items-center'
                                                style={{ color: '#121212', backgroundColor: 'transparent', borderColor: '#121212' }}>
                                                <a href={`${process.env.PUBLIC_URL}/pdf/${file.name}`} style={{ color: '#121212', textDecoration: 'none' }}
                                                    target="_blank" rel="noopener noreferrer">
                                                    {file.label}
                                                </a>
                                                <a href={`${process.env.PUBLIC_URL}/pdf/${file.name}`} className={`${styles['btn-style']}   btn w-25`} download>
                                                    {t('download')}
                                                </a>
                                            </li>
                                        ))}
                                        {videoFiles.map((video, index) => (

                                            <li key={index}
                                                className='d-flex flex-column align-items-center pe-2 list-group-item w-100'
                                                style={{ color: '#121212', backgroundColor: 'transparent', borderColor: '#121212' }}>
                                                <h6>{video.label}</h6>

                                                <video width="320" height="240" controls>
                                                    <source src={`${process.env.PUBLIC_URL}/videos/${video.name}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'PDFViewer' ? 'active' : ''}`}
                                role='tabpanel'
                                style={{ minHeight: '55vh', width: '100%' }}
                            >
                                <PDFViewer fname={fname}></PDFViewer>
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'Search' ? 'active' : ''}`}
                                role='tabpanel'
                                style={{ minHeight: '55vh', width: '100%' }}
                            >
                                <SearchComponent selectedZone={selectedZone}></SearchComponent>
                            </div>
                            <div
                                className={`tab-pane ${activeTab === 'Properties' ? 'active' : ''}`}
                                role='tabpanel'
                                style={{ minHeight: '55vh', width: '100%' }}
                            >
                                {/* <ViewerProperties selectedPart={selectedPart} ></ViewerProperties> */}

                                {activeTab === 'Properties' && <ViewerProperties selectedPart={selectedPart} ></ViewerProperties>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            ) : (<div className='w-100 h-100 d-flex justify-content-center' style={{ alignItems: 'center' }}>
                <div className='w-100'>
                    <h2 className='text-center' style={{ width: '90%' }}>{t('modelBeingTranslated')} {modelStatus}</h2><br />
                </div>
            </div>)}
            {/* </Sidebars> */}
            <Footer screenCode={screenCode} />
        </Wrapper>
    )
}

export default DashAnalytics;