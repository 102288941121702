import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, plantHCode) => {

    const response = await fetch(`${SERVER_URL}/plants/update/${plantHCode}?hcode=${localStorage.getItem('hcode')}`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const PlantModal = ({ plant, showModal, closeModal, change }) => {
    const { t } = useTranslation();

    const plantHCode = plant.hcode;
    const [plantData, setPlantData] = useState(plant);
    const [accounts, setAccounts] = useState([]);

    const fetchAccounts = async () => {
        const response = await fetch(`${SERVER_URL}/listOfAccounts?hcode=${localStorage.getItem('hcode')}`, { mode: 'cors' });
        const data = await response.json();
        setAccounts(data.accounts);
    };

    useEffect(() => {
        fetchAccounts();
        setPlantData(plant);
    }, [plant]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPlantData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleSaveChanges = async () => {
        const response = await fetchData(plantData, plantHCode);
        const data = await response.json()
        closeModal();
        change(response);
    };



    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>{t('editPlant')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form >
                    <Form.Group controlId="plantName">
                        <Form.Label>{t('plantName')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="plant_name"
                            value={plantData.plant_name}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="pdesc">
                        <Form.Label>{t('plantDesc')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="pdesc"
                            value={plantData.pdesc}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="createdOn">
                        <Form.Label>{t('createdOn')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_on"
                            value={plantData.created_on}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="createdBy">
                        <Form.Label>{t('createdBy')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_by"
                            value={plantData.created_by}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId="accountName">
                        <Form.Label>{t('accountName')}</Form.Label>
                        <Form.Select
                            name="acct_name"
                            value={plantData.acct_name}
                            onChange={handleInputChange}
                            required
                        >
                            <option>Select a Account</option>
                            {accounts.map((account) => (
                                <option key={account.hcode} value={account.acct_name}>
                                    {account.acct_name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={closeModal}>
                    {t('close')}
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                    save change
                </Button>
            </Modal.Footer>
        </Modal>
    );
};




export default PlantModal;