import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Modal, Button } from 'react-bootstrap';
import style from './../modal/style/modal.module.css';
import { useParams } from 'react-router-dom';
import styles from '../../common/common.module.css';
import FileDownloadButton from './downloadfile';
import { useTranslation } from 'react-i18next';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const UploadPropertyExcel = () => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { plant_name } = useParams();

    const handlePropertyUpload = async () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            for (const sheetName of workbook.SheetNames) {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                let headers;
                for (let rowIndex = 0; rowIndex < jsonData.length; rowIndex++) {
                    if (rowIndex + 1 === jsonData.length) {

                    }
                    if (rowIndex === 0) {
                        headers = jsonData[rowIndex]
                    } else {
                        const row = jsonData[rowIndex];
                        if (row[0] && headers[0]) {
                            for (let i = 1; i < headers.length; i++) {
                                if (row[i] && headers[i]) {
                                    const resp = await fetch(`${SERVER_URL}/assetProperties/addProperty?hcode=${localStorage.getItem('hcode')}`, {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            asset_name: row[0],
                                            key: headers[i],
                                            value: row[i],
                                            plant_name: plant_name
                                        }),
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                }
                            }
                        }
                    }

                }

            }
        };

        reader.readAsArrayBuffer(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setModalOpen(false);
        await handlePropertyUpload();
    };

    return (
        <>
            <span className='border m-2' style={{ padding: '11px' }}>
                <button onClick={() => setModalOpen(true)} className={`${styles['btn-style']} p-1 border-0 me-2`}>{t('uploadProperties')}</button>
                <FileDownloadButton filename={'Property.xlsx'} title={t('downloadPropertyTemplate')}></FileDownloadButton>
            </span>
            {modalOpen &&
                <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered>
                    <Modal.Header closeButton className={`${style['modal-header']}`}>
                        <Modal.Title >{t('uploadPropertyExcel')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${style['modal-body']}`}>
                        <form onSubmit={handleSubmit} className={`${style['custom-form']}`}>
                            <h4 className='form-label'>{t('selectFile')}</h4>
                            <input type="file" accept=".xls, .xlsx" ref={fileInputRef} required lang='de' />
                            <br />
                            <button type="submit" className={`${styles['btn-style']} p-2 border-0 offset-10`}>{t('submit')}</button>
                        </form>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default UploadPropertyExcel;
