import Chart from "react-google-charts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getRandomTime = () => {
    const formatTime = (time) => {
        return time < 10 ? "0" + time : time;
    };
    const randomHours = formatTime(Math.floor(Math.random() * 24));
    const randomMinutes = formatTime(Math.floor(Math.random() * 60));
    const randomSeconds = formatTime(Math.floor(Math.random() * 60));
    return `${randomHours}:${randomMinutes}:${randomSeconds}`;
}

const generateRandomSensorData = (startDate, endDate) => {

    const randomData = [['Time', 'Date', 'value']];
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();
    const numberOfDays = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));

    for (let i = 0; i < 10; i++) {
        const timestamp = startTimestamp + Math.floor(Math.random() * numberOfDays) * (24 * 60 * 60 * 1000);
        const date = new Date(timestamp).toISOString().split('T')[0];
        const time = getRandomTime()
        const value = Math.random() * 100; // Random value between 0 and 100
        randomData.push([time, date, value]);
    }
    return randomData;
};

function Graph(prop) {

    const { t } = useTranslation();
    const { shcode, startDate, endDate } = prop;
    const startTime = new Date(`${startDate}T00:00:00Z`);
    const endTime = new Date(`${endDate}T23:59:59Z`);

    const [unit, setUnit] = useState('');

    const [sensorData, setSensorData] = useState([]);
    const [data, setData] = useState([]);
    const [avg, setAvg] = useState(0)

    const fetchSensors = async (shcode) => {
        if (shcode) {
            const response = await fetch(`${SERVER_URL}/sensorsData/${shcode}?hcode=${localStorage.getItem('hcode')}&startDate=${startDate}&endDate=${endDate}`);
            const data = await response.json();
            // setSensorData(data.sensorData);
            if (data.statusCode === 409 || data.sensorData.length === 1) {
                const randomData = generateRandomSensorData(startTime, endTime);
                setSensorData(randomData)
            }
            setUnit(data.unit)
        }
    };

    useEffect(() => {
        fetchSensors(shcode);
    }, [startDate, endDate, shcode]);



    useEffect(() => {
        let total = 0;
        const data = sensorData.map(([time, date, value], index) => {
            if (index === 0) {
                return [{ type: "datetime", label: "x" }, 'Value']
            }
            total += value;
            return [new Date(`${date}T${time}Z`), value];
        })

        data.sort((a, b) => a[0] - b[0]);

        setData(data);
        setAvg((total / (sensorData.length - 1)).toFixed(2));

    }, [sensorData]);

    const optionsModal = {
        backgroundColor: 'transparent',
        hAxis: {
            title: 'Time',
            format: 'hh:mm (dd)',
            viewWindow: {
                min: startTime,
                max: endTime,
            },
            titleTextStyle: {
                font: '25px',
                color: '#ff0000'
            },

            textStyle: {
                color: '#ff0000',
            },
        },
        vAxis: {
            title: `Value (${unit})`,
            titleTextStyle: {
                font: '20px',
                color: '#ff0000',
            },

            textStyle: {
                color: '#ff0000',
            },
        },
        legend: {
            position: 'top', alignment: 'end', textStyle: {
                color: '#ff0000',
            }
        },
        explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 4.0,
        },
        colors: ['#ff0000'],
    };

    return (<>
        <h6 className="card-subtitle ms-3 text-muted" style={{ color: '#121212' }}>{t('averageValue')} {avg}</h6>
        <Chart
            chartType="LineChart"
            data={data}
            options={optionsModal}
            width={"100%"}
        />
    </>
    );
}


export default Graph;