import { useState } from "react";
import Graph from "../googleCharts/Graph";


function DropCard({ startDate, endDate }) {
    const [droppedObject, setDroppedObject] = useState(null);

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const data = JSON.parse(event.dataTransfer.getData('text/plain'));
        setDroppedObject(data);
    };

    return (
        <>
            <div className="card mb-1 ms-1 me-1" style={{ "width": "18rem" }}>
                <div className="card-body" onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    {!droppedObject ?
                        <p className="card-text">Drop sensor Name to see their graph</p>
                        :
                        <>
                        <h5 className="card-title">{droppedObject.sname}</h5>
                        <Graph  shcode={droppedObject.hcode} startDate={startDate} endDate={endDate} />
                        </>
                    }

                </div>
            </div>
        </>
    )
}



export default DropCard;